import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

interface IProps {
    children: string;
    onClick?: () => void;
    disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.neutral.black,
        width: '100%',
        height: '40px',
        background: (props: { disabled: boolean }) =>
            props.disabled
                ? `
                    linear-gradient(180deg, 
                    rgba(229, 229, 227, 0.88) 0%, 
                    ${theme.palette.neutral.light} 50%, 
                    ${theme.palette.neutral.light} 100%)
                `
                : `
                    linear-gradient(180deg, 
                    rgba(255, 255, 255, 0.88) 0%, 
                    ${theme.palette.neutral.white} 50%, 
                    ${theme.palette.neutral.white} 100%)
                `,
        borderRadius: '8px',
        border: `1.3px solid ${theme.palette.neutral.light}`,
        cursor: 'pointer',
    },
}));

const SecondaryButton: React.FC<IProps> = ({ children, onClick, disabled = false }) => {
    const classes = useStyles({ disabled });

    return (
        <Button disabled={disabled} className={classes.root} onClick={onClick}>
            {children}
        </Button>
    );
};

export default SecondaryButton;
