import { makeStyles, Theme } from '@material-ui/core';

export const useGlobalStyles = makeStyles((theme: Theme) => ({
    whiteBg: {
        backgroundColor: theme.palette.neutral.white,
    },

    defaultBackground: {
        overflow: 'scroll',
        backgroundColor: theme.palette.neutral.white,
    },

    orangeText: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
    },

    buttonsBox: {
        marginTop: 16,
        marginBottom: 16,
        borderRadius: 8,
        background: theme.palette.neutral.white,
        width: '100%',
    },

    layoutWrapStyle: {
        backgroundColor: theme.palette.neutral.extraLightGrey,
    },

    inDarkGrey: {
        color: theme.palette.neutral.darkGrey,
    },

    searchInput: {
        width: '100%',

        '& 	.MuiInputBase-root': {
            borderRadius: '8px',
            border: `1px solid ${theme.palette.neutral.grey}`,
        },

        '& 	.Mui-focused': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: '0.5px 2px 8px rgba(255, 190, 0, 0.24)',
        },

        '& input': {
            padding: '4px 0 4px 12px;',
        },

        '& .MuiInputAdornment-root': {
            color: theme.palette.neutral.main,
            cursor: 'pointer',
        },
    },

    // 48px - height of search field
    searchScrolledWrap: {
        height: 'calc(100% - 48px)',
        overflowY: 'auto',
    },

    rowStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    rowBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    rowCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    line: {
        height: '.5px',
        background: theme.palette.neutral.grey,
        width: '100%',
    },

    borderBottom: {
        borderBottom: `1px solid ${theme.palette.neutral.extraLight}`,
    },
}));
