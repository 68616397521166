import React, { FunctionComponent, SVGProps } from 'react';
import {
    Box,
    InputAdornment,
    makeStyles,
    createStyles,
    TextFieldProps,
    Theme,
} from '@material-ui/core';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

import Input from 'modules/common/components/Input';

interface IProps {
    icon?: FunctionComponent<SVGProps<SVGSVGElement>> | string;
    onClick: () => void;
    disabled?: boolean;
    hasError?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputWrapper: {
            position: 'relative',

            '& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
                color: theme.palette.neutral.black,
            },
        },

        overlay: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            cursor: 'pointer',
            zIndex: 10,
        },
    })
);

const InputButton: React.FC<IProps & TextFieldProps> = ({
    icon: Icon,
    onClick,
    disabled = false,
    hasError = false,
    ...props
}) => {
    const { inputWrapper, overlay } = useStyles();

    return (
        <Box onClick={disabled ? () => null : onClick} className={inputWrapper}>
            <Input
                onKeyDown={(e) => e.preventDefault()}
                disabled={disabled}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {(Icon && <Icon />) || <ArrowForwardIosOutlinedIcon fontSize="small" />}
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                }}
                hasError={hasError}
                {...props}
            />
            <Box className={overlay} />
        </Box>
    );
};

export default InputButton;
