import React from 'react';
import classNames from 'classnames';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ReactComponent as CheckCircle } from 'assets/icons/color/16/check-circle.svg';
import { useGlobalStyles } from 'theme/globalStyles';
import { theme } from 'theme';

export type RadioOption = { label: string; value: any; defaultMethod?: boolean; wallet?: boolean };

interface StylesProps {
    position?: string;
}

interface IProps extends StylesProps {
    wrapperClassname?: any;
    optionClassname?: any;
    value: string;
    radioOptions: Array<RadioOption>;
    onChange?: (value: any) => void;
    deselect?: boolean;
    deselectValue?: any;
    iconRight?: number;
}

export const useStyle = makeStyles<Theme, StylesProps>(() =>
    createStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelLineHeight: {
            verticalAlign: 'bottom',
        },
        cursorPointer: {
            cursor: 'pointer',
        },
        radioOption: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: ({ position = 'center' }) => position,
            height: 64,
            width: '100%',
            border: '0.5px solid #8B8B88',
            borderRadius: 8,
            padding: '0 16px',
            cursor: 'pointer',
            '&:not(:last-child)': {
                marginBottom: 8,
            },
        },
        active: {
            border: '1px solid #FFBE00 !important',
            boxSizing: 'border-box',
            boxShadow: '0.5px 2px 8px rgba(255, 190, 0, 0.24)',
        },
    })
);

const RadioGroup: React.FC<IProps> = ({
    wrapperClassname,
    optionClassname,
    value,
    radioOptions,
    onChange,
    deselect = false,
    deselectValue = '',
    iconRight = 8,
    position,
}) => {
    const { wrapper, labelLineHeight, cursorPointer, radioOption, active } = useStyle({ position });
    const { inDarkGrey } = useGlobalStyles();

    return (
        <>
            <Box mb={1} className={classNames(wrapper, wrapperClassname)}>
                {radioOptions.map((option: RadioOption) => {
                    const isDollarIn = option.label[0] === '$';

                    const { defaultMethod, wallet } = option;

                    return (
                        <Box
                            key={option.value}
                            onClick={() => {
                                if (onChange)
                                    option.value !== value
                                        ? onChange(option.value)
                                        : deselect && onChange(deselectValue);
                            }}
                            className={classNames(
                                radioOption,
                                { [active]: [option.value, option.label].includes(value) },
                                optionClassname
                            )}
                        >
                            {value === option.value && (
                                <CheckCircle style={{ marginRight: iconRight }} />
                            )}

                            <Box>
                                <Typography
                                    variant="button"
                                    className={classNames('px-3', labelLineHeight, cursorPointer, {
                                        [inDarkGrey]: value !== option.value,
                                    })}
                                >
                                    {isDollarIn ? (
                                        <>
                                            $
                                            <Typography variant="h3" style={{ display: 'inline' }}>
                                                {option.value}
                                            </Typography>
                                        </>
                                    ) : (
                                        option.label
                                    )}
                                </Typography>

                                <Typography
                                    variant="caption"
                                    style={{ color: theme.palette.neutral.darkGrey }}
                                    component="h3"
                                >
                                    {defaultMethod && wallet
                                        ? 'Used for payments with Credit Card and CreditCard Wallet'
                                        : wallet
                                        ? 'For payments with CreditCard Wallet'
                                        : defaultMethod
                                        ? 'For payments with Credit Card'
                                        : null}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </>
    );
};

export default RadioGroup;
