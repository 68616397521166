import React from 'react';
import * as serviceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { theme } from 'theme';
import history from 'utils/history';
import { persistor, store } from 'store';
import App from 'App';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { OnlineStatusProvider } from './hooks/useOnlineStatus';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string;
const stripePromise = loadStripe(publishableKey);

const client = new QueryClient({
    defaultOptions: { queries: { suspense: true } },
});

render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Elements stripe={stripePromise}>
            <QueryClientProvider client={client}>
                <OnlineStatusProvider>
                    <PersistGate persistor={persistor}>
                        <Router history={history}>
                            <Provider store={store}>
                                <App />
                            </Provider>
                        </Router>
                    </PersistGate>
                </OnlineStatusProvider>
            </QueryClientProvider>
        </Elements>
    </ThemeProvider>,
    document.getElementById('root')
);

const updateHandler = (registration) => {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
};

serviceWorker.register({ onUpdate: updateHandler });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// eslint-disable-next-line no-console
console.log('Version - 1.0.1');
