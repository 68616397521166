import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { ReactComponent as Info } from 'assets/icons/color/info.svg';
import { theme } from 'theme';
import PrimaryButton from '../Buttons/PrimaryButton';

interface IProps {
    title?: string;
    type?: 'info' | 'error' | 'attention';
    children: string | JSX.Element;
    hasIcon?: boolean;
    hasPadding?: boolean;
    actionButtonLabel?: string;
    onAction?: () => void;
}

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        fontFamily: theme.typography.fontFamily,
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
}));

const ToolTip: React.FC<IProps> = ({
    title,
    type = 'info',
    children,
    hasIcon,
    hasPadding = true,
    actionButtonLabel,
    onAction,
}) => {
    const { box } = useStyles();

    const getColorOfToolTip = () => {
        switch (type) {
            case 'info':
                return theme.palette.neutral.extraLightGrey;
            case 'error':
                return theme.palette.error.extraLight;
            case 'attention':
                return theme.palette.primary.transparent;
            default:
                return theme.palette.neutral.extraLightGrey;
        }
    };

    return (
        <Box
            py={1}
            pb={hasPadding ? 1 : 0}
            px={hasPadding ? 1.5 : 0}
            borderRadius="8px"
            bgcolor={getColorOfToolTip()}
            className={box}
        >
            {hasIcon && (
                <Grid item xs={1}>
                    <Info />
                </Grid>
            )}

            <Box ml={hasIcon && '10px'} flex={1}>
                <Box px={hasPadding ? 0 : 1.5}>
                    <Typography variant="h5" component="h5">
                        {title}
                    </Typography>
                </Box>
                <Typography variant="caption">{children}</Typography>
            </Box>

            {actionButtonLabel && (
                <Box ml={1}>
                    <PrimaryButton onClick={onAction}>
                        <Typography variant="h6">{actionButtonLabel} </Typography>
                    </PrimaryButton>
                </Box>
            )}
        </Box>
    );
};

export default ToolTip;
