import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { ReactComponent as Cross } from 'assets/icons/default/cross.svg';

interface IProps {
    onClick: () => void;
    top?: string;
    right?: string;
    icon?: string | JSX.Element;
}

type StyleProps = {
    top: string | undefined;
    right: string | undefined;
};

const useStyles = makeStyles<Theme, StyleProps>(
    createStyles({
        closeX: {
            position: 'absolute',
            top: (props) => props.top || 0,
            right: (props) => props.right || 0,
            zIndex: 30,
        },
    })
);
const CloseXComponent: React.FC<IProps> = ({ onClick, top, right, icon }) => {
    const { closeX } = useStyles({ top, right });

    return (
        <Box className={closeX}>
            <IconButton onClick={onClick}>{icon || <Cross />}</IconButton>
        </Box>
    );
};

export default CloseXComponent;
