import React, { useEffect } from 'react';
import SlideUpMenu from 'modules/common/components/SlideUpMenu';
import { useAppDispatch, useTypedSelector } from 'hooks/store';
import { closeLocationInfoModal, selectIsLocationInfoModalOpen } from 'store/slices/ui';
import { Box, Typography } from '@material-ui/core';

const LocationSetUpModal = () => {
    const dispatch = useAppDispatch();
    const isLocationInfoModalOpen = useTypedSelector(selectIsLocationInfoModalOpen);

    const handleCloseModal = () => dispatch(closeLocationInfoModal());

    useEffect(() => {
        handleCloseModal();
    }, []);

    return (
        <SlideUpMenu
            isOpen={isLocationInfoModalOpen}
            onClose={handleCloseModal}
            bottomTitle="Allow ProofKeep to access your device location to provide the correct functionality"
            isInfoBlock
        >
            <Box pb={2} px={2}>
                <Typography variant="caption">
                    <ol>
                        <li>
                            Go to Settings {'>'} Privacy {'>'} Location Services.{' '}
                        </li>
                        <li>Make sure that Location Services is on.</li>
                        <li>Scroll down to find the app.</li>
                        <li>
                            Tap the app and select an option:
                            <ol type="a" style={{ paddingLeft: 18 }}>
                                <li>
                                    While Using the App: Allows access to Location Services only
                                    when the app or one of its features is visible on screen. If an
                                    app is set to While Using the App, you might see your status bar
                                    turn blue with a message that an app is actively using your
                                    location.
                                </li>
                                <li>
                                    Always: Allows access to your location even when the app is in
                                    the background.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Typography>
            </Box>
        </SlideUpMenu>
    );
};

export default LocationSetUpModal;
