import React, { forwardRef, MutableRefObject } from 'react';
import { useViewerParam } from '../../hooks/useViewerParam';
import WatermarkBlock, { IWatermarkProps } from '../WatermarkBlock';

// eslint-disable-next-line react/display-name
const ImageWatermark = forwardRef<HTMLElement, IWatermarkProps>(
    (props, viewerRef: MutableRefObject<HTMLElement | HTMLImageElement | null> | any) => {
        const width = useViewerParam(viewerRef, calculateImageWidth);
        const bottom = useViewerParam(viewerRef, calculateBottom);

        function calculateImageWidth() {
            return viewerRef?.current?.clientWidth || 0;
        }

        function calculateBottom() {
            return (
                window.innerHeight -
                Number(viewerRef.current?.offsetTop) -
                Number(viewerRef.current?.clientHeight)
            );
        }

        if (!viewerRef?.current?.complete || !viewerRef?.current?.naturalHeight) return null;

        return <WatermarkBlock bottom={bottom} width={width} {...props} />;
    }
);

export default ImageWatermark;
