import React, { forwardRef, MutableRefObject } from 'react';
import { useViewerParam } from '../../hooks/useViewerParam';
import WatermarkBlock, { IWatermarkProps } from '../WatermarkBlock';

// eslint-disable-next-line react/display-name
const PlayerWatermark = forwardRef<HTMLElement, IWatermarkProps>(
    (props, viewerRef: MutableRefObject<HTMLElement | HTMLImageElement | null> | any) => {
        const bottom = useViewerParam(viewerRef, calculateBottom);

        function calculateBottom() {
            return Number(viewerRef.current?.clientHeight) - 10;
        }

        return <WatermarkBlock bottom={bottom} width="100%" {...props} />;
    }
);

export default PlayerWatermark;
