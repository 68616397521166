import { ONLY_ENG_AND_SYMBOLS_REGEX } from './validation';

export const formattingTime = (seconds: number): string => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }
    return `${mm}:${ss}`;
};

export const formatAmountOfMoney = (amount?: number, withDecimalPlaces?: boolean): string => {
    if (typeof amount !== 'number') return '';
    return withDecimalPlaces ? String((amount / 100).toFixed(2)) : String(amount / 100);
};

export const returnOnlyEngLang = (e, callback, returnOnlyValue = false) => {
    const eventTarget = e.target;
    if (eventTarget) {
        eventTarget.value = eventTarget.value.replace(ONLY_ENG_AND_SYMBOLS_REGEX, '');
    }
    if (returnOnlyValue) {
        callback && callback(eventTarget.value);
    } else {
        callback && callback(e);
    }
};
