import React, { ReactNode } from 'react';
import {
    Button,
    makeStyles,
    CircularProgress,
    Typography,
    Theme,
    createStyles,
} from '@material-ui/core';
import classNames from 'classnames';

interface IProps {
    children: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    red?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.neutral.black,
            height: '40px',
            borderRadius: '8px',
            width: '100%',
        },

        primaryMode: {
            background: `
                    linear-gradient(180deg, 
                    rgba(255, 190, 0, 0.88) 0%,
                    ${theme.palette.primary.main} 50%,
                    ${theme.palette.primary.main} 100%)
                `,
            boxShadow: '0.5px 2px 16px rgba(255, 190, 0, 0.32)',
        },

        redMode: {
            color: theme.palette.neutral.white,
            background: `
                    linear-gradient(180deg, 
                    rgba(214, 46, 46, 0.88) 0%, 
                    ${theme.palette.error.main} 50%, 
                    ${theme.palette.error.main} 100%)
                `,
            boxShadow: '0.5px 2px 16px rgba(214, 46, 46, 0.32)',
        },

        disabledMode: {
            background: `
                    linear-gradient(180deg, 
                    rgba(229, 229, 227, 0.88) 0%, 
                    ${theme.palette.neutral.light} 50%, 
                    ${theme.palette.neutral.light} 100%)
                `,
            boxShadow: 'none',
        },

        loader: {
            color: theme.palette.neutral.black,
            marginRight: '10px',
        },
    })
);

const PrimaryButton: React.FC<IProps> = ({
    children,
    onClick,
    disabled = false,
    isLoading = false,
    red,
}) => {
    const { root, redMode, disabledMode, loader, primaryMode } = useStyles();

    return (
        <Button
            disabled={disabled}
            className={classNames(root, {
                [primaryMode]: !red,
                [redMode]: red,
                [disabledMode]: disabled,
            })}
            onClick={onClick}
        >
            {isLoading ? (
                <>
                    <CircularProgress size={20} className={loader} />
                    <Typography variant="button">Please wait</Typography>
                </>
            ) : (
                children
            )}
        </Button>
    );
};

export default PrimaryButton;
