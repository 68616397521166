import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { logout, selectToken } from 'store/slices/loginInfo';

import { Routes } from './routesConfig';
import { useQuery } from '../hooks/useQuery';
import { useAppDispatch } from '../hooks/store';

interface IProps {
    component: React.ComponentType<RouteComponentProps<any>>;
    exact?: boolean;
    path: string | Routes;
}

export const PublicRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
    const query = useQuery();
    const token = useSelector(selectToken);
    const dispatch = useAppDispatch();

    if (query.get('referred') === 'true' && rest.path === Routes.SIGN_UP) {
        if (token) dispatch(logout());

        return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                !token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.HOME,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};
