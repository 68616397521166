import React, { ReactNode, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { useGlobalStyles } from 'theme/globalStyles';
import { ReactComponent as Visa } from 'assets/icons/payment-services/visa.svg';
import { ReactComponent as MasterCard } from 'assets/icons/payment-services/mastercard.svg';
import { ReactComponent as Amex } from 'assets/icons/payment-services/amex.svg';
import { ReactComponent as JCB } from 'assets/icons/payment-services/jcb.svg';
import { ReactComponent as UnionPay } from 'assets/icons/payment-services/union-pay.svg';
import { ReactComponent as Discover } from 'assets/icons/payment-services/discover.svg';
import { ReactComponent as Diners } from 'assets/icons/payment-services/diners-club.svg';
import { ReactComponent as EmptyCard } from 'assets/icons/payment-services/empty-card.svg';
import { ReactComponent as ErrorCard } from 'assets/icons/payment-services/error.svg';

import {
    ButtonBottomBlock,
    Input,
    StyledCheckbox,
    InputButton,
    StripeInput,
    DialogBox,
    ToolTip,
    PrimaryButton,
    SecondaryButton,
} from 'modules/common/components';

import AddressForm from 'modules/common/components/AdressForm';
import { PaymentInput } from 'modules/sign-up/constants/paymentInputs';
import {
    useCreateStripeSetupIntentMutation,
    useGetPaymentMethodFingerprintMutation,
    useGetPaymentMethodsQuery,
    useMakeDefaultPaymentMethodMutation,
} from 'store/services/payments';
import { useGetUserDetailsQuery, useUpdateFirstLastNameMutation } from 'store/services/accounts';
import { isoCountryCodes } from 'modules/sign-up/constants/isoCountryCodes';
import { useAppDispatch } from 'hooks/store';
import { snackbarShown } from 'store/slices/ui';
import CustomDialog from 'modules/common/components/CustomDialog';
import LayoutMain from 'layouts/LayoutMain';
import BackdropLoader from '../BackdropLoader';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import LeaveModal from '../LeaveModal';
import useCommutator from 'hooks/useCommutator';
import { theme } from 'theme';
import BottomBlock from '../BottomBlock';
import EnterPromoCodeModal from '../../../sign-up/components/EnterPromoCodeModal';
import { formatAmountOfMoney } from '../../../../utils/format';

interface PaymentDetailsForm {
    firstName: string;
    lastName: string;
    fullAddress: string;
    street: string;
    house: string;
    apartmentNumber: string;
    city: string;
    province: string;
    zipCode: string;
    country: string;
    foundAddress: string;
}

interface StripeInputType {
    elementType: string;
    complete: boolean;
    empty: boolean;
    value: undefined;
    error?: {
        code: string;
        type: string;
        message: string;
    };
    brand: string;
}

interface IProps {
    title?: string;
    onClose?: () => void;
    onBack?: () => void;
    onBackTitle?: string;
    onBackContent?: JSX.Element;
    onCloseTitle?: string;
    onCloseContent?: JSX.Element;
    onCloseButton?: string;
    submitText?: string;
    hasSetAsDefaultPaymentMethod?: boolean;
    onConfirmClick: () => void;
    showPromoCode?: boolean;
}

const commonShrinkStyle: CSSProperties = {
    fontSize: 12,
    transform: 'translate(12px, 6px)',
    lineHeight: '12px',
    letterSpacing: '0.55px',
    textTransform: 'uppercase',
};

const useStyle = makeStyles((theme) => ({
    stripeInputStyle: {
        '& .MuiFormLabel-root': {
            transform: 'translate(15px, 12px) scale(1)',
            color: theme.palette.neutral.darkGrey,
            fontSize: 16,
            fontWeight: 600,
            lineHeight: '24px',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.neutral.main,
            ...commonShrinkStyle,
        },
        '&.with-error .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-filled': {
            color: theme.palette.neutral.main,
            ...commonShrinkStyle,
        },
    },
}));

type CardString =
    | 'visa'
    | 'mastercard'
    | 'amex'
    | 'discover'
    | 'diners'
    | 'jcb'
    | 'unionpay'
    | 'unknown'
    | 'error';

const CardIcons: Record<CardString, ReactNode> = {
    visa: <Visa />,
    mastercard: <MasterCard />,
    amex: <Amex />,
    discover: <Discover />,
    diners: <Diners />,
    jcb: <JCB />,
    unionpay: <UnionPay />,
    unknown: <EmptyCard />,
    error: <ErrorCard />,
};

const CreatePayment: React.FC<IProps> = ({
    title = 'Payment information',
    onClose,
    onBack,
    onBackTitle,
    onBackContent,
    onCloseTitle = 'Leave this page?',
    onCloseContent = (
        <Typography align="center" style={{ color: theme.palette.neutral.darkGrey }}>
            Your progress and data will be lost.
        </Typography>
    ),
    onCloseButton = 'Leave',
    submitText = 'Almost Done!',
    hasSetAsDefaultPaymentMethod = false,
    onConfirmClick,
    showPromoCode,
    children,
}) => {
    const dispatch = useAppDispatch();
    const { whiteBg } = useGlobalStyles();
    const { stripeInputStyle } = useStyle();

    const [
        createSetupIntent,
        { isLoading: isSetupIntentLoading },
    ] = useCreateStripeSetupIntentMutation();

    const {
        data: paymentMethods,
        isLoading: isPaymentMethodsLoading,
        error: paymentMethodsError,
        refetch,
    } = useGetPaymentMethodsQuery();

    const [updateNames, { isLoading: isFirstLastNameLoading }] = useUpdateFirstLastNameMutation();

    const userDetails = useGetUserDetailsQuery();

    const [
        isPaymentMethodCreating,
        startCreatingPaymentMethod,
        finishCreatingPaymentMethod,
    ] = useCommutator();
    const [isChecked, setChecked] = useState(false);
    const [isBillingAddressFormOpen, setBillingAddressFormOpen] = useState(false);

    const [cardNumberError, setCardNumberError] = useState<string | undefined>(undefined);
    const [expiryDateDateError, setExpiryDateError] = useState<string | undefined>(undefined);
    const [cvvError, setCvvError] = useState<string | undefined>(undefined);
    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [expiryDateDateComplete, setExpiryDateComplete] = useState(false);
    const [cvvComplete, setCvvComplete] = useState(false);
    const [isConfirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false);
    const [isConfirmBackModalOpen, setConfirmBackModalOpen] = useState(false);
    const [isDefaultPaymentMethod, setAsDefaultPaymentMethod] = useState(
        hasSetAsDefaultPaymentMethod
    );
    const [brand, setBrand] = useState<string>('unknown');
    const [promoCodeAmount, setPromoCodeAmount] = useState<number>(0);
    const [promoCodeSuccessModalOpen, setPromoCodeSuccessModalOpen] = useState<boolean>(false);

    const [isLeaveOpen, handleOpenLeave, handleCloseLeave] = useCommutator();
    const [
        isPromoCodeModalOpen,
        handleOpenPromoCodeModal,
        handleClosePromoCodeModal,
    ] = useCommutator();

    const [
        makeDefaultPaymentMethod,
        { isLoading: isMakeDefaultPaymentMethodLoading },
    ] = useMakeDefaultPaymentMethodMutation();

    const [
        getFingerprint,
        { isLoading: isFingerprintGetting },
    ] = useGetPaymentMethodFingerprintMutation();

    const elements = useElements();
    const stripe = useStripe();

    const methods = useForm<PaymentDetailsForm>({
        mode: 'onChange',
        defaultValues: {
            firstName: '',
            lastName: '',
            fullAddress: '',
            street: '',
            house: '',
            apartmentNumber: '',
            city: '',
            province: '',
            zipCode: '',
            country: '',
            foundAddress: '',
        },
        criteriaMode: 'all',
    });

    const {
        setValue,
        trigger,
        getValues,
        control,
        watch,
        formState: { errors, isValid },
    } = methods;

    const handleSetAsDefaultCheckBoxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setAsDefaultPaymentMethod(true);
        } else {
            setAsDefaultPaymentMethod(false);
        }
    };

    const handleUsePreviousAddressCheckBoxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (userDetails.data) {
            const {
                country,
                apartmentNumber,
                province,
                street,
                house,
                city,
                zipCode,
            } = userDetails.data;

            if (e.target.checked) {
                setValue(
                    'fullAddress',
                    `${house}, ${apartmentNumber} ${street}, ${province}, ${country}, ${zipCode}`
                );
                setValue('house', house);
                setValue('apartmentNumber', apartmentNumber);
                setValue('street', street);
                setValue('city', city);
                setValue('province', province);
                setValue('country', country);
                setValue('zipCode', zipCode);
            }

            if (!e.target.checked) {
                setValue('fullAddress', '');
                setValue('house', '');
                setValue('apartmentNumber', '');
                setValue('street', '');
                setValue('city', '');
                setValue('province', '');
                setValue('country', '');
                setValue('zipCode', '');
            }
            trigger();
            setChecked(e.target.checked);
        }
    };

    const handleNewAddressSave = () => {
        const { house, street, country, province, apartmentNumber, zipCode } = getValues();

        setValue(
            'fullAddress',
            `${house}, ${apartmentNumber} ${street}, ${province}, ${country}, ${zipCode}`
        );
        trigger();
        setBillingAddressFormOpen(false);
    };

    const handleOpenBillingAddressForm = () => {
        setBillingAddressFormOpen(true);
    };

    const handleCloseBillingAddressForm = () => {
        setBillingAddressFormOpen(false);
    };

    const onSuccessfullySubmitCard = () => {
        sessionStorage.setItem('hasPaymentMethod', 'true');
        refetch();

        handleClosePromoCodeModal();
        onConfirmClick();
    };

    const handleSavePaymentInfo = async () => {
        try {
            startCreatingPaymentMethod();

            if (userDetails.data) {
                const {
                    phoneNumber: { countryCode, nationalNumber },
                    email,
                    stripeCustomerId,
                } = userDetails.data;

                const {
                    firstName,
                    lastName,
                    country,
                    apartmentNumber,
                    province,
                    street,
                    house,
                    city,
                    zipCode,
                } = getValues();

                if (paymentMethodsError && !paymentMethods) {
                    return;
                }

                if (!stripe || !elements) {
                    return;
                }

                const cardNumber = elements.getElement(CardNumberElement);

                if (!cardNumber) {
                    return;
                }

                await updateNames({ firstName, lastName });

                const {
                    error: paymentMethodError,
                    paymentMethod,
                } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardNumber,
                    billing_details: {
                        phone: `${countryCode}${nationalNumber}`,
                        email,
                        address: {
                            city,
                            country: isoCountryCodes[country],
                            line1: `${street} ${house}`,
                            line2: apartmentNumber,
                            postal_code: zipCode,
                            state: province,
                        },
                        name: `${firstName} ${lastName}`,
                    },
                });

                if (paymentMethodError) {
                    return dispatch(
                        snackbarShown({
                            type: 'error',
                            message:
                                paymentMethodError.message ||
                                'Card was not created. make sure all data is correct.',
                        })
                    );
                }

                const res = await getFingerprint(paymentMethod?.id || '').unwrap();
                const duplicates = paymentMethods.filter(
                    (pm) => pm.fingerprint === res?.fingerprint
                );

                if (duplicates.length > 0) {
                    setCardNumberError('Card already exist');

                    return dispatch(
                        snackbarShown({
                            type: 'info',
                            message: `Card ****${paymentMethod?.card?.last4} already exist. Please, add the new one.`,
                        })
                    );
                }

                createSetupIntent({ stripeCustomerId })
                    .unwrap()
                    .then(({ client_secret }) => {
                        stripe
                            .confirmCardSetup(client_secret, { payment_method: paymentMethod?.id })
                            .then((response) => {
                                if (response.error) {
                                    dispatch(
                                        snackbarShown({
                                            type: 'error',
                                            message:
                                                response.error.message ||
                                                'An error occurred please try again later',
                                        })
                                    );
                                } else {
                                    if (
                                        isDefaultPaymentMethod &&
                                        response.setupIntent.payment_method
                                    ) {
                                        makeDefaultPaymentMethod({
                                            paymentMethodId: response.setupIntent
                                                .payment_method as string,
                                        })
                                            .unwrap()
                                            .then(() => onSuccessfullySubmitCard())
                                            .catch(() => {
                                                dispatch(
                                                    snackbarShown({
                                                        type: 'error',
                                                        message:
                                                            'The credit card was successfully added. But failed to set it as default.',
                                                    })
                                                );
                                                onConfirmClick();
                                            });
                                    } else onSuccessfullySubmitCard();
                                }
                            });
                    });
            }
        } finally {
            finishCreatingPaymentMethod();
            setPromoCodeSuccessModalOpen(false);
        }
    };

    const handleChangePaymentInput = (e) => {
        const event: StripeInputType = e;

        switch (event.elementType) {
            case PaymentInput.CardNumber:
                setCardNumberError(event.error?.message);
                setCardNumberComplete(event.complete);
                setBrand(event.error?.message ? 'error' : event.brand);
                break;
            case PaymentInput.CardExpiry:
                setExpiryDateError(event.error?.message);
                setExpiryDateComplete(event.complete);
                break;
            case PaymentInput.CardCvv:
                setCvvError(event.error?.message);
                setCvvComplete(event.complete);
                break;
            default:
                return;
        }
    };

    const getIsSubmitButtonDisabled = () => {
        const isDisabled =
            !isValid ||
            cardNumberError ||
            expiryDateDateError ||
            cvvError ||
            !cardNumberComplete ||
            !expiryDateDateComplete ||
            !cvvComplete;

        return !!isDisabled;
    };

    const isAddressFormFilled = () => {
        const { country, province, street, house, city, zipCode } = getValues();

        const isFilled = !!country && !!province && !!street && !!house && !!city && !!zipCode;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { firstName, lastName, ...address } = errors;

        return !isFilled || !!Object.keys(address).length;
    };

    const handleBack = () => setConfirmBackModalOpen(true);
    const handleClose = () => setConfirmCloseModalOpen(true);

    const onPromoCodeApplied = (amount: number) => {
        setPromoCodeAmount(amount);
        setPromoCodeSuccessModalOpen(true);
    };

    return (
        <>
            <BackdropLoader
                isOpen={
                    isMakeDefaultPaymentMethodLoading ||
                    isFirstLastNameLoading ||
                    isPaymentMethodsLoading ||
                    isFingerprintGetting ||
                    isPaymentMethodCreating
                }
            />

            <EnterPromoCodeModal
                isOpen={isPromoCodeModalOpen}
                handleClose={handleClosePromoCodeModal}
                savePaymentInfo={handleSavePaymentInfo}
                onPromoCodeApplied={onPromoCodeApplied}
            />

            <DialogBox
                isOpen={promoCodeSuccessModalOpen}
                title={`$${formatAmountOfMoney(promoCodeAmount, true)} Promo Applied`}
                subTitle={
                    "Your credit card won't be charged until your ProofKeep Wallet balance expires."
                }
                submitText={'Proceed'}
                onSubmit={handleSavePaymentInfo}
            />

            <CustomDialog open={isBillingAddressFormOpen} onClose={handleCloseBillingAddressForm}>
                <FormProvider {...methods}>
                    <LeaveModal
                        isOpen={isLeaveOpen}
                        onClose={handleCloseLeave}
                        onLeave={onClose as () => void}
                    />

                    <LayoutMain
                        flowTitle="Credit Card Billing Address"
                        onBack={handleCloseBillingAddressForm}
                        onClose={handleOpenLeave}
                    >
                        <Box pt={2} pb={12} px={2}>
                            <AddressForm />
                        </Box>

                        <ButtonBottomBlock
                            onButtonClick={handleNewAddressSave}
                            buttonTitle="Save"
                            disabled={isAddressFormFilled()}
                        />
                    </LayoutMain>
                </FormProvider>
            </CustomDialog>

            {onBack && (
                <DialogBox
                    isOpen={isConfirmBackModalOpen}
                    title={onBackTitle}
                    submitText="Out"
                    onSubmit={() => onBack && onBack()}
                    onCancel={() => setConfirmBackModalOpen(false)}
                >
                    {onBackContent}
                </DialogBox>
            )}

            {onClose && (
                <DialogBox
                    isOpen={isConfirmCloseModalOpen}
                    title={onCloseTitle}
                    submitText={onCloseButton}
                    onSubmit={() => onClose && onClose()}
                    onCancel={() => setConfirmCloseModalOpen(false)}
                    color={theme.palette.error.main}
                >
                    {onCloseContent}
                </DialogBox>
            )}

            <Box height="100vh" className={whiteBg}>
                <LayoutMain
                    flowTitle={title}
                    onClose={handleClose}
                    onBack={onBack && (onBackTitle ? handleBack : onBack)}
                >
                    <Box px={2}>
                        {children}

                        <Box display="flex" mt={2}>
                            <Box flex={1} mr={1.5}>
                                <Controller
                                    control={control}
                                    name="firstName"
                                    rules={{
                                        required: 'This field is required',
                                        maxLength: {
                                            value: 50,
                                            message: 'Maximum 50 characters',
                                        },
                                        validate: (value) => {
                                            return value.length > 1 || `Minimum 2 characters`;
                                        },
                                    }}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            label="First Name"
                                            {...rest}
                                            inputRef={ref}
                                            hasError={!!errors?.firstName}
                                            helperText={errors?.firstName?.message}
                                        />
                                    )}
                                />
                            </Box>
                            <Box flex={1}>
                                <Controller
                                    control={control}
                                    name="lastName"
                                    rules={{
                                        required: 'This field is required',
                                        maxLength: {
                                            value: 50,
                                            message: 'Maximum 50 characters',
                                        },
                                        validate: (value) => {
                                            return value.length > 1 || `Minimum 2 characters`;
                                        },
                                    }}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            label="Last Name"
                                            {...rest}
                                            inputRef={ref}
                                            hasError={!!errors?.lastName}
                                            helperText={errors?.lastName?.message}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box mt={2}>
                            <Input
                                className={stripeInputStyle}
                                label="Card Number"
                                onChange={handleChangePaymentInput}
                                hasError={!!cardNumberError}
                                helperText={cardNumberError}
                                InputLabelProps={{
                                    shrink: cardNumberComplete || !!cardNumberError,
                                }}
                                InputProps={{
                                    inputComponent: StripeInput as any,
                                    inputProps: {
                                        component: CardNumberElement,
                                    },
                                    disableUnderline: true,
                                    endAdornment: CardIcons[brand],
                                }}
                            />
                        </Box>

                        <Box mt={2} display="flex" alignItems="flex-start">
                            <Box flexGrow={1} mr={1.5}>
                                <Input
                                    className={stripeInputStyle}
                                    label="MM/YY"
                                    onChange={handleChangePaymentInput}
                                    hasError={!!expiryDateDateError}
                                    helperText={expiryDateDateError}
                                    InputLabelProps={{
                                        shrink: expiryDateDateComplete || !!expiryDateDateError,
                                    }}
                                    InputProps={{
                                        inputComponent: StripeInput as any,
                                        inputProps: {
                                            component: CardExpiryElement,
                                        },
                                        disableUnderline: true,
                                    }}
                                />
                            </Box>

                            <Box flexGrow={1}>
                                <Input
                                    className={stripeInputStyle}
                                    label="CVV"
                                    onChange={handleChangePaymentInput}
                                    hasError={!!cvvError}
                                    helperText={cvvError}
                                    InputLabelProps={{ shrink: cvvComplete || !!cvvError }}
                                    InputProps={{
                                        inputComponent: StripeInput as any,
                                        inputProps: {
                                            component: CardCvcElement,
                                        },
                                        disableUnderline: true,
                                    }}
                                />
                            </Box>
                        </Box>

                        <Box mt={2}>
                            {isChecked ? (
                                <ToolTip>
                                    <>{getValues('fullAddress')}</>
                                </ToolTip>
                            ) : (
                                <Controller
                                    control={control}
                                    name="fullAddress"
                                    rules={{ required: 'Enter your billing address' }}
                                    render={({ field: { ref, ...rest } }) => (
                                        <InputButton
                                            {...rest}
                                            inputRef={ref}
                                            disabled={isChecked}
                                            onClick={handleOpenBillingAddressForm}
                                            label="Credit Card Billing Address"
                                            InputLabelProps={{
                                                shrink: !!watch('fullAddress'),
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Box>

                        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                            <Box mr={1.5} width="100%">
                                <Typography variant="button" component="span">
                                    Use Previously Entered Address for Credit Card
                                </Typography>
                            </Box>

                            <StyledCheckbox
                                checked={isChecked}
                                onChange={handleUsePreviousAddressCheckBoxClick}
                            />
                        </Box>
                        {hasSetAsDefaultPaymentMethod && (
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                <Box mr={1.5} width="100%">
                                    <Typography variant="button" component="span">
                                        Set as Default for Payments
                                    </Typography>
                                </Box>

                                <StyledCheckbox
                                    checked={isDefaultPaymentMethod}
                                    onChange={handleSetAsDefaultCheckBoxClick}
                                />
                            </Box>
                        )}

                        <Box pb={10} />
                    </Box>
                </LayoutMain>
            </Box>

            <BottomBlock>
                <PrimaryButton
                    onClick={showPromoCode ? handleOpenPromoCodeModal : handleSavePaymentInfo}
                    disabled={getIsSubmitButtonDisabled()}
                    isLoading={isSetupIntentLoading || isFirstLastNameLoading}
                >
                    {submitText}
                </PrimaryButton>
                <Box pt={2} />

                {showPromoCode && (
                    <SecondaryButton
                        onClick={handleSavePaymentInfo}
                        disabled={getIsSubmitButtonDisabled()}
                    >
                        Proceed without a Promo Code
                    </SecondaryButton>
                )}
            </BottomBlock>
        </>
    );
};

export default CreatePayment;
