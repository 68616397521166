import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ReactComponent as PlayButton } from 'assets/icons/color/play-button.svg';
import LayoutMain from 'layouts/LayoutMain';
import {
    ButtonBottomBlock,
    DialogBox,
    GuideTextItem,
    StyledCheckbox,
    ToolTip,
} from 'modules/common/components';
import { VehicleCondition } from 'modules/vehicle-rental/constants';
import { VehicleConditionType } from 'modules/vehicle-rental/constants/types';
import { TVehicleGuide } from '../types';

type IProps = TVehicleGuide & {
    buttonTitle?: string;
    steps: string[];
    previewSrc: string;
    conditionType: VehicleConditionType;
};

interface StylesProps {
    previewSrc: string;
}

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) =>
    createStyles({
        wrapper: {
            height: '100vh',
            minHeight: '100%',
            background: theme.palette.neutral.white,
        },
        videoBox: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: 8,
            opacity: 0.64,
            background: theme.palette.neutral.black,
        },
        guideBox: {
            maxWidth: 344,
            minHeight: 192,
            margin: '16px auto',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: ({ previewSrc }) => `url(${previewSrc})`,
        },
        playButton: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',

            '& h3': {
                color: theme.palette.neutral.white,
            },
        },
        box: {
            borderRight: `1px solid ${theme.palette.neutral.grey}`,
            borderLeft: `1px solid ${theme.palette.neutral.grey}`,
            minHeight: '100%',
        },
    })
);

const Guide: React.FC<IProps> = ({
    buttonTitle,
    onInitiateCapture,
    onClose,
    onBack,
    steps,
    previewSrc,
    conditionType,
    setCheckboxState,
}) => {
    const { wrapper, box, guideBox, videoBox, playButton } = useStyles({ previewSrc });

    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const getTitle = (conditionType: VehicleConditionType) => {
        switch (conditionType) {
            case VehicleCondition.Interior:
                return 'Capture Interior Condition';
            case VehicleCondition.Mileage:
                return 'Mileage';
            case VehicleCondition.Fuel:
                return 'Fuel';
            default:
                return '';
        }
    };

    const handleTakeMediaButtonClick = (conditionType: VehicleConditionType) => {
        if (conditionType === VehicleCondition.Interior) {
            return setInfoModalOpen(true);
        }

        onInitiateCapture();
    };

    const handleCloseModal = () => {
        setInfoModalOpen(false);
    };

    useEffect(() => {
        setCheckboxState(false);
    }, []);

    return (
        <>
            <DialogBox
                isOpen={infoModalOpen}
                submitText="Take Video"
                onSubmit={onInitiateCapture}
                onCancel={handleCloseModal}
            >
                <Box mt={1}>
                    <Typography variant="body1" align="center">
                        Recommended video recording time is less than 90 seconds. That way, it is
                        easier to upload, store, playback and the share with others.
                    </Typography>
                </Box>
            </DialogBox>

            <Box className={wrapper}>
                <LayoutMain flowTitle={getTitle(conditionType)} onClose={onClose} onBack={onBack}>
                    <Box maxWidth={600} m="auto" className={box}>
                        <Box px={2} mt={2}>
                            <ToolTip title="Guide">
                                <Box mt={1}>
                                    {steps.map((step) => (
                                        <GuideTextItem key={step} text={step} />
                                    ))}
                                </Box>
                            </ToolTip>

                            <Box className={guideBox}>
                                <Box className={videoBox} />

                                <Button>
                                    <Box className={playButton} p={4}>
                                        <Box>
                                            <PlayButton />
                                        </Box>
                                        <Typography variant="button" component="h3">
                                            Play Video Guide
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </Box>

                        <ButtonBottomBlock
                            buttonTitle={buttonTitle || 'Take Video'}
                            onButtonClick={() => handleTakeMediaButtonClick(conditionType)}
                            background={1}
                        >
                            <Box
                                pt={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderTop="0.5px solid rgba(0, 0, 0, 0.16)"
                            >
                                <Box mr={1.5}>
                                    <Typography variant="button">
                                        Don’t show me this page again
                                    </Typography>
                                </Box>
                                <StyledCheckbox
                                    onChange={(e) => setCheckboxState(e.target.checked)}
                                />
                            </Box>
                        </ButtonBottomBlock>
                    </Box>
                </LayoutMain>
            </Box>
        </>
    );
};

export default Guide;
