import React, { FC, ReactNode } from 'react';
import { BoxProps } from '@material-ui/core/Box/Box';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

interface IProps extends BoxProps {
    children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.neutral.extraLightGrey,
            borderRadius: 8,
            padding: '8px 12px',
        },
    })
);

const HighlightedBox: FC<IProps> = ({ children, ...boxProps }) => {
    const { root } = useStyles();

    return (
        <Box
            className={root}
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            {...boxProps}
        >
            {children}
        </Box>
    );
};

export default HighlightedBox;
