import React from 'react';
import { Box, makeStyles, Typography, IconButton, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { ReactComponent as ChevronLeft } from 'assets/icons/default/chevron-left.svg';
import { ReactComponent as Cross } from 'assets/icons/default/cross.svg';
import { LayoutT } from '../../../../types/layoutT';
import { theme } from '../../../../theme';

interface IStyle {
    transparentBackground?: boolean;
}

const flowCommonStyle: CSSProperties = {
    color: theme.palette.neutral.darkGrey,
    textAlign: 'center',
};

const useStyles = makeStyles<Theme, IStyle>(() => ({
    root: {
        background: ({ transparentBackground }) =>
            !transparentBackground
                ? `linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.96) 61.98%, rgba(255, 255, 255, 0.88) 100%)`
                : 'transparent',
        padding: '2px 4px 0px 7px',
        width: '100%',
        height: '50px',
    },
    flowLabel: {
        textTransform: 'uppercase',
        ...flowCommonStyle,
    },
    flowSubTitleStyle: {
        fontSize: 13,
        ...flowCommonStyle,
    },
}));

const HeaderMain: React.FC<LayoutT> = ({
    flowTitle,
    partTitle,
    flowSubTitle,
    suffixIcon,
    prefixIcon,
    onClose,
    onBack,
    prefixIconAction,
    transparentBackground,
}) => {
    const { root, flowLabel, flowSubTitleStyle } = useStyles({ transparentBackground });

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" className={root}>
            <Box>
                {prefixIcon && <IconButton onClick={prefixIconAction}>{prefixIcon}</IconButton>}
                {onBack && (
                    <Box>
                        <IconButton onClick={onBack}>
                            <ChevronLeft />
                        </IconButton>
                    </Box>
                )}
                {!prefixIcon && !onBack && <Box mx={3} />}
            </Box>
            <Box textAlign="center">
                {partTitle && (
                    <Typography variant="subtitle2" className={flowLabel}>
                        {flowTitle}
                    </Typography>
                )}

                <Typography variant="h4" component="h4">
                    {partTitle || flowTitle}
                </Typography>

                {flowSubTitle && (
                    <Typography variant="subtitle2" className={flowSubTitleStyle}>
                        {flowSubTitle}
                    </Typography>
                )}
            </Box>
            <Box>
                {suffixIcon && suffixIcon}

                {onClose && (
                    <IconButton onClick={onClose}>
                        <Cross />
                    </IconButton>
                )}

                {!suffixIcon && !onClose && <Box mx={3} />}
            </Box>
        </Box>
    );
};

export default HeaderMain;
