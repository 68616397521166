import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

const commonWrapStyle: CSSProperties = {
    marginTop: 4,
    padding: '4px 6px',
    borderRadius: 40,
    marginRight: 4,
};

const useStyles = makeStyles((theme: Theme) => ({
    expireSoonWrapper: {
        background: '#FBE4E4',
        color: '#D62E2E',
        ...commonWrapStyle,
    },

    expiredWrapper: {
        background: theme.palette.neutral.light,
        color: theme.palette.neutral.darkGrey,
        ...commonWrapStyle,
    },
    tag: {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 600,
        textTransform: 'uppercase',
    },
}));

interface IChipsExpired {
    type: string;
    text: string;
}

const ChipsExpired = ({ type, text }: IChipsExpired) => {
    const { expireSoonWrapper, expiredWrapper, tag } = useStyles();

    return (
        <Box className={classNames(type === 'expire_soon' ? expireSoonWrapper : expiredWrapper)}>
            <Typography className={tag}>{text}</Typography>
        </Box>
    );
};

export default ChipsExpired;
