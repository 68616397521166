import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { VehicleConditionType } from 'modules/vehicle-rental/constants/types';

import { RootState } from 'store';

export enum MediaType {
    Video = 'video',
    Audio = 'audio',
    Image = 'image',
}

export interface EvidenceFileType {
    address: string;
    file: File;
    id: string;
    type: MediaType.Video | MediaType.Audio | MediaType.Image;
    duration?: number;
    cover?: any;
    isLoading: boolean;
}
export interface VehicleRentalFileType {
    address: string;
    file: File;
    id: string;
    type: MediaType.Video | MediaType.Image;
    conditionType: VehicleConditionType;
    duration?: number;
    cover?: any;
    isLoading: boolean;
}
export interface HotelRentalFileType {
    address: string;
    file: File;
    id: string;
    type: MediaType.Video | MediaType.Image;
    duration?: number;
    cover?: any;
    isLoading: boolean;
}

type UploadState = {
    pendingFiles: string[];
    rejectedFiles: string[];
    fulfilledFiles: string[];
    progresses: Record<string, number>;
    remaining: Record<string, number>;
};

const initialState: UploadState = {
    pendingFiles: [] as string[],
    rejectedFiles: [] as string[],
    fulfilledFiles: [] as string[],
    progresses: {},
    remaining: {},
};

const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setPendingFile: (state, action: PayloadAction<string>) => {
            state.pendingFiles.push(action.payload);
            state.fulfilledFiles = state.fulfilledFiles.filter((id) => id !== action.payload);
            state.rejectedFiles = state.rejectedFiles.filter((id) => id !== action.payload);
        },
        setProgress: (
            state,
            { payload: { id, progress } }: PayloadAction<{ id: string; progress: number }>
        ) => {
            state.progresses[id] = progress;
        },
        setRemaining: (
            state,
            { payload: { id, remaining } }: PayloadAction<{ id: string; remaining: number }>
        ) => {
            state.remaining[id] = remaining;
        },

        setRejectedFile: (state, action: PayloadAction<string>) => {
            state.rejectedFiles.push(action.payload);
            state.fulfilledFiles = state.fulfilledFiles.filter((id) => id !== action.payload);
            state.pendingFiles = state.pendingFiles.filter((id) => id !== action.payload);
        },
        setFulfilledFile: (state, action: PayloadAction<string>) => {
            state.fulfilledFiles.push(action.payload);
            state.rejectedFiles = state.rejectedFiles.filter((id) => id !== action.payload);
            state.pendingFiles = state.pendingFiles.filter((id) => id !== action.payload);
        },
    },
});

export const {
    setFulfilledFile,
    setPendingFile,
    setRejectedFile,
    setProgress,
    setRemaining,
} = uploadSlice.actions;

export const uploadSelector = (state: RootState) => state.upload;

export const selectPendingState = (id: string) =>
    createSelector(uploadSelector, (state) => state.pendingFiles.includes(id));

export const selectProgress = (id: string) =>
    createSelector(uploadSelector, (state) => state.progresses[id]);

export const selectRemaining = (id: string) =>
    createSelector(uploadSelector, (state) => state.remaining[id]);

export const selectFulfilledState = (id: string) =>
    createSelector(uploadSelector, (state) => state.fulfilledFiles.includes(id));

export const selectRejectedState = (id: string) =>
    createSelector(uploadSelector, (state) => state.rejectedFiles.includes(id));

export default uploadSlice.reducer;
