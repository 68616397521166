import { makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
    hasError?: boolean;
    isDisabled?: boolean;
    hasStartAdornment?: boolean;
    isReferral?: boolean;
    hasValue?: boolean;
    isCode?: boolean;
    multiline?: boolean;
}

const inputCommonStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    inputWrapper: {
        '& .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-formControl': {
            backgroundColor: ({ isDisabled }) =>
                isDisabled ? theme.palette.neutral.extraLightGrey : theme.palette.neutral.white,
            border: ({ hasError, isDisabled }) => {
                if (isDisabled) {
                    return 'none';
                }

                return hasError
                    ? `1px solid ${theme.palette.error.main}`
                    : `0.5px solid ${theme.palette.neutral.main}`;
            },

            display: 'flex',
            alignItems: ({ multiline }) => (multiline ? 'flex-start' : 'center'),

            borderRadius: 8,

            height: ({ multiline }) => (multiline ? 'auto' : '48px'),
            minHeight: ({ multiline }) => (multiline ? '112px' : '0'),
            '& .MuiInputBase-input.MuiFilledInput-input': {
                padding: ({ hasStartAdornment, multiline }) =>
                    multiline
                        ? '0px'
                        : hasStartAdornment
                        ? '23px 12px 10px 0px'
                        : '23px 12px 10px 12px',
                fontSize: '16px',
                lineHeight: '24px',
                color: ({ hasValue, isCode }) => {
                    if (isCode) {
                        if (hasValue) return theme.palette.neutral.black;
                        return theme.palette.neutral.main;
                    }

                    return theme.palette.neutral.black;
                },
                fontWeight: 400,
                textAlign: ({ isReferral }) => (isReferral ? 'center' : 'left'),
                letterSpacing: ({ isCode, isReferral }) => {
                    if (isReferral) return '4px';
                    if (isCode) return '5px';
                    return '0px';
                },
            },
        },

        '& .MuiFilledInput-adornedStart': {
            paddingLeft: 0,
        },

        '& .MuiInputLabel-shrink': {
            textTransform: 'uppercase',
            paddingLeft: ({ isReferral }) => (isReferral ? '2px' : 0),
        },

        '& .MuiInputLabel-root': {
            transform: 'translate(15px, 12px) scale(1)',
            letterSpacing: ({ isReferral }) => (isReferral ? '0px' : 'initial'),
        },

        '& .MuiInputBase-root.MuiFilledInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl': {
            border: ({ hasError }) =>
                hasError
                    ? `1px solid ${theme.palette.error.main}`
                    : `0.5px solid ${theme.palette.primary.main}`,
            boxShadow: ({ hasError }) =>
                hasError
                    ? `0.5px 2px 8px rgba(214, 46, 46, 0.24)`
                    : '0.5px 2px 8px rgba(255, 190, 0, 0.24)',
        },

        '& .MuiFormLabel-root': {
            fontSize: '16px',
            lineHeight: '24px',
            color: ({ hasError }) =>
                hasError ? theme.palette.error.main : theme.palette.neutral.darkGrey,
            fontWeight: 600,
        },

        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(12px, 6px)',
            fontSize: '12px',
            lineHeight: '12px',
            color: ({ hasError }) =>
                hasError ? theme.palette.error.main : theme.palette.neutral.main,
            letterSpacing: '0.55px',
        },

        '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
            margin: 0,
        },
    },
}));

export default inputCommonStyles;
