import {
    configureStore,
    combineReducers,
    ThunkAction,
    Action,
    Middleware,
    MiddlewareAPI,
    isRejectedWithValue,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { baseApi } from 'store/services/base';
import loginInfoReducer from 'store/slices/loginInfo';
import uploadReducer from 'store/slices/upload';
import uiReducer, { snackbarShown } from 'store/slices/ui';
import { sharingApiAuth } from './services/sharing';

// intercepts async errors and shows error snackbar
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const statusCode = Math.floor(action.payload.originalStatus / 100);

        if (statusCode === 5) {
            api.dispatch(
                snackbarShown({
                    type: 'error',
                    message: 'An error occurred. Please try again later ',
                })
            );
        }
    }

    return next(action);
};

const getPersistConfig = (key: string) => ({ key, storage });
const uiPersistConfig = {
    ...getPersistConfig('ui'),
    blacklist: ['snackbar'],
};

export const rootReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    [sharingApiAuth.reducerPath]: sharingApiAuth.reducer,
    loginInfo: persistReducer(getPersistConfig('loginInfo'), loginInfoReducer),
    ui: persistReducer(uiPersistConfig, uiReducer),
    upload: uploadReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(baseApi.middleware)
            .concat(sharingApiAuth.middleware)
            .concat(rtkQueryErrorLogger),
    devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
