import React, { ReactNode, useState } from 'react';
import { Box, IconButton, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ReactComponent as MenuVertical } from 'assets/icons/default/menu-vertical.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/white/play.svg';
import { ReactComponent as Refresh } from 'assets/icons/default/refresh.svg';
import { ReactComponent as XCircle } from 'assets/icons/default/x-circle.svg';

import dayjs, { extend } from 'dayjs';
import dayjsPluginDuration from 'dayjs/plugin/duration';
import { MediaTypeEnum, FileView, MediaType } from 'modules/common/types';
import voiceImg from 'assets/img/audio-recording.png';
import { useSelector } from 'react-redux';
import {
    selectPendingState,
    selectFulfilledState,
    selectRejectedState,
    selectProgress,
    selectRemaining,
} from 'store/slices/upload';
import ImageViewer from 'modules/common/components/ImageViewer';
import VideoPlayer from 'modules/common/components/VideoPlayer';
import { truncateText } from 'utils/strings';
import { DAYS_FORMAT, DAYS_FORMAT_WITH_TIME } from 'modules/common/constants/dateOptions';
import ChipsExpired from '../ChipsExpired';
import classNames from 'classnames';
import usePreventOpenPlayer from 'hooks/usePreventOpenPlayer';
import ShareIcon from 'assets/icons/components/share';
import { ReactComponent as ShareCoin } from 'assets/icons/color/share-coin.svg';
import { IWatermarkProps } from '../../../file-sharing/components/WatermarkBlock';
import AnimatedProgressBar from '../AnimatedProgressBar';
import { msToTime } from '../../helpers/msToTime';

interface IProps {
    file: FileView;
    onRetake?: () => void;
    onFileMenuOpen?: () => void;
    onClose?: () => void;
    withStateInfo?: boolean;
    className?: string;
    isLastItem?: boolean;
    shareSectionHidden?: boolean;
    watermark?: IWatermarkProps;
    children?: ReactNode;
}

interface StyleProps {
    isLoading: boolean;
}

extend(dayjsPluginDuration);

const commonBox = {
    textTransform: 'uppercase',
    borderRadius: 40,
    width: 'fit-content',
    letterSpacing: '0.55px',
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    mainWrap: {
        position: 'relative',
        background: theme.palette.neutral.white,
        cursor: ({ isLoading }) => (isLoading ? 'default' : 'pointer'),
        overflow: 'hidden',
    },

    overlay: {
        position: 'absolute',
        zIndex: 10,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #0D0A00 100%)',
        opacity: 0.32,
        overflow: 'hidden',
    },

    overlayError: {
        position: 'absolute',
        zIndex: 10,
        width: '100%',
        height: '100%',
        background: '#E6E5E3',
        overflow: 'hidden',
    },

    overlayBorder: {
        position: 'absolute',
        zIndex: 20,
        width: '100%',
        height: '100%',
        border: '2px solid rgba(13, 10, 0, 0.2)',
        overflow: 'hidden',
        borderRadius: 4,
    },

    thumbnailWrapper: {
        width: 64,
        height: 64,
        overflow: 'hidden',
        borderRadius: 4,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%',
        },
    },

    thumbnail: {
        minHeight: '100%',
        minWidth: '100%',
        flexShrink: 0,
    },

    fileInfoWrapper: {
        height: '100%',
        zIndex: 10,
    },

    fileName: {
        flex: 1,
        minWidth: 0,
        color: theme.palette.neutral.darkGrey,
        '& h2': {
            fontWeight: 600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },

    play: {
        position: 'absolute',
        bottom: 2,
        left: 2,
        zIndex: 100,
    },

    uploadedBox: {
        background: theme.palette.success.transparent,
        color: theme.palette.success.dark,
        ...(commonBox as CSSProperties),
    },

    progressBar: {
        width: '100%',
        borderRadius: 10,
    },

    borderLine: {
        position: 'absolute',
        right: -16,
        bottom: 0,
        width: 'calc(100% - 76px)',
        height: 1,
        background: theme.palette.neutral.light,
    },

    rejectedBox: {
        background: theme.palette.error.light,
        color: theme.palette.error.dark,
        ...(commonBox as CSSProperties),
    },

    lastItem: {
        '& .border-line': {
            display: 'none',
        },
    },

    xcircle: {
        zIndex: 11,
        '& path': {
            stroke: theme.palette.neutral.main,
        },
    },

    shareCountsWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '4px 4px 0 0',
    },
}));

const FileField: React.FC<IProps> = ({
    file,
    onRetake,
    onFileMenuOpen,
    withStateInfo = true,
    className,
    isLastItem = false,
    onClose,
    shareSectionHidden = false,
    watermark,
    children,
}) => {
    const theme = useTheme();

    const { sharedCounter, sharingAvailableCounter } = file;

    const [isPlayerOpen, setPlayerOpen] = useState(false);
    const [isImageViewerOpen, setImageViewerOpen] = useState(false);

    const {
        overlay,
        overlayError,
        overlayBorder,
        thumbnailWrapper,
        fileInfoWrapper,
        fileName,
        mainWrap,
        borderLine,
        play,
        uploadedBox,
        rejectedBox,
        lastItem,
        xcircle,
        shareCountsWrapper,
    } = useStyles({
        isLoading: !file.uploaded,
    });

    const withPreventOpenPlayer = usePreventOpenPlayer(file?.expired, onClose);

    const isPending = useSelector(selectPendingState(file.id));
    const isFulfilled = useSelector(selectFulfilledState(file.id));
    const isRejected = useSelector(selectRejectedState(file.id));
    const progress = useSelector(selectProgress(file.id));
    const remaining = useSelector(selectRemaining(file.id));

    const handleOpenFileMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        onFileMenuOpen && onFileMenuOpen();
    };

    const handleFileOpen = withPreventOpenPlayer((type: 'audio' | 'video' | 'image') => {
        if (type === MediaTypeEnum.Video || type === MediaTypeEnum.Audio) {
            return setPlayerOpen(true);
        }

        if (type === MediaTypeEnum.Image) {
            return setImageViewerOpen(true);
        }
    });

    const handleFileClose = () => {
        setPlayerOpen(false);
        setImageViewerOpen(false);
    };

    const renderThumbnail = (type: MediaType, src: string) => {
        if (type === MediaTypeEnum.Video) {
            return <img src={src} alt="video-evidence" />;
        }

        if (type === MediaTypeEnum.Image) {
            return <img src={src} alt="photo-evidence" />;
        }

        if (type === MediaTypeEnum.Audio) {
            return <img src={voiceImg} alt="voice-evidence" />;
        }
        return null;
    };

    const url = file.evidenceSharingUrl ? file.evidenceSharingUrl : file.temporaryFileUrl || '';

    const handleFileOpenAction = withPreventOpenPlayer(() => handleFileOpen(file.mediaType));

    return (
        <>
            <VideoPlayer
                isOpen={isPlayerOpen}
                src={url}
                title={file.name}
                onClose={handleFileClose}
                type={file.mediaType}
                watermark={watermark}
            />

            <ImageViewer
                isOpen={isImageViewerOpen}
                title={file.name}
                src={url}
                onClose={handleFileClose}
                watermark={watermark}
            />

            <Box
                className={classNames(mainWrap, className, { [lastItem]: isLastItem })}
                onClick={handleFileOpenAction}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexGrow={1}
                    className={fileInfoWrapper}
                    py={1}
                    pl={2}
                >
                    <Box
                        className={thumbnailWrapper}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mr={1.5}
                    >
                        <Box className={isRejected ? overlayError : overlay} />
                        {isRejected ? (
                            <XCircle className={xcircle} />
                        ) : (
                            file.uploadedAt && (
                                <>
                                    {renderThumbnail(file.mediaType, file.thumbnail)}
                                    <Box className={overlayBorder} />
                                    {file.mediaType === MediaTypeEnum.Video && (
                                        <PlayIcon className={play} />
                                    )}
                                </>
                            )
                        )}
                    </Box>
                    <Box pr={0.5} className={fileName}>
                        <Typography variant="body1" component="h2">
                            {truncateText(file?.name, 14)}
                        </Typography>

                        <Typography variant="caption" component="p">
                            {file.uploadedAt &&
                                dayjs(file.uploadedAt).format(DAYS_FORMAT_WITH_TIME)}{' '}
                            · {file.city}
                        </Typography>

                        {withStateInfo ? (
                            <Box mt="4px">
                                {isPending && (
                                    <Box width="100%">
                                        <AnimatedProgressBar value={progress} />

                                        <Typography variant="caption">
                                            {remaining
                                                ? `About ${msToTime(remaining)} remaining`
                                                : 'Initializing...'}
                                        </Typography>
                                    </Box>
                                )}

                                {(isFulfilled || file.uploaded) && (
                                    <Box className={uploadedBox} py="4px" px={1}>
                                        <Typography
                                            variant="subtitle2"
                                            style={{ lineHeight: '16px' }}
                                        >
                                            Uploaded
                                        </Typography>
                                    </Box>
                                )}

                                {isRejected && (
                                    <Box className={rejectedBox} py="4px" px={1}>
                                        <Typography
                                            variant="subtitle2"
                                            style={{ lineHeight: '16px' }}
                                        >
                                            Error
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box display="flex" flexWrap="wrap">
                                {!shareSectionHidden && (
                                    <Box className={shareCountsWrapper}>
                                        {sharingAvailableCounter > sharedCounter ? (
                                            <>
                                                <ShareIcon color={theme.palette.neutral.main} />

                                                <Box pl="2px">
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            color: theme.palette.neutral.darkGrey,
                                                        }}
                                                    >
                                                        {sharedCounter}/{sharingAvailableCounter}
                                                    </Typography>
                                                </Box>
                                            </>
                                        ) : (
                                            <ShareCoin />
                                        )}
                                    </Box>
                                )}

                                {file?.expireSoon ? (
                                    <ChipsExpired
                                        text={`EXPIRES SOON · ${dayjs(file?.expirationDate).format(
                                            DAYS_FORMAT
                                        )}`}
                                        type="expire_soon"
                                    />
                                ) : null}

                                {file?.expired ? (
                                    <ChipsExpired text="Expired" type="expired" />
                                ) : null}
                            </Box>
                        )}
                    </Box>

                    {isRejected && onRetake ? (
                        <Box mr={0.5}>
                            <IconButton
                                disabled={isPending}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onRetake();
                                }}
                            >
                                <Refresh />
                            </IconButton>
                        </Box>
                    ) : (
                        onFileMenuOpen && (
                            <Box mr={0.5}>
                                <IconButton
                                    disabled={isPending}
                                    onClick={(e) => handleOpenFileMenu(e)}
                                    style={{ padding: 7 }}
                                >
                                    <MenuVertical />
                                </IconButton>
                            </Box>
                        )
                    )}

                    {children}
                </Box>

                <div className={classNames(borderLine, 'border-line')} />
            </Box>
        </>
    );
};

export default FileField;
