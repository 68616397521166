import React from 'react';
import { Box } from '@material-ui/core';
import HeaderMain from 'modules/common/components/HeaderMain';
import useScreenHeight from 'hooks/useScreenHeight';
import { LayoutT } from '../types/layoutT';
import { OfflineBox } from '../modules/common/components';
import { useOnlineStatus } from '../hooks/useOnlineStatus';
import { useSelector } from 'react-redux';
import { selectOfflineEvidences, selectUploadInProgress } from '../store/slices/ui';

type IProps = LayoutT & {
    children: React.ReactNode;
    subHeader?: React.ReactNode;
    childrenWrapperClassName?: string;
};

const LayoutMain: React.FC<IProps> = ({
    children,
    subHeader,
    childrenWrapperClassName,
    ...props
}) => {
    const screenHeight = useScreenHeight();
    const isOnline = useOnlineStatus();
    const uploadInProgress = useSelector(selectUploadInProgress);
    const offlineEvidences = useSelector(selectOfflineEvidences);
    return (
        <>
            <OfflineBox />
            <Box
                position="fixed"
                top={(!isOnline || offlineEvidences > 0) && !uploadInProgress ? 50 : 0}
                width="100%"
                maxWidth="597px"
                zIndex={100}
            >
                <HeaderMain {...props} />
                {subHeader && subHeader}
            </Box>
            <Box
                pt={`${50 + ((isOnline && !offlineEvidences) || uploadInProgress ? 0 : 50)}px`}
                minHeight={screenHeight}
                className={childrenWrapperClassName}
            >
                {children}
            </Box>
        </>
    );
};

export default LayoutMain;
