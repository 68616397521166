import React, { CSSProperties, forwardRef } from 'react';
import { Dialog, Container, makeStyles, createStyles, Theme } from '@material-ui/core';
import useScreenHeight from 'hooks/useScreenHeight';
import { DialogTransition } from 'modules/common/components/DialogTransition';

interface IProps extends CSSProperties {
    open: boolean;
    onClose?: () => void;
    ref?: any;
    scroll?: 'paper' | 'body';
    bgColor?: string;
    children: React.ReactChild;
}

interface StyleProps {
    windowHeight: number;
    bgColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        container: {
            height: '100vh',
            padding: '0',
            overflow: 'auto',
            backgroundColor: ({ bgColor }) => bgColor,
            borderLeft: `1px solid ${theme.palette.neutral.grey}`,
            borderRight: `1px solid ${theme.palette.neutral.grey}`,
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    })
);

const CustomDialog: React.FC<IProps> = forwardRef(
    ({ open, onClose, scroll, bgColor = '#fff', children, ...styles }, ref) => {
        const screenHeight = useScreenHeight();

        const { container } = useStyles({
            windowHeight: screenHeight,
            bgColor,
        });

        return (
            <Dialog
                ref={ref}
                open={open}
                onClose={onClose}
                scroll={scroll}
                fullScreen
                TransitionComponent={DialogTransition}
            >
                <Container innerRef={ref} maxWidth="sm" className={container} style={{ ...styles }}>
                    {children}
                </Container>
            </Dialog>
        );
    }
);

CustomDialog.displayName = 'CustomDialog';

export default CustomDialog;
