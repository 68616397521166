import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

type UserState = {
    accessFolderToken: string | null;
    accessToken: string | null;
    refreshToken: string | null;
};

const initialState: UserState = {
    accessFolderToken: null,
    accessToken: null,
    refreshToken: null,
};

const loginInfoSlice = createSlice({
    name: 'loginInfo',
    initialState,
    reducers: {
        setTokens: (
            state,
            {
                payload: { accessToken, refreshToken },
            }: PayloadAction<{ accessToken: string; refreshToken: string }>
        ) => {
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
        },

        setAccessFolderToken: (state, { payload: accessFolderToken }: PayloadAction<string>) => {
            state.accessFolderToken = accessFolderToken;
        },

        deleteFolderToken: (state) => {
            state.accessFolderToken = null;
        },
        logout: () => {
            sessionStorage.removeItem('hasPaymentMethod');
            return initialState;
        },
    },
});

export const selectToken = (state: RootState) => state.loginInfo.accessToken;
export const selectRefreshToken = (state: RootState) => state.loginInfo.refreshToken;
export const selectAccessFolderToken = (state: RootState) => state.loginInfo.accessFolderToken;

export const {
    logout,
    setTokens,
    setAccessFolderToken,
    deleteFolderToken,
} = loginInfoSlice.actions;

export default loginInfoSlice.reducer;
