import React from 'react';
import { Box, makeStyles, Theme, createStyles, Typography, IconButton } from '@material-ui/core';
import { ReactComponent as CreditCard } from 'assets/icons/default/credit-card.svg';
import { ReactComponent as MenuVertical } from 'assets/icons/default/menu-vertical.svg';
import { extend } from 'dayjs';
import dayjsPluginIsBetween from 'dayjs/plugin/isBetween';
import { theme } from 'theme';

extend(dayjsPluginIsBetween);

interface IProps {
    defaultMethod: boolean;
    wallet: boolean;
    brand: string;
    last4: string;
    onClick?: () => void;
    onCardControlsOpen?: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            cursor: 'pointer',
        },

        folderInfo: {
            minWidth: 0,
            textTransform: 'capitalize',
            '& h3': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        details: {
            minWidth: 0,
            borderBottom: `0.5px solid ${theme.palette.neutral.grey}`,
        },
    })
);

const CardItem: React.FC<IProps> = ({
    defaultMethod,
    brand,
    last4,
    onClick,
    onCardControlsOpen,
    wallet,
}) => {
    const { wrapper, details, folderInfo } = useStyles();

    return (
        <Box display="flex" alignItems="center" className={wrapper} pl={2}>
            <Box onClick={onClick}>
                <CreditCard />
            </Box>

            <Box
                ml="14px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={details}
                pr={0.5}
                width="100%"
                onClick={onClick}
            >
                <Box className={folderInfo}>
                    <Typography variant="subtitle1" component="h3">
                        {`${brand} •••• ${last4}`}
                    </Typography>

                    <Typography
                        variant="caption"
                        style={{ color: theme.palette.neutral.darkGrey }}
                        component="p"
                    >
                        {defaultMethod && wallet
                            ? 'Used for payments with Credit Card and CreditCard Wallet'
                            : defaultMethod
                            ? 'For payments with Credit Card'
                            : wallet
                            ? 'For payments with CreditCard Wallet'
                            : null}
                    </Typography>
                </Box>

                <Box>
                    <IconButton onClick={onCardControlsOpen}>
                        <MenuVertical />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default CardItem;
