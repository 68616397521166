import React, { FC, ReactNode } from 'react';
import { Box, createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import StyledCheckbox from '../CheckBox';
import { CheckboxProps } from '@material-ui/core/Checkbox';

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 52px 8px 0px',
            '& > .MuiBox-root': {
                flexGrow: 1,
            },

            '&:not(:last-child)': {
                '&:after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    borderBottom: `0.5px solid ${theme.palette.neutral.light}`,
                },
            },
        },

        actionButtonStyle: {
            position: 'absolute',
            right: ({ iconRight = 5 }) => iconRight,
        },
    })
);

interface StylesProps {
    iconRight?: number;
}

interface IProps extends CheckboxProps, StylesProps {
    children: ReactNode;
    pl?: number;
}

const CheckOption: FC<IProps> = ({ children, iconRight, ...props }) => {
    const { wrapper, actionButtonStyle } = useStyles({ iconRight });

    return (
        <Box className={wrapper}>
            <Box>
                <Typography variant="caption">{children}</Typography>
            </Box>

            <IconButton className={actionButtonStyle}>
                <StyledCheckbox {...props} />
            </IconButton>
        </Box>
    );
};

export default CheckOption;
