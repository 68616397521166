import React from 'react';
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface IProps extends Omit<BackdropProps, 'open'> {
    isOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);

const BackdropLoader: React.FC<IProps> = ({ isOpen, children, ...props }) => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={isOpen} {...props}>
            {children || <CircularProgress color="primary" />}
        </Backdrop>
    );
};

export default BackdropLoader;
