import { keysToCamel, keysToSnake } from 'modules/common/helpers/transformKeysStyle';
import { BASE_URL, baseApi } from 'store/services/base';
import {
    CheckSharingCodeRequest,
    CheckSharingCodeResponse,
    CreateShareFolderUrlRequest,
    CreateShareFolderUrlResponse,
    IFolderShareRequest,
    ISharedEvidence,
    ISharedFolderDetails,
    IUnauthSharedFolder,
    SendSharingCodeRequest,
    SharingPriceRequest,
    SharingPriceResponse,
} from './types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../../index';
import {
    IOrganizationEvidenceBaseRequest,
    IOrganizationFolder,
    IOrganizationMultipartUploadCompleteRequest,
    IOrganizationMultipartUploadStartRequest,
    IOrganizationProofCreateRequest,
    IOrganizationUpdateProofRequest,
} from '../organizations/types';
import {
    CreateImageUploadLinkResponse,
    CreateImageUploadLinkResponseTransformed,
    MultipartUploadCompleteResponse,
    MultipartUploadCompleteResponseTransformed,
    MultipartUploadStartResponse,
    MultipartUploadStartResponseTransformed,
    ProofCreateResponse,
    ProofCreateResponseTransformed,
    UpdateProofResponse,
    UpdateProofResponseTransformed,
} from '../evidence/types';
import { setFulfilledFile, setPendingFile, setRejectedFile } from '../../slices/upload';

export const sharingApiAuth = createApi({
    reducerPath: 'sharingApiAuth',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const {
                loginInfo: { accessFolderToken },
            } = getState() as RootState | any;

            if (accessFolderToken) {
                headers.set('authorization', `Bearer ${accessFolderToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAuthSharedFolder: builder.query<IUnauthSharedFolder, void>({
            query: () => ({ url: 'sharing/view/authenticated/' }),
            transformResponse: (res) => keysToCamel(res),
        }),
    }),
    tagTypes: [],
});

export const sharingApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUnauthSharedFolder: builder.query<
            IUnauthSharedFolder & { accessToken: string },
            { cid?: string }
        >({
            query: (params) => ({ url: 'sharing/view/', params }),
            transformResponse: (res) => keysToCamel(res),
        }),

        createShareFolderUrl: builder.mutation<
            CreateShareFolderUrlResponse,
            CreateShareFolderUrlRequest
        >({
            query: ({ active, ...props }) => ({
                url: `sharing/url/create/`,
                method: 'POST',
                params: { active },
                body: keysToSnake(
                    Object.fromEntries(Object.entries(props).filter(([, val]) => !!val))
                ),
            }),
            transformResponse: (res) => keysToCamel(res),
        }),

        sendSharingCode: builder.mutation<{ id: string }, SendSharingCodeRequest>({
            query: (body) => ({
                url: `sharing/code/send/`,
                method: 'POST',
                body: keysToSnake(body),
            }),

            transformResponse: (res) => keysToCamel(res),
        }),

        checkSharingCode: builder.mutation<CheckSharingCodeResponse, CheckSharingCodeRequest>({
            query: ({ codeId, ...body }) => ({
                url: `sharing/code/${codeId}/check/`,
                method: 'POST',
                body: keysToSnake(body),
            }),

            transformResponse: (res) => keysToCamel(res),
        }),

        getSharingPrice: builder.query<SharingPriceResponse, SharingPriceRequest>({
            query: (body) => ({
                url: `/payments/sharing-price/calculate/`,
                method: 'POST',
                body: keysToSnake(body),
            }),

            transformResponse: (response) => keysToCamel(response),
        }),

        shareOrganizationFolder: builder.mutation<void, IFolderShareRequest>({
            query: ({ folderId, ...body }) => ({
                url: `sharing/folder/${folderId}/share/`,
                method: 'POST',
                body: keysToSnake(body),
            }),
        }),

        authenticateOrganizationFolder: builder.mutation<
            CheckSharingCodeResponse,
            { folderId: string; verificationCode: string }
        >({
            query: ({ folderId, ...body }) => ({
                url: `sharing/folder/${folderId}/authenticate/`,
                method: 'POST',
                body: keysToSnake(body),
            }),
        }),

        getOrganizationSharedFolderDetails: builder.query<
            ISharedFolderDetails,
            { folderId: string }
        >({
            query: ({ folderId }) => ({
                url: `/sharing/folder/${folderId}/details/`,
                method: 'GET',
            }),

            transformResponse: (response) => keysToCamel(response),
        }),

        getSharedFolderContents: builder.query<IOrganizationFolder, { folderId: string }>({
            query: ({ folderId }) => ({
                url: `sharing/folder/${folderId}/contents/`,
                method: 'GET',
            }),
        }),

        getOrganizationSharedEvidence: builder.query<
            ISharedEvidence,
            { folderId: string; stepId: string }
        >({
            query: ({ folderId, stepId }) => ({
                url: `sharing/evidence/${folderId}/${stepId}/`,
            }),
            transformResponse: (res) => keysToCamel(res),
            providesTags: ['OrganizationEvidence'],
        }),

        createAnonymousProof: builder.mutation<
            ProofCreateResponseTransformed,
            IOrganizationProofCreateRequest
        >({
            query({ folderId, stepId, ...payload }) {
                return {
                    url: `sharing/anonymous/proofs/${folderId}/${stepId}/create/`,
                    method: 'POST',
                    body: keysToSnake(
                        Object.fromEntries(Object.entries(payload).filter(([, v]) => v !== ''))
                    ),
                };
            },

            transformResponse: (response: ProofCreateResponse) => keysToCamel(response),

            invalidatesTags: ['OrganizationEvidence'],
        }),

        anonymousMultipartUploadStart: builder.mutation<
            MultipartUploadStartResponseTransformed,
            IOrganizationMultipartUploadStartRequest
        >({
            query({ evidenceId, ...payload }) {
                return {
                    url: `sharing/anonymous/proofs/${evidenceId}/multipart-upload/start/`,
                    method: 'POST',
                    body: keysToSnake(payload),
                };
            },

            transformResponse: (response: MultipartUploadStartResponse) => {
                return {
                    uploadId: response.upload_id,
                    presignedUrls: Object.values(response.presigned_urls),
                };
            },

            async onQueryStarted({ evidenceId }, { dispatch, queryFulfilled }) {
                try {
                    dispatch(setPendingFile(evidenceId));
                    await queryFulfilled;
                } catch {
                    dispatch(setRejectedFile(evidenceId));
                }
            },

            invalidatesTags: ['OrganizationEvidence'],
        }),

        anonymousMultipartUploadComplete: builder.mutation<
            MultipartUploadCompleteResponseTransformed,
            IOrganizationMultipartUploadCompleteRequest
        >({
            query({ evidenceId, payload }) {
                return {
                    url: `sharing/anonymous/proofs/${evidenceId}/multipart-upload/finish/`,
                    method: 'POST',
                    body: keysToSnake(payload),
                };
            },

            transformResponse: (response: MultipartUploadCompleteResponse) => keysToCamel(response),

            async onQueryStarted({ evidenceId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(setFulfilledFile(evidenceId));
                } catch {
                    dispatch(setRejectedFile(evidenceId));
                }
            },

            invalidatesTags: ['OrganizationEvidence'],
        }),

        anonymousSingleUploadCreate: builder.mutation<
            CreateImageUploadLinkResponseTransformed,
            IOrganizationEvidenceBaseRequest
        >({
            query({ evidenceId }) {
                return {
                    url: `sharing/anonymous/proofs/proofs/${evidenceId}/single-upload-url/create/`,
                    method: 'POST',
                };
            },

            async onQueryStarted({ evidenceId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(setFulfilledFile(evidenceId));
                } catch {
                    dispatch(setRejectedFile(evidenceId));
                }
            },

            transformResponse: (response: CreateImageUploadLinkResponse) => {
                const { url, fields: requestHeaders } = response.presigned_upload_url;

                return {
                    url,
                    requestHeaders,
                };
            },
        }),

        updateAnonymousProof: builder.mutation<
            UpdateProofResponseTransformed,
            IOrganizationUpdateProofRequest
        >({
            query({ evidenceId, payload }) {
                return {
                    url: `sharing/anonymous/proofs/${evidenceId}/update/`,
                    method: 'PUT',
                    body: payload,
                };
            },

            async onQueryStarted({ evidenceId, uploaded }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    if (uploaded) {
                        dispatch(setFulfilledFile(evidenceId));
                    }
                } catch {
                    dispatch(setRejectedFile(evidenceId));
                }
            },

            transformResponse: (response: UpdateProofResponse) => keysToCamel(response),

            invalidatesTags: ['OrganizationEvidence'],
        }),
    }),

    overrideExisting: true,
});

export const {
    useGetUnauthSharedFolderQuery,
    useCreateShareFolderUrlMutation,
    useSendSharingCodeMutation,
    useCheckSharingCodeMutation,
    useGetSharingPriceQuery,
    useShareOrganizationFolderMutation,
    useGetOrganizationSharedFolderDetailsQuery,
    useGetSharedFolderContentsQuery,
    useAuthenticateOrganizationFolderMutation,
    useGetOrganizationSharedEvidenceQuery,
    useCreateAnonymousProofMutation,
    useAnonymousMultipartUploadStartMutation,
    useAnonymousMultipartUploadCompleteMutation,
    useAnonymousSingleUploadCreateMutation,
    useUpdateAnonymousProofMutation,
} = sharingApi;

export const { useGetAuthSharedFolderQuery } = sharingApiAuth;
