import React, { useRef, useState } from 'react';
import { Box, IconButton, InputAdornment, makeStyles, Theme } from '@material-ui/core';

import Input from 'modules/common/components/Input';
import { ReactComponent as Eye } from 'assets/icons/default/eye.svg';
import { ReactComponent as EyeOff } from 'assets/icons/default/eye-off.svg';
import { ReactComponent as Success } from 'assets/icons/color/success-pw.svg';
import { ReactComponent as Error } from 'assets/icons/color/error-pw.svg';
import { useController } from 'react-hook-form';
import { passwordRules } from 'modules/common/constants/formRules';

interface StyleProps {
    isPasswordValid: boolean;
    hasError: boolean;
}

interface IProps {
    control: any;
    name?: string;
    label?: string;
    extraRules?: any;
    autoFocus?: boolean;
    withPasswordRules?: boolean;
    preventCopy?: boolean;
    preventPaste?: boolean;
    disabled?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    inputWrapper: {
        '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink': {
            transform: ({ isPasswordValid, hasError }) =>
                isPasswordValid || hasError ? 'translate(36px, 6px)' : 'translate(12px, 6px)',
            color: ({ isPasswordValid, hasError }) =>
                isPasswordValid
                    ? theme.palette.success.main
                    : hasError
                    ? theme.palette.error.main
                    : theme.palette.neutral.main,
        },

        '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled': {
            marginTop: 0,
            paddingLeft: '10px',
        },

        '& .MuiInputBase-input.MuiFilledInput-input.MuiInputBase-inputAdornedStart MuiFilledInput-inputAdornedStart.MuiInputBase-inputAdornedEnd.MuiFilledInput-inputAdornedEnd': {
            padding: 0,
        },

        '& .MuiInputBase-input.MuiFilledInput-input.MuiInputBase-inputAdornedStart.MuiFilledInput-inputAdornedStart.MuiInputBase-inputAdornedEnd.MuiFilledInput-inputAdornedEnd': {
            padding: ({ isPasswordValid, hasError }) =>
                isPasswordValid || hasError ? '23px 12px 10px 0px' : '23px 12px 10px 12px',
        },

        '& .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiFilledInput-adornedStart.MuiInputBase-adornedEnd.MuiFilledInput-adornedEnd': {
            border: ({ isPasswordValid, hasError }) =>
                isPasswordValid
                    ? `1px solid ${theme.palette.success.main}`
                    : hasError
                    ? `1px solid ${theme.palette.error.main}`
                    : `0.5px solid ${theme.palette.neutral.main}`,
        },

        '& .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-fullWidth.Mui-focused.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiFilledInput-adornedStart.MuiInputBase-adornedEnd.MuiFilledInput-adornedEnd': {
            border: ({ isPasswordValid, hasError }) =>
                isPasswordValid
                    ? `1px solid ${theme.palette.success.main}`
                    : hasError
                    ? `1px solid ${theme.palette.error.main}`
                    : `0.5px solid ${theme.palette.primary.main}`,

            boxShadow: ({ isPasswordValid, hasError }) =>
                isPasswordValid
                    ? '0.5px 2px 8px rgba(33, 177, 87, 0.24)'
                    : hasError
                    ? `0.5px 2px 8px rgba(214, 46, 46, 0.24)`
                    : '0.5px 2px 8px rgba(255, 190, 0, 0.24)',
        },
    },
}));

const PasswordInput: React.FC<IProps> = ({
    control,
    label = 'Password',
    name = 'password',
    extraRules = {},
    autoFocus = false,
    withPasswordRules = true,
    disabled = false,
    preventCopy,
    preventPaste,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const {
        field: { ref, value, ...inputProps },
        formState: { errors },
    } = useController({
        name,
        control,
        rules: {
            ...(withPasswordRules ? passwordRules : { required: 'This field is required' }),
            ...extraRules,
        },
        defaultValue: '',
    });

    const { inputWrapper } = useStyles({
        isPasswordValid: !!value && !errors[name] && withPasswordRules,
        hasError: Object.prototype.hasOwnProperty.call(errors, name),
    });

    const [showPw, setShowPw] = useState(false);
    const [isFocused, setFocused] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPw(!showPw);
    };

    const preventEvent = (e) => e.preventDefault();

    return (
        <Box className={inputWrapper}>
            <Input
                {...inputProps}
                inputRef={ref}
                label={label}
                disabled={disabled}
                onFocus={() => setFocused(true)}
                onBlur={() => {
                    if (value.length < 1) setFocused(false);
                }}
                onCopy={preventCopy ? preventEvent : undefined}
                onPaste={preventPaste ? preventEvent : undefined}
                type={`${showPw ? 'text' : 'password'}`}
                fullWidth
                autoFocus={autoFocus}
                helperText={errors?.[name]?.message}
                inputProps={{ maxLength: 32 }}
                InputProps={{
                    onSelect: () => {
                        inputRef.current!.selectionStart = inputRef.current!.selectionEnd;
                    },
                    inputRef,
                    startAdornment: (
                        <>
                            {!!value && !errors[name] && withPasswordRules && (
                                <InputAdornment position="start">
                                    <Success />
                                </InputAdornment>
                            )}
                            {!!errors[name] && (
                                <InputAdornment position="start">
                                    <Error />
                                </InputAdornment>
                            )}
                        </>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                            >
                                {showPw ? <Eye /> : <EyeOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                }}
                InputLabelProps={{
                    shrink: isFocused || !!value || !!errors[name],
                }}
            />
        </Box>
    );
};

export default PasswordInput;
