import { useState, useEffect } from 'react';

const getHeight = () => document.documentElement.clientHeight;

const useScreenHeight = () => {
    // save current window width in the state object
    const [screenHeight, setScreenHeight] = useState(getHeight());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        const resizeListener = () => {
            // change width from the state object
            setScreenHeight(getHeight());
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return screenHeight;
};

export default useScreenHeight;
