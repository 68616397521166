import React, { memo } from 'react';
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop';

const BackDropModalWrapper = ({ ...props }: BackdropProps) => {
    const onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    return <Backdrop {...props} onTouchMove={onTouchMove} />;
};

export default memo(BackDropModalWrapper);
