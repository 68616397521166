import { createTheme } from '@material-ui/core/styles';
import type {} from '@material-ui/lab/themeAugmentation';

const sourceSansProRegular: any = {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('SourceSansProRegular'),
    local('SourceSansProRegular'),
    url(/fonts/SourceSansPro-Regular.woff) format('woff')
  `,
};

const sourceSansProBold: any = {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
    local('SourceSansProBold'),
    local('SourceSansProBold'),
    url(/fonts/SourceSansPro-Bold.woff) format('woff')
  `,
};
const sourceSansProSemiBold: any = {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
    local('SourceSansProSemiBold'),
    local('SourceSansProSemiBold'),
    url(/fonts/SourceSansPro-SemiBold.woff) format('woff')
  `,
};

const poppinsSemiBold: any = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
    local('PoppinsSemiBold'),
    local('PoppinsSemiBold'),
    url(/fonts/Poppins-SemiBold.woff) format('woff')
  `,
};

const poppinsRegular: any = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('PoppinsRegular'),
    local('PoppinsRegular'),
    url(/fonts/Poppins-Regular.woff) format('woff')
  `,
};

export const theme = createTheme({
    palette: {
        primary: {
            transparent: '#FFF7CC',
            extraLight: '#FFE680',
            light: '#FFD54D',
            main: '#FFBE00',
            dark: '#F29912',
        },

        neutral: {
            black: '#0D0A00',
            darkGrey: '#52514E',
            main: '#8B8B88',
            grey: '#CCCBC9',
            extraLight: '#E6E5E3',
            light: '#E5E5E3',
            lightGrey: '#EDEDEB',
            extraLightGrey: '#F2F2F0',
            white: '#FFFFFF',
        },

        error: {
            transparent: '#FBE4E4',
            extraLight: '#F2BFBF',
            light: '#F08484',
            main: '#D62E2E',
            dark: '#951515',
        },

        success: {
            transparent: '#D3F8E0',
            extraLight: '#94EBB4',
            light: '#4DD17F',
            main: '#21B157',
            dark: '#0E6630',
        },

        blue: { dark: '#03254C', light: '#D0EFFF' },

        social: {
            fb: '#1877F2',
            apple: '#000000',
            google: '#FFFFFF',
        },
    },

    typography: {
        fontFamily: 'Source Sans Pro',

        h1: {
            fontWeight: 600,
            fontSize: '23px',
            lineHeight: '32px',
            fontFamily: 'Poppins',
        },
        h2: {
            fontWeight: 600,
            fontSize: '19px',
            lineHeight: '24px',
            fontFamily: 'Poppins',
        },
        h3: {
            fontWeight: 600,
            fontSize: '17px',
            lineHeight: '24px',
            fontFamily: 'Poppins',
            letterSpacing: '-0.25px',
        },
        h4: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            fontFamily: 'Poppins',
        },
        h5: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '16px',
        },
        h6: {
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '16px',
        },

        button: {
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '16px',
            textTransform: 'unset',
        },

        body1: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
        },

        body2: {
            fontWeight: 500,
            fontSize: '11px',
            lineHeight: '12px',
        },

        subtitle1: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '12px',
        },

        caption: {
            fontWeight: 400,
            fontSize: '13px',
            lineHeight: '16px',
        },
    },

    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    sourceSansProRegular,
                    sourceSansProBold,
                    sourceSansProSemiBold,
                    poppinsSemiBold,
                    poppinsRegular,
                ],
                '*': {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-webkit-text-size-adjust': '100%',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                },

                html: { '-webkit-text-size-adjust': '100%' },

                div: {
                    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
                },

                a: {
                    textDecoration: 'inherit',
                    color: 'inherit',
                },

                p: {
                    margin: 0,
                },

                '.mailLink': {
                    textDecoration: 'none',
                    color: '#F29912',
                },

                ol: { margin: 0, paddingLeft: 12 },

                body: {
                    backgroundColor: '#fff',
                    color: '#0D0A00',
                    height: '100%',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',

                    '& input::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    '& input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
                '.no-wrap-block': {
                    whiteSpace: 'nowrap',
                },
            },
        },

        MuiSelect: {
            select: {
                paddingRight: '16px !important',

                '&:active, &:focus': {
                    background: '#ffffff',
                },

                '&::before': {
                    borderBottom: '0px',
                },
            },
            icon: {
                top: 6,
            },
        },
        MuiMenuItem: {
            root: {
                padding: '8px 12px',
                minHeight: '20px',
            },
        },
        MuiSlider: {
            track: { backgroundColor: '#FFBE00' },
        },

        MuiButton: {
            root: {
                textTransform: 'unset',
            },
        },

        MuiAlert: {
            root: {
                padding: '8px 12px',
                borderRadius: 8,
                '& .MuiAlert-message': {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
            standardError: {
                backgroundColor: '#FBE4E4',
                color: '#D62E2E',
            },
        },

        MuiSvgIcon: {
            root: {
                width: '16px',
                height: '16px',
            },
        },

        MuiFilledInput: {
            input: {
                '&:-webkit-autofill': {
                    transition: 'background-color 5000s ease-in-out 0s',
                    backgroundColor: 'white !important',
                },
            },
        },

        MuiInputBase: {
            input: {
                '&:-webkit-autofill': {
                    transition: 'background-color 5000s ease-in-out 0s',
                    backgroundColor: 'white !important',
                },
            },
        },

        MuiFormHelperText: {
            root: {
                color: '#E31C79',
                backgroundColor: '#FBE4E4',
                boxShadow: '0px -16px 0px #FBE4E4',
                display: 'block',
                padding: '4px 8px',
                borderRadius: '0px 0px 8px 8px',
                margin: '0px',
                '&$focused': {
                    display: 'none',
                },
            },

            contained: {
                margin: '0px',
            },
        },
    },
});
