import React, { FC } from 'react';
import './styles.css';

interface IProps {
    value: number;
}

const AnimatedProgressBar: FC<IProps> = ({ value }) => {
    return (
        <div className="meter animate">
            <span style={{ width: `${value}%` }}>
                <span></span>
            </span>
        </div>
    );
};

export default AnimatedProgressBar;
