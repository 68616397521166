import { SVGProps } from 'react';

export enum MediaTypeEnum {
    Video = 'video',
    Audio = 'audio',
    Image = 'image',
}

export type MediaType = MediaTypeEnum.Audio | MediaTypeEnum.Image | MediaTypeEnum.Video;

export interface FileView {
    id: string;
    evidenceSharingUrl?: string;
    temporaryFileUrl?: string;
    storageUrl: string;
    name: string;
    address: string;
    city: string;
    mediaType: MediaType;
    sharedCounter: number;
    sharingAvailableCounter: number;
    fileTimestamp: string;

    uploaded: boolean;
    uploadedAt: string;
    duration: number | null;
    fileFormat: string;
    fileSize?: number;
    shared: boolean;
    sharedDate?: string;
    accessedDate?: string;
    thumbnail: string;
    caption?: string;
    latitude: string;
    longitude: string;
    expirationDate: string;
    expireSoon: boolean;
    expireSoonDate: string;
    expired: boolean;
    situationId?: string;
}

export interface Error {
    error: {
        data: {
            errors: {
                code: string;
                message: string;
            }[];
        };
        status: number;
    };
    isUnhandledError: boolean;
    meta: any;
}

export interface IIcon extends SVGProps<SVGSVGElement> {
    iconColor?: string;
}
