import React, { useEffect, useState } from 'react';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import { useHistory } from 'react-router';
import LayoutMain from 'layouts/LayoutMain';
import { useGlobalStyles } from 'theme/globalStyles';
import CardItem from '../components/CardItem';
import useScreenHeight from 'hooks/useScreenHeight';
import { useGetPaymentMethodsQuery } from 'store/services/payments';
import CardControlsMenu from '../components/CardControlsMenu';
import { DialogBox } from 'modules/common/components';
import { capitalize } from 'utils/strings';
import AddPaymentMethod from './AddPaymentMethod';
import { useAppDispatch } from 'hooks/store';
import { snackbarShown } from 'store/slices/ui';
import { useOnlineStatus } from '../../../hooks/useOnlineStatus';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            padding: '12px 0',
            color: theme.palette.primary.dark,
        },
    })
);

interface IProps {
    onClose?: any;
}

const PaymentMethods: React.FC<IProps> = ({ onClose }) => {
    const { button } = useStyles();
    const { whiteBg } = useGlobalStyles();

    const history = useHistory();
    const dispatch = useAppDispatch();
    const screenHeight = useScreenHeight();
    const { data: paymentMethods, refetch } = useGetPaymentMethodsQuery();

    const [isAddFormModalOpen, setAddFormModalOpen] = useState(false);

    const [isOpenAddPaymentMethod, setOpenAddPaymentMethod] = useState(false);
    const isOnline = useOnlineStatus();

    const [cardControlsMenuOpen, setCardControlsMenuOpen] = useState<{
        card: {
            id: string;
            defaultMethod: boolean;
            title: string;
        };
        isOpen: boolean;
    } | null>(null);

    const handleCardControlsClose = () => {
        if (cardControlsMenuOpen) {
            setCardControlsMenuOpen({
                ...cardControlsMenuOpen,
                isOpen: false,
            });
        } else {
            setCardControlsMenuOpen(null);
        }
    };

    const handleCardControlsOpen = (id: string, defaultMethod: boolean, title: string) => {
        setCardControlsMenuOpen({
            isOpen: true,
            card: {
                id,
                defaultMethod,
                title,
            },
        });
    };

    const handleAddPaymentMethodSlide = () => {
        setOpenAddPaymentMethod((state) => !state);
    };

    const closeAddFormModal = () => {
        setAddFormModalOpen(false);
        handleAddPaymentMethodSlide();
    };

    useEffect(() => refetch(), []);

    const handleOpenCreatedCardInfo = () => {
        closeAddFormModal();
        dispatch(snackbarShown({ type: 'info', message: 'Card was added.' }));
    };

    return (
        <>
            <DialogBox
                isOpen={isOpenAddPaymentMethod && isOnline}
                title="Add Payment Method"
                subTitle="This Payment Method will be used as Default for payments with Credit Card."
                submitText="Continue"
                onSubmit={() => setAddFormModalOpen(true)}
                onCancel={handleAddPaymentMethodSlide}
            />

            <LayoutMain
                flowTitle={'Payment Method'}
                onBack={() => (onClose ? onClose() : history.goBack())}
            >
                {cardControlsMenuOpen && (
                    <CardControlsMenu
                        isOpen={cardControlsMenuOpen.isOpen && isOnline}
                        isDefault={cardControlsMenuOpen.card.defaultMethod}
                        cardId={cardControlsMenuOpen.card.id}
                        title={cardControlsMenuOpen.card.title}
                        onClose={handleCardControlsClose}
                    />
                )}

                <Box height={screenHeight}>
                    <Box className={whiteBg} my={2}>
                        {paymentMethods &&
                            paymentMethods.map(({ id, ...props }: any) => {
                                const { defaultMethod, brand, last4 } = props;

                                return (
                                    <Box key={id}>
                                        <CardItem
                                            onCardControlsOpen={(e) => {
                                                e.stopPropagation();
                                                handleCardControlsOpen(
                                                    id,
                                                    defaultMethod,
                                                    `${capitalize(brand)} •••• ${last4}`
                                                );
                                            }}
                                            {...props}
                                        />
                                    </Box>
                                );
                            })}

                        <Button
                            className={button}
                            disabled={!isOnline}
                            onClick={handleAddPaymentMethodSlide}
                            fullWidth
                        >
                            <Typography variant="button">Add Payment Method</Typography>
                        </Button>
                    </Box>
                </Box>
            </LayoutMain>
            <AddPaymentMethod
                isOpen={isAddFormModalOpen && isOnline}
                onClose={closeAddFormModal}
                onSuccess={handleOpenCreatedCardInfo}
            />
        </>
    );
};

export default PaymentMethods;
