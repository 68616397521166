import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import Input from 'modules/common/components/Input';
import { phoneNumberRules } from 'modules/common/constants/formRules';
import { IInputTypes } from '../../../../../types/inputTypes';
import useDebounce from 'hooks/useDebounce';

type IPhoneNumberInput = IInputTypes & {
    onCheck?: () => void;
    optional?: boolean;
};

const PhoneNumberInput: React.FC<IPhoneNumberInput> = ({
    control,
    name = 'phoneNumber',
    onBlur,
    defaultValue = '',
    onCheck,
    optional,
    label = 'Phone Number',
}) => {
    const {
        field: { ref, value, ...inputProps },
        formState: { errors },
    } = useController({
        name,
        control,
        rules: { ...phoneNumberRules, ...(optional ? { required: false } : {}) },
        defaultValue,
    });

    const debouncedValue = useDebounce(value, 500);

    useEffect(() => {
        if (onCheck) onCheck();
    }, [debouncedValue]);

    return (
        <Input
            label={label}
            {...inputProps}
            type="number"
            onBlur={onBlur}
            inputProps={{ inputMode: 'numeric' }}
            hasError={optional && !value?.length ? false : errors[name]}
            onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            helperText={optional && !value?.length ? '' : errors[name]?.message}
            inputRef={ref}
            onPaste={(e) => e.preventDefault()}
        />
    );
};

export default PhoneNumberInput;
