import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    createStyles,
    IconButton,
    makeStyles,
    Slider,
    Typography,
    Modal,
    useMediaQuery,
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import { Theme } from '@material-ui/core/styles';
import TopBox from 'modules/evidence/components/TopBox';
import { ReactComponent as PauseButton } from 'assets/icons/white/camera-pause.svg';
import { ReactComponent as PlayButton } from 'assets/icons/white/camera-play.svg';
import BottomBox from 'modules/evidence/components/BottomBox';
import audioAnalyzerJSON from 'assets/json/audio-analyzer.json';
import { Lottie } from '@alfonmga/react-lottie-light-ts';
import { MediaType } from 'modules/common/types';
import { formattingTime } from '../../../../utils/format';
import BackDropModalWrapper from '../BackDropModalWrapper';
import { FileModalTypes } from '../../../../types/fileModalTypes';
import { ReactComponent as Maximize } from 'assets/icons/default/24/maximize.svg';
import { ReactComponent as Minimize } from 'assets/icons/default/minimize.svg';
import { useAppDispatch } from '../../../../hooks/store';
import { snackbarShown } from '../../../../store/slices/ui';
import { IWatermarkProps } from '../../../file-sharing/components/WatermarkBlock';
import PlayerWatermark from '../../../file-sharing/components/PlayerWatermark';
import { createStringNumsArray } from '../../helpers/createStringNumsArray';

type IProps = FileModalTypes & {
    type?: MediaType;
    watermark?: IWatermarkProps;
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        box: {
            background: theme.palette.neutral.black,
            color: theme.palette.neutral.white,
            minHeight: '100vh',
        },

        timing: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            '& .MuiSlider-root': {
                padding: 0,
                color: theme.palette.primary.dark,
            },
            '& .MuiSlider-thumb:not(.MuiSlider-active)': {
                transition: 'left 0.1s ease-in-out',
            },
            '& .MuiSlider-track': {
                transition: 'width 0.1s ease-in-out',
                height: '4px',
            },
            '& .MuiSlider-rail': {
                height: '4px',
            },
        },
    })
);

const VideoPlayer: React.FC<IProps> = ({
    isOpen,
    src,
    title,
    onClose,
    type = 'video',
    watermark,
}) => {
    const { box, timing } = useStyles();
    const dispatch = useAppDispatch();

    const [state, setState] = useState({
        playing: false,
        started: false,
        isEnded: false,
        controls: true,
        played: 0,
        playbackRate: 1.0,
        volume: 1,
        seeking: false,
    });

    useEffect(() => {
        setState((state) => ({ ...state, played: 0, playing: isOpen, started: isOpen }));
    }, [isOpen]);

    const { playing } = state;
    const playerRef = useRef<any>(null);

    const togglePlay = () => {
        setState({ ...state, playing: !state.playing, started: true });
    };

    const handleProgress = (changeState: any) => {
        if (!state.seeking) {
            setState({ ...state, ...changeState });
        }
    };

    const handleSeekChange = (_, newValue: number | number[]) => {
        if (Array.isArray(newValue)) return;
        setState({ ...state, played: newValue / 100 });
    };

    const handleSeekMouseUp = (_, newValue: number | number[]) => {
        if (Array.isArray(newValue)) return;
        setState({ ...state, seeking: false });
        playerRef.current.seekTo(newValue / 100, 'fraction');
    };

    const handleEnded = () => {
        setState({ ...state, isEnded: true, playing: false });
    };

    const currentTime =
        playerRef && playerRef.current ? playerRef.current.getCurrentTime() : '00:00';

    const duration = () => {
        if (playerRef && playerRef.current) {
            const duration = playerRef.current.getDuration();
            if (duration === Infinity) {
                return '0:00';
            }
            return duration;
        }
        return '0:00';
    };

    const ref = useRef<any>(null);
    const controlsRef = useRef<any>(null);
    const isUpMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const elapsedTime = formattingTime(currentTime);

    const totalDuration = formattingTime(duration());

    useEffect(() => {
        setState({ ...state, playing: true, started: true });
    }, []);

    const defaultOptions = {
        loop: true,
        animationData: audioAnalyzerJSON,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
        isPaused: true,
    };

    const handleToggleFullScreen = () => {
        if (document.fullscreenEnabled) {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                ref.current?.requestFullscreen();
            }
        } else {
            dispatch(snackbarShown({ type: 'info', message: 'Fullscreen is not available.' }));
        }
    };

    return (
        <Modal open={isOpen} BackdropComponent={BackDropModalWrapper} ref={ref}>
            <Box>
                <Box className={box} display="flex" flexDirection="column" justifyContent="center">
                    <TopBox onClose={onClose} title={title} />
                    <Box>
                        <ReactPlayer
                            controls={false}
                            ref={playerRef}
                            url={src}
                            playsinline
                            playing={playing}
                            playIcon={<div />}
                            width="100%"
                            style={{ maxHeight: window.innerHeight }}
                            height={type === 'video' ? '100vh' : 0}
                            onProgress={handleProgress}
                            onEnded={handleEnded}
                        />
                    </Box>
                    {type === 'audio' && (
                        <Box
                            width="100%"
                            height={window.innerHeight}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mx="auto"
                        >
                            {createStringNumsArray(0, isUpMd ? 2 : 0).map((k) => (
                                <Lottie
                                    key={k}
                                    config={defaultOptions}
                                    height="400px"
                                    playingState={playing ? 'playing' : 'paused'}
                                />
                            ))}
                        </Box>
                    )}

                    {watermark && <PlayerWatermark ref={controlsRef} {...watermark} />}

                    <BottomBox ref={controlsRef}>
                        <Box pl={2} pr="4px" width="100%">
                            <Box className={timing} pt={1} width="100%">
                                <Box mr={1}>
                                    <Typography variant="subtitle2">{elapsedTime}</Typography>
                                </Box>

                                <Slider
                                    min={0}
                                    max={100}
                                    value={state.played * 100}
                                    onChange={handleSeekChange}
                                    onChangeCommitted={handleSeekMouseUp}
                                    aria-labelledby="continuous-slider"
                                />

                                <Box ml={1} pr="4px">
                                    <Typography variant="subtitle2">{totalDuration}</Typography>
                                </Box>

                                <IconButton
                                    style={{ padding: 12 }}
                                    onClick={handleToggleFullScreen}
                                >
                                    {document.fullscreenElement ? <Minimize /> : <Maximize />}
                                </IconButton>
                            </Box>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                zIndex={100}
                            >
                                <Box>
                                    {playing ? (
                                        <IconButton onClick={togglePlay}>
                                            <PauseButton />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={togglePlay}>
                                            <PlayButton />
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </BottomBox>
                </Box>
            </Box>
        </Modal>
    );
};

export default VideoPlayer;
