import React from 'react';
import { useController } from 'react-hook-form';

import Input from 'modules/common/components/Input';
import { emailRules } from 'modules/common/constants/formRules';
import { IInputTypes } from '../../../../../types/inputTypes';

const EmailInput: React.FC<IInputTypes> = ({
    control,
    name = 'email',
    placeholder = '',
    defaultValue = '',
    onBlur,
}) => {
    const {
        field: { ref, ...inputProps },
        formState: { errors },
    } = useController({
        name,
        control,
        rules: emailRules,
        defaultValue,
    });

    return (
        <Input
            label="Email"
            placeholder={placeholder}
            {...inputProps}
            onBlur={onBlur}
            inputRef={ref}
            hasError={errors[name]}
            helperText={errors[name]?.message}
            inputProps={{ maxLength: 250 }}
        />
    );
};

export default EmailInput;
