import React, { useRef, useImperativeHandle } from 'react';

import { theme } from 'theme';

const StripeInput = ({ component: Component, inputRef, ...props }) => {
    const elementRef = useRef<any>(null);
    useImperativeHandle(inputRef, () => ({
        focus: () => elementRef.current.focus,
    }));
    return (
        <Component
            onReady={(element: any) => (elementRef.current = element)}
            options={{
                style: {
                    base: {
                        color: theme.palette.neutral.black,
                    },
                    invalid: {
                        iconColor: theme.palette.neutral.black,
                        color: theme.palette.neutral.black,
                    },
                },
                placeholder: '',
            }}
            {...props}
        />
    );
};

export default StripeInput;
