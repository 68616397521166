import React, { ReactNode } from 'react';
import {
    Box,
    Container,
    createStyles,
    makeStyles,
    Typography,
    Modal,
    ButtonBase,
} from '@material-ui/core';
import { theme } from 'theme';
import { Variant } from '@material-ui/core/styles/createTypography';
import classNames from 'classnames';

export interface IColumnButton {
    text: string;
    isHighlighted?: boolean;
    onClick: () => void;
    disabled?: boolean;
}

interface IProps {
    isOpen: boolean;
    title?: ReactNode;
    subTitle?: string;
    children?: JSX.Element;
    subVariant?: Variant;
    buttons: IColumnButton[];
}

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            padding: '0 48px',
            zIndex: 100,
            width: '100%',
            border: 0,
            outline: 'none',
        },
        box: {
            borderRadius: '12px',
            background: theme.palette.neutral.white,
        },
        buttons: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',

            '& .MuiButtonBase-root': {
                padding: '12px 0px',
                borderTop: ` 0.5px solid ${theme.palette.neutral.grey}`,
            },
        },
        sub: {
            color: theme.palette.neutral.darkGrey,
            fontWeight: 400,
            marginTop: '2px',
            letterSpacing: '0.1px',
        },
        textGrey: { color: theme.palette.neutral.darkGrey },
        textYellow: { color: theme.palette.primary.dark },
        disabledButton: { background: theme.palette.neutral.grey },
    })
);

const DialogBoxColumn: React.FC<IProps> = ({
    isOpen,
    title,
    subTitle,
    children,
    subVariant = 'body1',
    buttons,
}) => {
    const {
        box,
        wrapper,
        buttons: buttonsClass,
        sub,
        textGrey,
        textYellow,
        disabledButton,
    } = useStyles();

    return (
        <Modal open={isOpen}>
            <Container maxWidth="sm" className={wrapper}>
                <Box className={box}>
                    <Box p={2} textAlign="center">
                        <Typography variant="h3" component="h3" align="center">
                            {title}
                        </Typography>

                        {!!subTitle && (
                            <Typography variant={subVariant} className={sub} align="center">
                                {subTitle}
                            </Typography>
                        )}

                        <Box>{children}</Box>
                    </Box>

                    <Box className={buttonsClass}>
                        {buttons.map(({ text, onClick, isHighlighted, disabled }) => (
                            <ButtonBase
                                key={text}
                                onClick={onClick}
                                disabled={disabled}
                                className={disabled ? disabledButton : ''}
                            >
                                <Typography
                                    variant="button"
                                    className={classNames(textGrey, {
                                        [textYellow]: isHighlighted && !disabled,
                                        [disabledButton]: disabled,
                                    })}
                                >
                                    {text}
                                </Typography>
                            </ButtonBase>
                        ))}
                    </Box>
                </Box>
            </Container>
        </Modal>
    );
};

export default DialogBoxColumn;
