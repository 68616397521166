import { usePingMutation } from 'store/services/accounts';
import { useState } from 'react';

const usePing = () => {
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
    const [makePing] = usePingMutation();

    const start = () => {
        setIntervalId(() =>
            setInterval(async () => {
                await makePing();
            }, 30000)
        );
    };

    const stop = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(undefined);
        }
    };

    return [start, stop, makePing];
};

export default usePing;
