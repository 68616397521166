import React from 'react';
import { Box, Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import { ReactComponent as ChevronRightColor } from 'assets/icons/color/chevron-right-arrow.svg';

interface IProps {
    onClick: () => void;
    children: JSX.Element | string;
}

const useButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            borderRadius: '8px',
            padding: '4px 5px 4px 16px',
        },
    })
);

const SaveButton: React.FC<IProps> = ({ children, onClick }) => {
    const button = useButtonStyles();
    return (
        <Button classes={button} onClick={onClick}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="button">{children}</Typography>
                <ChevronRightColor />
            </Box>
        </Button>
    );
};

export default SaveButton;
