import React, { ReactNode } from 'react';
import { Box, Button, createStyles, Dialog, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { DialogTransition } from 'modules/common/components/DialogTransition';
import { ReactComponent as CheckCircle } from 'assets/icons/color/check-circle.svg';
import classNames from 'classnames';
import { theme } from '../../../../theme';

export interface IData {
    actionButton: string | JSX.Element;
    onClick: () => void;
    disabled?: boolean;
    isSelected?: boolean;
    isHighlighted?: boolean;
    comingSoon?: boolean;
}

export interface ISlideupMenuButtonsData {
    text: string;
    onClick: () => void;
    primary?: boolean;
}

export interface ISlideUpMenuProps {
    isOpen: boolean;
    topTitle?: string;
    bottomTitle?: string | ReactNode;
    textOnBackgroud?: string;
    contentOnBackgroud?: JSX.Element | null;
    backgroundColor?: string;
    backLabel?: string;
    onClose?: () => void;
    isInfoBlock?: boolean;
    data?: IData[] | null;
    buttonsData?: ISlideupMenuButtonsData[];
    children?: React.ReactNode;
}

interface StyleProps {
    backgroundColor?: string;
    isInfoBlock?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'fixed',
            bottom: 0,
            left: '50%',
            width: '100%',
            maxWidth: 600,
            transform: 'translateX(-50%)',
            padding: 0,
            border: 0,
            outline: 'none',
        },
        item: {
            background: 'transparent',
            textAlign: 'center',
            color: theme.palette.neutral.darkGrey,
            borderRadius: 0,
            boxShadow: 'none',
            height: '40px',
        },

        itemComingSoon: {
            background: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            color: theme.palette.neutral.darkGrey,
            borderRadius: 0,
            padding: '4px 0',
        },

        box: {
            borderRadius: '12px',
            background: theme.palette.neutral.white,

            '&:not(:last-child)': {
                marginBottom: '8px',
            },
            '& > div:first-child': {
                borderRadius: '12px',
            },

            '& > div:not(:first-child), button:not(:first-child)': {
                borderTop: ({ isInfoBlock }) => !isInfoBlock && '1px solid rgb(204 203 201)',
            },
        },
        titleStyle: {
            color: theme.palette.neutral.black,
        },
        topTitleStyle: {
            textTransform: 'uppercase',
        },
        checkCircle: {
            marginRight: '8px',
        },

        textOnBackgroudStyle: {
            background: ({ backgroundColor }) => backgroundColor,
            borderRadius: '8px',
        },

        primiryButton: {
            color: theme.palette.primary.dark,
        },

        grayColor: {
            color: theme.palette.neutral.main,
        },

        isSelectedStyle: {
            color: theme.palette.neutral.black,
            fontWeight: 600,
        },
    })
);

const SlideUpMenu: React.FC<ISlideUpMenuProps> = ({
    isOpen,
    bottomTitle,
    backLabel = 'Back',
    topTitle,
    textOnBackgroud,
    contentOnBackgroud = null,
    backgroundColor,
    onClose,
    isInfoBlock = false,
    data,
    buttonsData,
    children,
}) => {
    const {
        box,
        item,
        itemComingSoon,
        titleStyle,
        topTitleStyle,
        wrapper,
        checkCircle,
        textOnBackgroudStyle,
        primiryButton,
        isSelectedStyle,
        grayColor,
    } = useStyles({
        backgroundColor,
        isInfoBlock,
    });

    const maxHeight = `calc(100vh - ${
        (buttonsData ? buttonsData.length * 48 : 0) + (onClose ? 48 : 0) + 40
    }px)`;

    return (
        <Dialog open={isOpen} onClose={onClose} TransitionComponent={DialogTransition}>
            <Box className={wrapper} maxHeight="100vh">
                <Box px={2} pb={2.125}>
                    <Box className={box} mb={1} maxHeight={maxHeight} overflow="auto">
                        <Box
                            textAlign="center"
                            pt={topTitle ? 1 : 1.5}
                            pb={topTitle ? 1.2 : 1.5}
                            px={2}
                        >
                            {topTitle && (
                                <Box pb={0.25}>
                                    <Typography
                                        variant="subtitle2"
                                        component="h4"
                                        className={topTitleStyle}
                                    >
                                        {topTitle}
                                    </Typography>
                                </Box>
                            )}

                            <Box>
                                <Typography variant="h3" component="h3" className={titleStyle}>
                                    {bottomTitle}
                                </Typography>
                            </Box>

                            {!contentOnBackgroud && textOnBackgroud && (
                                <Box className={textOnBackgroudStyle} px={1.5} py={1} mt={1}>
                                    <Typography variant="h5" component="h5">
                                        {textOnBackgroud}
                                    </Typography>
                                </Box>
                            )}

                            {contentOnBackgroud}
                        </Box>

                        {data
                            ? data.map(
                                  (
                                      {
                                          actionButton,
                                          onClick,
                                          disabled,
                                          isSelected,
                                          isHighlighted,
                                          comingSoon,
                                      },
                                      i
                                  ) => (
                                      <Button
                                          key={i}
                                          className={!comingSoon ? item : itemComingSoon}
                                          onClick={onClick}
                                          disabled={disabled}
                                          fullWidth
                                      >
                                          <Box
                                              display="flex"
                                              alignItems="center"
                                              flexDirection="column"
                                          >
                                              <Box display="flex" alignItems="center">
                                                  {isSelected && (
                                                      <CheckCircle className={checkCircle} />
                                                  )}
                                                  {typeof actionButton === 'string' ? (
                                                      <Typography
                                                          variant="button"
                                                          style={{
                                                              color: isHighlighted
                                                                  ? theme.palette.primary.dark
                                                                  : undefined,
                                                          }}
                                                          className={classNames({
                                                              [isSelectedStyle]: isSelected,
                                                              [grayColor]: comingSoon,
                                                          })}
                                                      >
                                                          {actionButton}
                                                      </Typography>
                                                  ) : (
                                                      actionButton
                                                  )}
                                              </Box>
                                              {comingSoon && (
                                                  <Typography
                                                      variant="caption"
                                                      className={grayColor}
                                                  >
                                                      Coming soon
                                                  </Typography>
                                              )}
                                          </Box>
                                      </Button>
                                  )
                              )
                            : children}
                    </Box>

                    {buttonsData &&
                        buttonsData.map((button) => (
                            <Box key={button.text} className={box}>
                                <Button className={item} onClick={button.onClick} fullWidth>
                                    <Typography
                                        variant="button"
                                        align="center"
                                        className={(button.primary && primiryButton) || undefined}
                                    >
                                        {button.text}
                                    </Typography>
                                </Button>
                            </Box>
                        ))}

                    {onClose && (
                        <Box className={box}>
                            <Button className={item} onClick={onClose} fullWidth>
                                <Typography variant="button" align="center">
                                    {backLabel}
                                </Typography>
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
};

export default SlideUpMenu;
