import { keysToCamel, keysToSnake } from 'modules/common/helpers/transformKeysStyle';
import { baseApi } from 'store/services/base';
import { CreateReferralCodeRequest, IReferralCode, ReferralInfoType } from './types';

export const referralApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getReferralInfo: builder.query<ReferralInfoType, void>({
            query: () => ({ url: 'shareable-codes/referral-info/' }),
            transformResponse: (res) => keysToCamel(res),
            providesTags: ['ReferralCodes'],
        }),

        getReferralCodes: builder.query<{ results: IReferralCode[]; count: number }, string | void>(
            {
                query: (sorting) => ({
                    url: `shareable-codes/retrieve/?sorting=${sorting}`,
                    params: { limit: 50 },
                }),

                transformResponse: (res) => keysToCamel(res),
                providesTags: ['ReferralCodes'],
            }
        ),

        createReferralCode: builder.mutation<void, CreateReferralCodeRequest>({
            query: ({ type = 'individual', ...props }) => ({
                url: `shareable-codes/${type}/create/`,
                method: 'POST',
                body: keysToSnake(
                    Object.fromEntries(Object.entries(props).filter(([, val]) => !!val))
                ),
            }),
            invalidatesTags: ['ReferralCodes'],
        }),

        resendReferralCode: builder.mutation<void, string>({
            query: (id) => ({
                url: `shareable-codes/${id}/resend/`,
                method: 'POST',
            }),

            invalidatesTags: ['ReferralCodes'],
        }),

        cancelReferralCode: builder.mutation<void, string>({
            query: (id) => ({
                url: `shareable-codes/${id}/delete/`,
                method: 'DELETE',
            }),

            invalidatesTags: ['ReferralCodes'],
        }),

        checkReferralCode: builder.mutation<void, string>({
            query: (code) => ({
                url: `shareable-codes/check/`,
                method: 'POST',
                body: { code },
            }),
        }),

        checkPromoCode: builder.mutation<{ amount: number }, string>({
            query: (code) => ({
                url: `shareable-codes/promo-code/check/`,
                method: 'POST',
                body: { code },
            }),
        }),
    }),

    overrideExisting: true,
});

export const {
    useGetReferralInfoQuery,
    useGetReferralCodesQuery,

    useCreateReferralCodeMutation,
    useResendReferralCodeMutation,
    useCancelReferralCodeMutation,
    useCheckReferralCodeMutation,
    useCheckPromoCodeMutation,
} = referralApi;
