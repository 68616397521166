import React from 'react';
import { Box, Container, makeStyles, Theme } from '@material-ui/core';

import { PrimaryButton } from 'modules/common/components';
import { BoxProps } from '@material-ui/core/Box/Box';

interface StyleProps {
    background?: number;
}

interface IProps extends StyleProps, Pick<BoxProps, 'px'> {
    buttonTitle: string;
    disabled?: boolean;
    isLoading?: boolean;
    red?: boolean;
    onButtonClick: () => void;
    children?: JSX.Element;
}

const backgrounds = [
    'linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.96) 26.97%, #FFFFFF 100%)',
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 48.63%, #FFFFFF 100%)',
];

const useStyles = makeStyles<Theme, StyleProps>(() => ({
    buttonWrapper: {
        position: 'fixed',
        padding: 0,
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        background: ({ background }) => (background ? backgrounds[background - 1] : ''),
        maxWidth: '597px',
        zIndex: 100,
    },
    box: {
        padding: 16,
    },
}));

const ButtonBottomBlock: React.FC<IProps> = ({
    buttonTitle,
    onButtonClick,
    children,
    disabled = false,
    isLoading = false,
    background = 0,
    red,
    ...props
}) => {
    const { buttonWrapper, box } = useStyles({ background });

    return (
        <Container maxWidth="sm" className={buttonWrapper}>
            {children}
            <Box display="flex" className={box}>
                <Box flex={1} {...props}>
                    <PrimaryButton
                        onClick={onButtonClick}
                        disabled={disabled}
                        isLoading={isLoading}
                        red={red}
                    >
                        {buttonTitle}
                    </PrimaryButton>
                </Box>
            </Box>
        </Container>
    );
};

export default ButtonBottomBlock;
