import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme, createStyles, Box } from '@material-ui/core';

import { Router } from 'router/Router';
import Snackbar from 'modules/common/components/Snackbar';
import useScreenHeight from 'hooks/useScreenHeight';
import { Switch } from 'react-router-dom';

import { Routes } from 'router/routesConfig';
import { Route } from 'router/Route';

import BackdropLoader from 'modules/common/components/BackdropLoader';
import LocationSetUpModal from 'modules/common/components/LocationSetUpModal';
import localforage from 'localforage';
import { setUploadInProgress, updateOfflineEvidences } from './store/slices/ui';
import { useAppDispatch, useTypedSelector } from './hooks/store';
import { IdleTimerProvider } from 'react-idle-timer';
import { selectToken } from './store/slices/loginInfo';
import usePing from './hooks/usePing';
import InsufficientFundsDialog from './modules/common/components/InsufficientFundsDialog';
import useNoSleep from 'hooks/useNoSleep';

const SharedFolderView = lazy(() => import('modules/file-sharing/pages/SharedUnauthFolderView'));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 0,
            [theme.breakpoints.up('sm')]: {
                borderRight: `1px solid ${theme.palette.neutral.grey}`,
                borderLeft: `1px solid ${theme.palette.neutral.grey}`,
            },
            backgroundColor: theme.palette.neutral.extraLightGrey,
        },
        backgroundBox: {
            top: 0,
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            backgroundColor: theme.palette.neutral.extraLightGrey,
            zIndex: -1,
        },
    })
);

const time = location.hostname.includes('dev') ? 20 : 10;

const App: React.FC = () => {
    const screenHeight = useScreenHeight();
    const dispatch = useAppDispatch();
    const classes = useStyles({
        screenHeight,
    });

    const accessToken = useTypedSelector(selectToken);
    const [, , makePing] = usePing();

    const [isLoading, setIsLoading] = useState(true);
    useNoSleep(true);

    useLayoutEffect(() => {
        window.addEventListener('load', () => {
            setIsLoading(false);
        });

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.deferredPrompt = e;
        });
    }, []);

    useEffect(() => {
        async function initLocalForage() {
            localforage.config({ size: 100000000, storeName: 'proofkeep' });
            const evidences = await localforage.getItem('evidences');
            if (!evidences) {
                await localforage.setItem('evidences', {});
            }
            localforage.getItem('evidences').then((evidences: any) => {
                if (evidences) {
                    const totalEvidences = Object.keys(evidences).reduce(
                        (prev, key) => prev + evidences[key].data.length,
                        0
                    );
                    dispatch(updateOfflineEvidences(totalEvidences));
                }
            });
        }
        dispatch(setUploadInProgress(false));
        void initLocalForage();
    }, []);

    const onUserAction = async () => {
        if (accessToken) {
            await makePing();
        }
    };

    return (
        <IdleTimerProvider timeout={1000 * 60 * time} debounce={1000 * 2} onAction={onUserAction}>
            <BackdropLoader isOpen={isLoading} style={{ background: '#fff' }} />

            <Suspense fallback={<BackdropLoader isOpen={true} />}>
                <Switch>
                    <Route
                        exact
                        path={Routes.UNAUTH_SHARED_FOLDER_VIEW}
                        component={SharedFolderView}
                    />
                </Switch>
            </Suspense>

            <Container maxWidth="sm" className={classes.container}>
                <Router />
                <Snackbar />
                <LocationSetUpModal />
                <InsufficientFundsDialog />
            </Container>

            <Box className={classes.backgroundBox} />
        </IdleTimerProvider>
    );
};

export default App;
