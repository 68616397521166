import React from 'react';
import { Box, ButtonBase, createStyles, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import { ReactComponent as CloseX } from 'assets/icons/white/x.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topBar: {
            position: 'absolute',
            top: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            background:
                'linear-gradient(180deg, rgba(13, 10, 0, 0.88) 0%, rgba(13, 10, 0, 0.4) 77.13%, rgba(13, 10, 0, 0.32) 100%)',
        },
        topBarBox: {
            zIndex: 30,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: theme.palette.neutral.white,
        },
    })
);

interface IProps {
    onClose: () => void;
    title: string;
}

const TopBox: React.FC<IProps> = ({ title, onClose }) => {
    const { topBar, topBarBox } = useStyles();
    const theme = useTheme();

    return (
        <Box className={topBar}>
            <Box className={topBarBox}>
                <Box px={3} />

                <Box py={2} px="12px" textAlign="center">
                    <Typography variant="body1">{title}</Typography>
                </Box>

                <ButtonBase style={{ padding: 12 }} onClick={onClose}>
                    <CloseX color={theme.palette.neutral.white} />
                </ButtonBase>
            </Box>
        </Box>
    );
};

export default TopBox;
