import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { DialogBox, SlideUpMenu } from 'modules/common/components';
import {
    useDeletePaymentMethodMutation,
    useMakeDefaultPaymentMethodMutation,
} from 'store/services/payments';
import BackdropLoader from 'modules/common/components/BackdropLoader';
import { snackbarShown } from '../../../../store/slices/ui';
import { useAppDispatch } from '../../../../hooks/store';
import useCommutator from '../../../../hooks/useCommutator';
import { parseError } from '../../../common/helpers/parseError';

interface IProps {
    isOpen: boolean;
    isDefault: boolean;
    cardId: string;
    title: string;
    onClose: () => void;
}

const CardControlsMenu: React.FC<IProps> = ({ isOpen, isDefault, cardId, title, onClose }) => {
    const theme = useTheme();

    const dispatch = useAppDispatch();

    const [
        makeDefaultPaymentMethod,
        { isLoading: isMakeDefaultPaymentMethodLoading },
    ] = useMakeDefaultPaymentMethodMutation();
    const [
        deletePaymentMethod,
        { isLoading: isDeletePaymentMethodLoading },
    ] = useDeletePaymentMethodMutation();

    const [isRenameOpen, setRenameOpen] = useState(false);
    const [isChangeOpen, openChange, closeChange] = useCommutator(false);

    const toggleRemoveOpen = () => {
        onClose();
        setRenameOpen(!isRenameOpen);
    };

    const handleConfirmRemove = () => {
        toggleRemoveOpen();
        deletePaymentMethod({ paymentMethodId: cardId })
            .unwrap()
            .catch((e) => {
                dispatch(
                    snackbarShown({
                        type: 'error',
                        message:
                            (e?.data?.errors?.length && e?.data?.errors[0]?.message) ||
                            "Cannot remove user's default payment method. Set another method as default and try again.",
                    })
                );
            });
    };

    const handleSetDefault = async () => {
        closeChange();

        makeDefaultPaymentMethod({ paymentMethodId: cardId })
            .unwrap()
            .catch((e) => {
                dispatch(
                    snackbarShown({
                        type: 'info',
                        message: parseError(e, 'Payment method was not changed.'),
                    })
                );
            });
    };

    const folderControlsMenuOptions = [
        {
            isSelected: isDefault,
            actionButton: isDefault
                ? 'Default for Payments'
                : 'Set as Default for Payments with Credit Card',
            onClick: () => {
                if (!isDefault) openChange();
                onClose();
            },
        },
        {
            actionButton: 'Remove',
            onClick: toggleRemoveOpen,
        },
    ];

    return (
        <>
            <BackdropLoader
                isOpen={isMakeDefaultPaymentMethodLoading || isDeletePaymentMethodLoading}
            />

            <DialogBox
                isOpen={isChangeOpen}
                title="Change Payment Method?"
                subTitle="This Payment Method will be used as Default for payments with Credit Card."
                submitText="Continue"
                onSubmit={handleSetDefault}
                onCancel={closeChange}
            />

            <SlideUpMenu
                isOpen={isOpen}
                topTitle="Credit Card"
                backgroundColor={theme.palette.primary.transparent}
                bottomTitle={title}
                data={folderControlsMenuOptions}
                onClose={onClose}
            />

            <DialogBox
                isOpen={isRenameOpen}
                title={
                    <>
                        Remove Credit Card? <br />
                        <Typography variant="h3" style={{ display: 'inline', fontWeight: 400 }}>
                            {` ‘${title}’`}
                        </Typography>
                    </>
                }
                onSubmit={handleConfirmRemove}
                submitText="Remove"
                color={theme.palette.error.main}
                onCancel={toggleRemoveOpen}
            >
                <Box mt="2px" textAlign="center">
                    <Typography variant="body1" style={{ color: theme.palette.neutral.darkGrey }}>
                        After you press Remove this Card will be removed from Payment Method
                    </Typography>
                </Box>
            </DialogBox>
        </>
    );
};

export default CardControlsMenu;
