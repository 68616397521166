import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { Box } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import inputCommonStyles from '../Input/style';

interface IProps {
    hasError?: boolean;
    value: string;
    mask: string;
    maskChar?: string;
    isReferral?: boolean;
    isCode?: boolean;
}

const InputWithMask: React.FC<IProps & TextFieldProps> = ({
    hasError = false,
    value,
    mask,
    maskChar = '',
    isReferral,
    isCode,
    ...props
}) => {
    const { inputWrapper } = inputCommonStyles({
        hasError,
        isDisabled: props.disabled,
        isReferral,
        hasValue: !!value.replaceAll(/_/gi, '').trim().length,
        isCode: isCode || isReferral,
    });

    return (
        <Box className={inputWrapper}>
            <ReactInputMask
                mask={mask}
                value={value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                disabled={false}
                maskChar={maskChar}
                autoFocus={props.autoFocus}
            >
                {() => (
                    <TextField
                        InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
                        variant="filled"
                        fullWidth
                        {...props}
                    />
                )}
            </ReactInputMask>
        </Box>
    );
};

export default InputWithMask;
