import React from 'react';
import { Step, Stepper, StepConnector, withStyles, StepIcon } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { theme } from 'theme';

interface IProps {
    activeStep: number;
    stepsCount?: number;
}

const useIconStyles = makeStyles({
    completeSvgIcon: {
        marginLeft: '-2px',
        marginRight: '-2px',
    },
});

const StepperIcon = () => {
    const { completeSvgIcon } = useIconStyles();

    return (
        <svg
            className={completeSvgIcon}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                fill="#FFBE00"
            />
            <path
                d="M14 7.5L8.5 13L6 10.5"
                stroke="#0D0A00"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const StyledStepConnector = withStyles((theme: Theme) => ({
    active: {
        '& $line': {
            background: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            background: theme.palette.primary.main,
        },
    },
    line: {
        border: 0,
        height: '2px',
        background: theme.palette.neutral.light,
    },
}))(StepConnector);

const useStepIconStyles = makeStyles({
    root: {
        padding: 0,
        color: theme.palette.neutral.light,
        '& $text': {
            color: 'white',
        },
    },
    active: {
        color: theme.palette.neutral.black,
    },
    completed: {
        padding: 0,
    },
    text: {
        fontWeight: 500,
        transform: 'translateY(2px)',
        fontSize: '19px',
    },
});

const useStyles = makeStyles({
    stepper: {
        padding: 0,
        '& .MuiStep-horizontal': {
            padding: '2px',
        },
    },
});

const StyledStepper: React.FC<IProps> = ({ stepsCount = 4, activeStep = 1 }) => {
    const { stepper } = useStyles();
    const classes = useStepIconStyles();

    const steps: Array<number> = [];

    for (let i = 1; i <= stepsCount; i++) {
        steps.push(i);
    }

    return (
        <Stepper
            activeStep={activeStep - 1}
            connector={<StyledStepConnector />}
            classes={{ root: stepper }}
        >
            {steps.map((stepNumber: number) => {
                const stepProps: { completed?: boolean } = {};

                if (stepNumber < activeStep) {
                    stepProps.completed = true;
                }

                return (
                    <Step key={stepNumber} {...stepProps}>
                        <StepIcon
                            icon={stepProps.completed ? <StepperIcon /> : stepNumber}
                            classes={classes}
                        />
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default StyledStepper;
