import React from 'react';

import { theme } from 'theme';
import { useGetUserDetailsQuery } from 'store/services/accounts';
import CustomDialog from '../CustomDialog';
import CreateStoredAccount from '../CreateStoredAccount/CreateStoredAccount';
import AddEditStoredAccount, {
    StoredAccountType,
} from '../AddEditStoredAccount/AddEditStoredAccount';
import PaymentMethods from 'modules/settings/pages/PaymentMethods';
import CardDeclinedDialog from '../CardDeclinedDialog';

interface IProps {
    dialogName?: DialogType | null;
    setDialogName: any;
    paymentPrice?: any;
    closeOnCreated?: boolean;
}

export enum DialogType {
    CREATE_STORED_ACCOUNT,
    TOP_UP_STORED_ACCOUNT,
    PAYMENT_METHODS,
    FAILED_PAYMENT,
}

const StoredAccountDialogs: React.FC<IProps> = ({
    dialogName,
    setDialogName,
    paymentPrice,
    closeOnCreated,
}) => {
    const { data: userDetails } = useGetUserDetailsQuery();

    const onClose = () => setDialogName(null);

    return (
        <>
            <CustomDialog open={dialogName === DialogType.CREATE_STORED_ACCOUNT}>
                <CreateStoredAccount onClose={onClose} closeOnCreated={closeOnCreated} />
            </CustomDialog>

            <CustomDialog
                open={dialogName === DialogType.TOP_UP_STORED_ACCOUNT}
                bgColor={theme.palette.neutral.lightGrey}
            >
                <AddEditStoredAccount
                    balance={userDetails?.creditCardWalletBalance}
                    required={paymentPrice?.data?.totalPrice}
                    onClose={onClose}
                    type={StoredAccountType.TOPUP}
                />
            </CustomDialog>

            <CustomDialog
                open={dialogName === DialogType.PAYMENT_METHODS}
                bgColor={theme.palette.neutral.lightGrey}
            >
                <PaymentMethods onClose={onClose} />
            </CustomDialog>

            <CardDeclinedDialog setDialogName={setDialogName} dialogName={dialogName} />
        </>
    );
};

export default StoredAccountDialogs;
