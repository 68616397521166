import React, { Dispatch, FC } from 'react';
import { Typography } from '@material-ui/core';

import DialogBox from 'modules/common/components/DialogBox';
import { theme } from 'theme';

interface IProps {
    isOpen: boolean;
    onClose: Dispatch<void>;
    onLeave: Dispatch<void>;
    title?: string;
}

const LeaveModal: FC<IProps> = ({ isOpen, onClose, onLeave, title = 'Leave this page?' }) => {
    return (
        <DialogBox
            isOpen={isOpen}
            title={title}
            onSubmit={onLeave}
            submitText="Leave"
            color={theme.palette.error.main}
            onCancel={onClose}
        >
            <Typography style={{ color: theme.palette.neutral.darkGrey }} align="center">
                Your progress and data will be lost.
            </Typography>
        </DialogBox>
    );
};

export default LeaveModal;
