import { Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
export const DialogTransition = forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => {
        return <Slide direction="up" ref={ref} {...props} />;
    }
);
