import React, { Dispatch, FC, SetStateAction } from 'react';
import { Typography } from '@material-ui/core';
import DialogBox from '../DialogBox';
import { DialogType } from '../StoredAccountDialogs/StoredAccountDialogs';
import { useLocation } from 'react-router';
import { Routes } from '../../../../router/routesConfig';
import { useHistory } from 'react-router-dom';

interface IProps {
    dialogName?: DialogType | null;
    setDialogName: Dispatch<SetStateAction<DialogType | null>>;
}

const CardDeclinedDialog: FC<IProps> = ({ dialogName, setDialogName }) => {
    const { push } = useHistory();
    const { pathname } = useLocation();

    const onClose = () => setDialogName(null);

    const onChangeCard = () => {
        if (pathname === Routes.CHECKOUT) {
            return push(Routes.PAYMENT_METHODS);
        }

        onClose();
    };
    return (
        <DialogBox
            isOpen={dialogName === DialogType.FAILED_PAYMENT}
            title="Your Card was declined"
            submitText="Change Card"
            onSubmit={onChangeCard}
            onCancel={onClose}
        >
            <Typography>
                We were unable to process your payment. Please change your payment method.
            </Typography>
        </DialogBox>
    );
};

export default CardDeclinedDialog;
