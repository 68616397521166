import React from 'react';
import { Route as ReactRout, RouteComponentProps } from 'react-router-dom';

import { Routes } from './routesConfig';

interface IProps {
    component: React.ComponentType<RouteComponentProps<any>>;
    exact?: boolean;
    path: Routes;
}

export const Route: React.FC<IProps> = ({ component: Component, ...rest }) => {
    return <ReactRout {...rest} render={(props) => <Component {...props} />} />;
};
