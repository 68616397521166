import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Container, IconButton, Typography, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useFormContext } from 'react-hook-form';

import SearchBar from 'modules/common/components/Search/SearchBar';
import { ReactComponent as ChevronRight } from 'assets/icons/default/chevron-right.svg';
import { ReactComponent as EmptySearchResultsIcon } from '../../../../assets/icons/empty-state/empty-search-results.svg';
import EmptyBlock from '../EmptyBlock';

interface IProps {
    onCloseAddressSelect: () => void;
    validate?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            background: '#fff',
            height: '100vh',
        },

        searchContainer: {
            padding: 0,
        },

        contentContainer: {
            padding: '0',
            paddingLeft: '16px',
        },

        searchItem: {
            borderBottom: `0.5px solid ${theme.palette.neutral.grey}`,
            cursor: 'pointer',
        },
    })
);

const AddressSelect: React.FC<IProps> = ({ onCloseAddressSelect }) => {
    const { contentContainer, backdrop, searchItem } = useStyles();

    const { setValue } = useFormContext();

    const [searchValue, setSearchValue] = useState('');
    const [predictions, setPredictions] = useState<{ city: string; country: string; id: string }[]>(
        []
    );

    const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    });

    const handleChangeSearchInput = (value) => {
        setSearchValue(value);
        getPlacePredictions({ input: value });
    };

    const handleCloseClick = () => {
        onCloseAddressSelect();
    };

    const handleSelectSearchResult = (id: string) => {
        const addressFields = {
            house: false,
            city: false,
            street: false,
            province: false,
            zipCode: false,
            country: false,
        };

        const setValueOptions = {
            shouldValidate: true,
            shouldDirty: true,
        };

        placesService?.getDetails(
            {
                placeId: id,
            },
            ({ formatted_address, address_components }) => {
                address_components &&
                    address_components.forEach(({ long_name, types }) => {
                        if (types.includes('country')) {
                            setValue('country', long_name, { ...setValueOptions });
                            addressFields.country = true;
                        }
                        if (types.includes('locality')) {
                            setValue('city', long_name, { ...setValueOptions });
                            addressFields.city = true;
                        }
                        if (types.includes('route')) {
                            setValue('street', long_name, { ...setValueOptions });
                            addressFields.street = true;
                        }
                        if (
                            types.includes('administrative_area_level_1') ||
                            types.includes('administrative_area_level_2') ||
                            types.includes('administrative_area_level_3')
                        ) {
                            setValue('province', long_name, { ...setValueOptions });
                            addressFields.province = true;
                        }
                        if (types.includes('postal_code')) {
                            setValue('zipCode', long_name, { ...setValueOptions });
                            addressFields.zipCode = true;
                        }
                        if (types.includes('street_number')) {
                            setValue('house', long_name, { ...setValueOptions });
                            addressFields.house = true;
                        }

                        setValue('foundAddress', formatted_address, { ...setValueOptions });

                        onCloseAddressSelect();
                    });
            }
        );

        Object.entries(addressFields).forEach((item) => {
            if (!item[1]) {
                setValue(item[0], '');
            }
        });
    };

    useEffect(() => {
        if (placePredictions.length) {
            const formattedPredictions = placePredictions.map(
                ({ structured_formatting: { main_text, secondary_text }, place_id }) => {
                    return {
                        city: main_text,
                        country: secondary_text,
                        id: place_id,
                    };
                }
            );

            setPredictions(formattedPredictions);
        }
    }, [placePredictions]);

    return (
        <Box className={backdrop}>
            <SearchBar
                searchValue={searchValue}
                placeholder="Type your Address"
                onChange={handleChangeSearchInput}
                onClose={handleCloseClick}
            />
            <Box>
                {searchValue.length > 0 && predictions.length === 0 && (
                    <EmptyBlock icon={EmptySearchResultsIcon} mt="145px">
                        <>
                            <Typography variant="h4">No Result Found</Typography>
                            <Typography>Try adjusting your search words.</Typography>
                        </>
                    </EmptyBlock>
                )}
                <Container maxWidth="sm" className={contentContainer}>
                    <Box>
                        {searchValue.length
                            ? predictions.map(({ city, country, id }) => {
                                  return (
                                      <Box
                                          key={id}
                                          display="flex"
                                          justifyContent="space-between"
                                          align-items="center"
                                          className={searchItem}
                                          onClick={() => handleSelectSearchResult(id)}
                                      >
                                          <Box
                                              display="flex"
                                              flexDirection="column"
                                              justifyContent="center"
                                          >
                                              <Box>
                                                  <Typography variant="subtitle1" component="h5">
                                                      {city}
                                                  </Typography>
                                              </Box>
                                              <Box>
                                                  <Typography variant="caption" component="h6">
                                                      {country}
                                                  </Typography>
                                              </Box>
                                          </Box>
                                          <Box>
                                              <IconButton>
                                                  <ChevronRight />
                                              </IconButton>
                                          </Box>
                                      </Box>
                                  );
                              })
                            : null}
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default AddressSelect;
