import React, { FC, ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';

interface IProps extends BoxProps {
    children: ReactNode;
}

const useStyles = makeStyles(() => ({
    wrapper: {
        zIndex: 101,
        position: 'fixed',
        maxWidth: 598,
        width: '100%',
        bottom: 0,
        left: '50%',
        right: '50%',
        transform: 'translate(-50%)',
        padding: 16,
        background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 23.28%, #FFFFFF 100%)',
    },
}));

const BottomBlock: FC<IProps> = ({ children, ...props }) => {
    const { wrapper } = useStyles();

    return (
        <Box {...props} className={wrapper}>
            {children}
        </Box>
    );
};

export default BottomBlock;
