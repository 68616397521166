import { snackbarShown } from '../store/slices/ui';
import { Dispatch } from 'react';
import { useAppDispatch } from './store';

const usePreventOpenPlayer = (expired: boolean | undefined, onClose?: () => void) => {
    const dispatch = useAppDispatch();

    return (callback: Dispatch<any>) => (e?: any) => {
        onClose && onClose();

        if (expired) {
            return dispatch(
                snackbarShown({
                    type: 'info',
                    message: 'Please extend your Storage Date for this file.',
                })
            );
        }

        callback(e);
    };
};

export default usePreventOpenPlayer;
