import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import StoredAccountImage from 'assets/img/stored-account.png';
import { useGlobalStyles } from 'theme/globalStyles';
import LayoutMain from 'layouts/LayoutMain';
import ButtonBottomBlock from '../ButtonBottomBlock';
import { useHistory } from 'react-router-dom';
import AddEditStoredAccount from '../AddEditStoredAccount/AddEditStoredAccount';

interface IProps {
    onClose?: () => void;
    closeOnCreated?: boolean;
}

const CreateStoredAccount: React.FC<IProps> = ({ onClose, closeOnCreated }) => {
    const [wellcomeScreen, setWellcomeScreen] = useState(true);
    const { defaultBackground } = useGlobalStyles();
    const history = useHistory();

    if (!wellcomeScreen) {
        return <AddEditStoredAccount onClose={onClose} closeOnCreated={closeOnCreated} />;
    }

    return (
        <>
            <LayoutMain
                flowTitle={'CreditCard Wallet'}
                onClose={() => (onClose ? onClose() : history.goBack())}
            >
                <Box pb={8} className={defaultBackground} overflow="scroll">
                    <Box px={4} py={1.5}>
                        <Box display="flex" justifyContent="center">
                            <img src={StoredAccountImage} height={224} alt="" />
                        </Box>

                        <Typography variant="h2">Create Your CreditCard Wallet</Typography>

                        <Box mt={2} mb={6}>
                            <Box mb={2}>
                                <Typography variant="caption">
                                    Your Credit Card Wallet on ProofKeep&#8482; is a personal
                                    payment instrument that offers you added convenience, and
                                    additional savings. By establishing a Credit Card Wallet and
                                    using it to pay for ProofKeep&#8482; Services, you will:
                                    <ul style={{ paddingLeft: 20 }}>
                                        <li>
                                            Save an extra 10% on all future ProofKeep&#8482; Service
                                            fees.
                                        </li>
                                        <li>
                                            Save on transaction and processing fees imposed by your
                                            credit card.
                                        </li>
                                    </ul>
                                </Typography>
                            </Box>

                            <Box mt={2}>
                                <Typography variant="caption">
                                    To establish your CreditCard Wallet, follow these 2 easy steps:
                                    <ul style={{ paddingLeft: 20 }}>
                                        <li>
                                            Select the desired credit card you wish to use for
                                            automatic fund transfers. If you have only one card on
                                            file, it will be automatically selected.
                                        </li>
                                        <li>
                                            Specify the amount you want to automatically transfer
                                            from the selected credit card to your CreditCard Wallet
                                            when the balance in your CreditCard Wallet drops below
                                            $5.00.
                                        </li>{' '}
                                    </ul>
                                    When using ProofKeep&#8482; services, your service charges will
                                    first be deducted from your CreditCard Wallet. If there are
                                    insufficient funds in your CreditCard Wallet, funds will be
                                    automatically transferred from the credit card you selected.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <ButtonBottomBlock
                        background={2}
                        onButtonClick={() => setWellcomeScreen(false)}
                        buttonTitle="Create CreditCard Wallet"
                    />
                </Box>
            </LayoutMain>
        </>
    );
};

export default CreateStoredAccount;
