import React from 'react';
import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ReactComponent as MenuVertical } from 'assets/icons/default/menu-vertical.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/white/play.svg';

import dayjs, { extend } from 'dayjs';
import dayjsPluginDuration from 'dayjs/plugin/duration';
import { MediaTypeEnum, FileView } from 'modules/common/types';
import voiceImg from 'assets/img/audio-recording.png';
import classNames from 'classnames';
import { DAYS_FORMAT_WITH_TIME } from 'modules/common/constants/dateOptions';

interface IProps {
    file: FileView;
    selected: boolean;
    onFileClick: () => void;
    onFileMenuOpen?: () => void;
}

interface StyleProps {
    isLoading: boolean;
}

extend(dayjsPluginDuration);

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        background: {
            background: theme.palette.neutral.white,
            cursor: ({ isLoading }) => (isLoading ? 'default' : 'pointer'),
        },

        overlay: {
            position: 'absolute',
            zIndex: 10,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #0D0A00 100%)',
            opacity: 0.32,
            overflow: 'hidden',
            borderRadius: '4px',
        },

        overlayBorder: {
            position: 'absolute',
            zIndex: 20,
            width: '100%',
            height: '100%',
            border: '2px solid rgba(13, 10, 0, 0.2)',
            borderRadius: 8,
        },

        thumbnailWrapper: {
            width: '100%',
            height: '100%',
            borderRadius: 8,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 0,
            marginBottom: 8,
            '& img': {
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: 8,
            },
        },

        fileInfoWrapper: {
            height: '100%',
            width: '100%',
            padding: 0,
            zIndex: 10,
        },

        fileName: {
            flex: 1,
            minWidth: 0,
            '& h2': {
                fontWeight: 600,
                overflowWrap: 'anywhere',
            },
        },

        play: {
            position: 'absolute',
            width: '20%',
            height: '20%',
            bottom: 4,
            left: 4,
            zIndex: 100,
        },
        menu: {
            position: 'absolute',
            width: '20%',
            height: '15%',
            bottom: '5%',
            right: 4,
            zIndex: 100,
            '& path': {
                fill: theme.palette.neutral.white,
            },
        },

        border: {
            height: 1,
            background: theme.palette.neutral.grey,
        },

        selectedFile: {
            border: `3px solid ${theme.palette.primary.main}`,
            width: 'calc(100% + 3px)',
            height: 'calc(100% + 3px)',
        },
        dateStyle: {
            color: theme.palette.neutral.darkGrey,
        },
    })
);

const FileFieldPoint: React.FC<IProps> = ({ file, selected, onFileClick, onFileMenuOpen }) => {
    const {
        overlay,
        overlayBorder,
        thumbnailWrapper,
        fileInfoWrapper,
        fileName,
        background,
        play,
        menu,
        selectedFile,
        dateStyle,
    } = useStyles({
        isLoading: !file.uploaded,
    });

    const handleOpenFileMenu = (e) => {
        e.stopPropagation();
        onFileMenuOpen && onFileMenuOpen();
    };

    const renderThumbnail = (type: 'image' | 'audio' | 'video', src: string) => {
        if (type === MediaTypeEnum.Video) {
            return <img src={src} alt="video-evidence" />;
        }

        if (type === MediaTypeEnum.Image) {
            return <img src={src} alt="photo-evidence" />;
        }

        if (type === MediaTypeEnum.Audio) {
            return <img src={voiceImg} alt="voice-evidence" />;
        }
        return null;
    };

    return (
        <>
            <Grid item xs={6} onClick={onFileClick}>
                <Box className={background}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="column"
                        alignItems="center"
                        flexGrow={1}
                        className={fileInfoWrapper}
                        py={1}
                        pl={2}
                    >
                        <Box
                            className={thumbnailWrapper}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mr={1.5}
                        >
                            {file.uploaded && renderThumbnail(file.mediaType, file.thumbnail)}
                            <Box className={overlay} />
                            <Box
                                className={classNames(overlayBorder, { [selectedFile]: selected })}
                            />
                            {file.mediaType === MediaTypeEnum.Video && (
                                <PlayIcon className={play} />
                            )}
                            {onFileMenuOpen && (
                                <MenuVertical
                                    className={menu}
                                    onClick={(e) => handleOpenFileMenu(e)}
                                />
                            )}
                        </Box>
                        <Box pr={0.5} width="100%" className={fileName}>
                            <Typography variant="body1" component="h2">
                                {file.name}
                            </Typography>

                            <Typography variant="caption" component="p" className={dateStyle}>
                                {file.uploadedAt &&
                                    dayjs(file.uploadedAt).format(DAYS_FORMAT_WITH_TIME)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </>
    );
};

export default FileFieldPoint;
