import { keysToCamel, keysToSnake } from 'modules/common/helpers/transformKeysStyle';
import { baseApi, CACHE_TAGS } from 'store/services/base';

import {
    CheckUserInfoResponse,
    CheckUserInfoResponseTransformed,
    ConfirmationCodeRequest,
    VerifyConfirmationCodeResponse,
    VerifyConfirmationCodeRequest,
    CreateUserRequest,
    CreateUserResponse,
    ResetPasswordRequest,
    VerifyPasswordRequest,
    LogInResponse,
    LogInRequest,
    GetUserDetailsResponse,
    GetUserDetailsResponseTransformed,
    UpdateUsernameRequest,
    UpdateEmailRequest,
    UpdatePhoneNumberRequest,
    UpdateAddressRequest,
    GetEventsHistoryResponse,
    VerifyUserByEmailResponse,
    ChangeCommunicationMethodRequest,
    VerifyUserPasswordRequest,
    VerifyUserPasswordResponseTransformed,
    VerifyUserPasswordResponse,
    VerifyUserByPhoneResponse,
    VerifyPasswordResponse,
    VerifyUserByEmailRequest,
    VerifyUserByPhoneRequest,
    UsernameByPhone,
    UpdateFirstLastNameRequest,
    IDeleteReason,
    DeleteUserRequest,
    CheckUserInfoRequest,
} from './types';

export const accountsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        checkUserInfo: builder.mutation<CheckUserInfoResponseTransformed, CheckUserInfoRequest>({
            query: (payload) => {
                return {
                    url: 'accounts/email-username-phone-number/verify/',
                    method: 'POST',
                    body: keysToSnake(payload),
                };
            },

            transformResponse: (response: CheckUserInfoResponse) => keysToCamel(response),
        }),

        checkNewUserPhone: builder.mutation({
            query: (payload: string) => ({
                url: 'accounts/phone/verify/',
                method: 'POST',
                body: { phone_number: payload },
            }),

            transformResponse: (response) => keysToCamel(response),
        }),

        checkUsernameByPhoneNumber: builder.mutation<void, UsernameByPhone>({
            query: (payload) => {
                return {
                    url: 'accounts/username-has-phone/verify/',
                    method: 'POST',
                    body: keysToSnake(payload),
                };
            },
        }),

        sendConfirmationCode: builder.mutation<any, ConfirmationCodeRequest>({
            query: (payload) => ({
                url: 'accounts/confirmation-code/send/',
                method: 'POST',
                body: keysToSnake(payload),
            }),
        }),

        verifyConfirmationCode: builder.mutation<
            VerifyConfirmationCodeResponse,
            VerifyConfirmationCodeRequest
        >({
            query: (payload) => ({
                url: 'accounts/confirmation-code/verify/',
                method: 'POST',
                body: keysToSnake(payload),
            }),
        }),

        createUser: builder.mutation<CreateUserResponse, CreateUserRequest>({
            query: (payload) => ({
                url: 'accounts/create/',
                method: 'POST',
                body: keysToSnake(payload),
            }),
        }),

        updateFirstLastName: builder.mutation<void, UpdateFirstLastNameRequest>({
            query: (payload) => ({
                url: 'accounts/first-last-names/update/',
                method: 'PATCH',
                body: keysToSnake(payload),
            }),
            invalidatesTags: ['UserInfo'],
        }),

        resetPassword: builder.mutation<void, ResetPasswordRequest>({
            query: (payload) => ({
                url: 'accounts/password/reset/',
                method: 'POST',
                body: keysToSnake(payload),
            }),

            invalidatesTags: ['EventsHistory'],
        }),

        logIn: builder.mutation<LogInResponse, LogInRequest>({
            query: (credentials) => ({
                url: 'accounts/login/',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: CACHE_TAGS,
        }),

        verifyUserByEmail: builder.mutation<VerifyUserByEmailResponse, VerifyUserByEmailRequest>({
            query: (payload) => ({
                url: 'accounts/username-by-email/verify/',
                method: 'POST',
                body: keysToSnake(payload),
            }),
        }),

        verifyUserByPhoneNumber: builder.mutation<
            VerifyUserByPhoneResponse,
            VerifyUserByPhoneRequest
        >({
            query: (payload) => ({
                url: 'accounts/username-by-phone/verify/',
                method: 'POST',
                body: keysToSnake(payload),
            }),
        }),

        getUserDetails: builder.query<GetUserDetailsResponseTransformed, void>({
            query: () => ({
                url: 'accounts/me/',
                method: 'GET',
            }),

            transformResponse: (response: GetUserDetailsResponse) => {
                sessionStorage.setItem('hasPaymentMethod', `${!!response.default_payment_method}`);
                return keysToCamel(response);
            },

            providesTags: ['UserInfo'],
        }),

        ping: builder.mutation<void, void>({
            query: () => ({ url: 'accounts/me/', method: 'GET' }),
        }),

        updateUsername: builder.mutation<void, UpdateUsernameRequest>({
            query: ({ username }) => ({
                url: 'accounts/username/change/',
                method: 'PATCH',
                body: { new_username: username },
            }),

            invalidatesTags: ['UserInfo', 'EventsHistory'],
        }),

        updateEmail: builder.mutation<void, UpdateEmailRequest>({
            query: ({ email }) => ({
                url: 'accounts/email/change/',
                method: 'PATCH',
                body: { new_email: email },
            }),

            invalidatesTags: ['UserInfo', 'EventsHistory'],
        }),

        updatePhoneNumber: builder.mutation<void, UpdatePhoneNumberRequest>({
            query: ({ phoneNumber }) => ({
                url: 'accounts/phone-number/change/',
                method: 'PATCH',
                body: { new_phone_number: phoneNumber },
            }),

            invalidatesTags: ['UserInfo', 'EventsHistory'],
        }),

        updateAddress: builder.mutation<void, UpdateAddressRequest>({
            query: (payload) => ({
                url: 'accounts/address/change/',
                method: 'PATCH',
                body: keysToSnake(payload),
            }),

            invalidatesTags: ['UserInfo', 'EventsHistory'],
        }),

        verifyPassword: builder.mutation<VerifyPasswordResponse, VerifyPasswordRequest>({
            query: (payload) => ({
                url: 'accounts/password/verify/',
                method: 'POST',
                body: keysToSnake(payload),
            }),

            transformResponse: (response: VerifyPasswordResponse) => keysToCamel(response),
        }),

        getEventsHistory: builder.query<GetEventsHistoryResponse, any>({
            query: (query) => ({
                url: 'action-logs/history/',
                params: query,
            }),

            keepUnusedDataFor: 0,

            providesTags: ['EventsHistory'],

            transformResponse: (response: GetEventsHistoryResponse) => keysToCamel(response),
        }),

        changeCommunicationMethod: builder.mutation<void, ChangeCommunicationMethodRequest>({
            query: (payload) => ({
                url: 'accounts/communication-method/change/',
                method: 'POST',
                body: keysToSnake(payload),
            }),

            async onQueryStarted(patch, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    accountsApi.util.updateQueryData('getUserDetails', undefined, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                    dispatch(accountsApi.util.invalidateTags(['UserInfo']));
                }
            },

            invalidatesTags: ['UserInfo'],
        }),

        verifyUserPassword: builder.mutation<
            VerifyUserPasswordResponseTransformed,
            VerifyUserPasswordRequest
        >({
            query: (payload) => ({
                url: 'accounts/password/verify/',
                method: 'POST',
                body: keysToSnake(payload),
            }),

            transformResponse: (response: VerifyUserPasswordResponse) => keysToCamel(response),
        }),

        getDeleteReasons: builder.query<IDeleteReason[], void>({
            query: () => 'accounts/delete-reasons/?delete_type=soft_delete',
            keepUnusedDataFor: 0,
        }),

        deleteUser: builder.mutation<void, DeleteUserRequest>({
            query: (payload) => ({
                url: 'accounts/delete/',
                method: 'POST',
                body: keysToSnake(payload),
            }),
        }),

        recoverAccount: builder.mutation<void, LogInRequest>({
            query: (payload) => ({
                url: 'accounts/recover/',
                method: 'POST',
                body: payload,
            }),
        }),
    }),
    overrideExisting: true,
});

export const {
    useCheckUserInfoMutation,
    useCheckNewUserPhoneMutation,
    useSendConfirmationCodeMutation,
    useUpdateFirstLastNameMutation,
    useVerifyConfirmationCodeMutation,
    useCreateUserMutation,
    useResetPasswordMutation,
    useLogInMutation,
    useVerifyUserByEmailMutation,
    useVerifyUserByPhoneNumberMutation,
    useGetUserDetailsQuery,
    useUpdateUsernameMutation,
    useUpdateEmailMutation,
    useUpdatePhoneNumberMutation,
    useUpdateAddressMutation,
    useGetEventsHistoryQuery,
    useVerifyPasswordMutation,
    usePingMutation,
    useChangeCommunicationMethodMutation,
    useVerifyUserPasswordMutation,
    useCheckUsernameByPhoneNumberMutation,
    useGetDeleteReasonsQuery,
    useDeleteUserMutation,
    useRecoverAccountMutation,
} = accountsApi;
