import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { Box } from '@material-ui/core';
import inputCommonStyles from './style';
import classNames from 'classnames';
import { returnOnlyEngLang } from '../../../../utils/format';

interface IProps {
    hasError?: boolean;
    startAdornment?: any;
}

const Input: React.FC<IProps & TextFieldProps> = ({
    hasError = false,
    startAdornment,
    onChange,
    ...props
}) => {
    const { inputWrapper, startAdornmentStyle } = inputCommonStyles({
        hasError,
        isDisabled: props.disabled,
        hasStartAdornment: Boolean(startAdornment),
        multiline: props.multiline,
    });

    return (
        <Box className={inputWrapper}>
            <TextField
                InputProps={
                    {
                        disableUnderline: true,
                        startAdornment,
                        classes: {
                            startAdornment: startAdornmentStyle,
                        },
                        ...props.InputProps,
                    } as Partial<OutlinedInputProps>
                }
                variant="filled"
                fullWidth
                {...props}
                className={classNames(props.className, { 'with-error': hasError })}
                onChange={(e) => returnOnlyEngLang(e, onChange)}
            />
        </Box>
    );
};

export default Input;
