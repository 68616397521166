import React, { useEffect, useState } from 'react';
import { Box, createStyles, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ReactComponent as File } from 'assets/icons/default/file.svg';
import { ReactComponent as Video } from 'assets/icons/default/video.svg';
import { ReactComponent as Mic } from 'assets/icons/default/mic.svg';
import { ReactComponent as Camera } from 'assets/icons/default/camera.svg';
import { DialogBox, SlideUpMenu } from 'modules/common/components';
import { extend } from 'dayjs';
import dayjsPluginDuration from 'dayjs/plugin/duration';
import { formatMediaDuration } from 'modules/common/helpers/formatMediaDuratiom';
import { FileView } from 'modules/common/types';
import { useForm } from 'react-hook-form';
import FileNameInput from '../FormInputs/FileNameInput';
import { humanFileSize } from 'utils/strings';
import { firstThreeItemForDetails } from '../../constants/firstThreeItemForDetails';
import { useGlobalStyles } from '../../../../theme/globalStyles';
import { formatType } from '../../helpers/formatType';

interface IProps {
    isOpen: boolean;
    file: FileView;
    onClose: () => void;
    onRename?: (newName: string) => void;
    onDelete?: () => void;
    onRetake?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '8px 20px',
            background: 'transparent',
            color: theme.palette.neutral.black,
            borderRadius: 0,
        },
    })
);

extend(dayjsPluginDuration);

const SlideUpInfoMenu: React.FC<IProps> = ({
    isOpen,
    file,
    onRename,
    onDelete,
    onRetake,
    onClose,
}) => {
    const { inDarkGrey } = useGlobalStyles();
    const { item } = useStyles();
    const theme = useTheme();

    const {
        control,
        reset,
        getValues,
        formState: { errors, isDirty },
    } = useForm<{ fileName: string }>({
        mode: 'onChange',
        defaultValues: {
            fileName: '',
        },
    });

    const [isRenameOpen, setRenameOpen] = useState(false);
    const [isRetakeOpen, setRetakeOpen] = useState(false);
    const [isDetailsOpen, setDetailsOpen] = useState(false);
    const [isDeleteOpen, setDeleteOpen] = useState(false);

    useEffect(() => reset({ fileName: file.name }), [file.name]);

    const toggleRetakeOpen = () => {
        onClose();
        setRetakeOpen(!isRetakeOpen);
    };
    const toggleRenameOpen = (resetThis?: boolean) => {
        onClose();
        setRenameOpen(!isRenameOpen);
        if (resetThis) {
            reset({ fileName: file.name });
        }
    };
    const toggleDetailsOpen = () => {
        onClose();
        setDetailsOpen(!isDetailsOpen);
    };
    const toggleDeleteOpen = () => {
        onClose();
        setDeleteOpen(!isDeleteOpen);
    };

    const handleConfirmRename = () => {
        const { fileName } = getValues();
        toggleRenameOpen();

        if (onRename) {
            onRename(fileName);
        }
    };

    const handleRetake = () => {
        toggleRetakeOpen();
        if (onRetake) {
            onRetake();
        }
    };

    const handleDelete = () => {
        toggleDeleteOpen();
        if (onDelete) {
            onDelete();
        }
    };

    const getMenuItems = () => {
        if (onRetake) {
            return [
                { actionButton: 'Rename', onClick: toggleRenameOpen },
                { actionButton: 'Retake', onClick: toggleRetakeOpen },
                { actionButton: 'Details', onClick: toggleDetailsOpen },
                { actionButton: 'Delete', onClick: toggleDeleteOpen },
            ];
        }

        return [
            { actionButton: 'Rename', onClick: toggleRenameOpen },
            { actionButton: 'Details', onClick: toggleDetailsOpen },
            { actionButton: 'Delete', onClick: toggleDeleteOpen },
        ];
    };

    const getDetails = (type: string, duration?: number | null) => {
        const details = firstThreeItemForDetails(
            file.uploadedAt,
            file.address,
            file.latitude,
            file.longitude
        );

        const durationString = duration ? formatMediaDuration(duration) : '';

        if (type.startsWith('image')) {
            details.push({ icon: <Camera />, text: 'Photograph File' });
        }
        if (type.startsWith('audio')) {
            details.push({ icon: <Mic />, text: `Audio File${durationString}` });
        }
        if (type.startsWith('video')) {
            details.push({
                icon: <Video />,
                text: `Video File${durationString}`,
            });
        }

        details.push({
            icon: <File />,
            text: file.fileSize ? humanFileSize(file.fileSize) : '-',
        });
        return details;
    };

    return (
        <>
            <DialogBox
                isOpen={isRenameOpen}
                title={`Rename ${formatType(file.mediaType)}`}
                onSubmit={handleConfirmRename}
                submitText="Save"
                cancelText="Cancel"
                onCancel={toggleRenameOpen}
                isSubmitDisabled={(!!errors && !!errors.fileName) || !isDirty}
            >
                <Box mt={2}>
                    <FileNameInput control={control} />
                </Box>
            </DialogBox>

            <DialogBox
                isOpen={isRetakeOpen}
                title={`Retake ${formatType(file.mediaType)}`}
                onSubmit={handleRetake}
                submitText="Retake"
                onCancel={toggleRetakeOpen}
            >
                <Box>
                    <Typography variant="body1" align="center">
                        The previously captured file will be deleted.
                    </Typography>
                </Box>
            </DialogBox>

            <SlideUpMenu
                isOpen={isDetailsOpen}
                topTitle="File Details"
                bottomTitle={file?.name}
                onClose={toggleDetailsOpen}
            >
                <>
                    {getDetails(file.mediaType, file.duration).map(({ icon, text }, index) => (
                        <Box key={index} className={item}>
                            <Box mr={1.9}>{icon}</Box>
                            <Typography variant="body1">{text}</Typography>
                        </Box>
                    ))}
                </>
            </SlideUpMenu>

            <DialogBox
                isOpen={isDeleteOpen}
                title={`Delete ${formatType(file.mediaType)}`}
                onSubmit={handleDelete}
                submitText="Delete"
                color={theme.palette.error.main}
                onCancel={toggleDeleteOpen}
            >
                <>
                    <Typography
                        variant="body1"
                        align="center"
                        style={{ fontSize: 17, fontFamily: 'Poppins' }}
                    >
                        &rsquo;{file.name}&rsquo;
                    </Typography>

                    <Box mt="2px">
                        <Typography variant="body1" align="center" className={inDarkGrey}>
                            Be Aware:
                        </Typography>
                        <Typography variant="body1" align="center" className={inDarkGrey}>
                            {`When you Delete an ${formatType(
                                file.mediaType
                            )} it will be permanently deleted and removed from your account's saved files list.`}
                        </Typography>
                    </Box>
                </>
            </DialogBox>

            <SlideUpMenu
                isOpen={isOpen}
                bottomTitle={file?.name}
                topTitle={formatType(file.mediaType)}
                data={getMenuItems()}
                onClose={onClose}
            />
        </>
    );
};

export default SlideUpInfoMenu;
