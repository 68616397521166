import React, { FunctionComponent, SVGProps } from 'react';
import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';

type IEmptyBlock = BoxProps & {
    icon: FunctionComponent<SVGProps<SVGSVGElement>> | string;
    children: JSX.Element;
};

const EmptyBlock = ({ icon: Icon, children, ...rest }: IEmptyBlock): JSX.Element => (
    <Box display="flex" flexDirection="column" alignItems="center" {...rest}>
        <Box mb={1}>{typeof Icon === 'string' ? <img src={Icon} width={72} /> : <Icon />}</Box>
        {children}
    </Box>
);

export default EmptyBlock;
