import { MutableRefObject, useEffect, useState } from 'react';

export const useViewerParam = (
    ref: MutableRefObject<HTMLImageElement | HTMLElement | null> | null,
    calculateValue: () => number
) => {
    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        const set = () => setValue(calculateValue());

        set();

        const everythingLoaded = setInterval(() => {
            if ((ref?.current as HTMLImageElement)?.complete) {
                clearInterval(everythingLoaded);
                set();
            }
        }, 10);

        window.addEventListener('resize', set);

        return () => {
            window.removeEventListener('resize', set);
        };
    }, [ref?.current]);

    return value;
};
