export const PHONE_CODES = [
    {
        name: 'Afghanistan',
        code: '+93',
        id: '0',
    },
    {
        name: 'Albania',
        code: '+355',
        id: '1',
    },
    {
        name: 'Algeria',
        code: '+213',
        id: '2',
    },
    {
        name: 'American Samoa',
        code: '+1684',
        id: '3',
    },
    {
        name: 'Andorra',
        code: '+376',
        id: '4',
    },
    {
        name: 'Angola',
        code: '+244',
        id: '5',
    },
    {
        name: 'Anguilla',
        code: '+1264',
        id: '6',
    },
    {
        name: 'Antarctica',
        code: '+672',
        id: '7',
    },
    {
        name: 'Antigua and Barbuda',
        code: '+1268',
        id: '8',
    },
    {
        name: 'Argentina',
        code: '+54',
        id: '9',
    },
    {
        name: 'Armenia',
        code: '+374',
        id: '10',
    },
    {
        name: 'Aruba',
        code: '+297',
        id: '11',
    },
    {
        name: 'Australia',
        code: '+61',
        id: '12',
    },
    {
        name: 'Austria',
        code: '+43',
        id: '13',
    },
    {
        name: 'Azerbaijan',
        code: '+994',
        id: '14',
    },
    {
        name: 'Bahamas',
        code: '+1242',
        id: '15',
    },
    {
        name: 'Bahrain',
        code: '+973',
        id: '16',
    },
    {
        name: 'Bangladesh',
        code: '+880',
        id: '17',
    },
    {
        name: 'Barbados',
        code: '+1246',
        id: '18',
    },
    {
        name: 'Belarus',
        code: '+375',
        id: '19',
    },
    {
        name: 'Belgium',
        code: '+32',
        id: '20',
    },
    {
        name: 'Belize',
        code: '+501',
        id: '21',
    },
    {
        name: 'Benin',
        code: '+229',
        id: '22',
    },
    {
        name: 'Bermuda',
        code: '+1441',
        id: '23',
    },
    {
        name: 'Bhutan',
        code: '+975',
        id: '24',
    },
    {
        name: 'Bolivia',
        code: '+591',
        id: '25',
    },
    {
        name: 'Bosnia and Herzegovina',
        code: '+387',
        id: '26',
    },
    {
        name: 'Botswana',
        code: '+267',
        id: '27',
    },
    {
        name: 'Brazil',
        code: '+55',
        id: '28',
    },
    {
        name: 'British Indian Ocean Territory',
        code: '+246',
        id: '29',
    },
    {
        name: 'British Virgin Islands',
        code: '+1284',
        id: '30',
    },
    {
        name: 'Brunei',
        code: '+673',
        id: '31',
    },
    {
        name: 'Bulgaria',
        code: '+359',
        id: '32',
    },
    {
        name: 'Burkina Faso',
        code: '+226',
        id: '33',
    },
    {
        name: 'Burundi',
        code: '+257',
        id: '34',
    },
    {
        name: 'Cambodia',
        code: '+855',
        id: '35',
    },
    {
        name: 'Cameroon',
        code: '+237',
        id: '36',
    },
    {
        name: 'Canada',
        code: '+1',
        id: '37',
    },
    {
        name: 'Cape Verde',
        code: '+238',
        id: '38',
    },
    {
        name: 'Cayman Islands',
        code: '+1345',
        id: '39',
    },
    {
        name: 'Central African Republic',
        code: '+236',
        id: '40',
    },
    {
        name: 'Chad',
        code: '+235',
        id: '41',
    },
    {
        name: 'Chile',
        code: '+56',
        id: '42',
    },
    {
        name: 'China',
        code: '+86',
        id: '43',
    },
    {
        name: 'Christmas Island',
        code: '+61',
        id: '44',
    },
    {
        name: 'Cocos Islands',
        code: '+61',
        id: '45',
    },
    {
        name: 'Colombia',
        code: '+57',
        id: '46',
    },
    {
        name: 'Comoros',
        code: '+269',
        id: '47',
    },
    {
        name: 'Cook Islands',
        code: '+682',
        id: '48',
    },
    {
        name: 'Costa Rica',
        code: '+506',
        id: '49',
    },
    {
        name: 'Croatia',
        code: '+385',
        id: '50',
    },
    {
        name: 'Cuba',
        code: '+53',
        id: '51',
    },
    {
        name: 'Curacao',
        code: '+599',
        id: '52',
    },
    {
        name: 'Cyprus',
        code: '+357',
        id: '53',
    },
    {
        name: 'Czech Republic',
        code: '+420',
        id: '54',
    },
    {
        name: 'Democratic Republic of the Congo',
        code: '+243',
        id: '55',
    },
    {
        name: 'Denmark',
        code: '+45',
        id: '56',
    },
    {
        name: 'Djibouti',
        code: '+253',
        id: '57',
    },
    {
        name: 'Dominica',
        code: '+1767',
        id: '58',
    },
    {
        name: 'Dominican Republic',
        code: '+1809',
        id: '59',
    },
    {
        name: 'Dominican Republic',
        code: '1829',
        id: '60',
    },
    {
        name: 'Dominican Republic',
        code: '1849',
        id: '61',
    },
    {
        name: 'East Timor',
        code: '+670',
        id: '62',
    },
    {
        name: 'Ecuador',
        code: '+593',
        id: '63',
    },
    {
        name: 'Egypt',
        code: '+20',
        id: '64',
    },
    {
        name: 'El Salvador',
        code: '+503',
        id: '65',
    },
    {
        name: 'Equatorial Guinea',
        code: '+240',
        id: '66',
    },
    {
        name: 'Eritrea',
        code: '+291',
        id: '67',
    },
    {
        name: 'Estonia',
        code: '+372',
        id: '68',
    },
    {
        name: 'Ethiopia',
        code: '+251',
        id: '69',
    },
    {
        name: 'Falkland Islands',
        code: '+500',
        id: '70',
    },
    {
        name: 'Faroe Islands',
        code: '+298',
        id: '71',
    },
    {
        name: 'Fiji',
        code: '+679',
        id: '72',
    },
    {
        name: 'Finland',
        code: '+358',
        id: '73',
    },
    {
        name: 'France',
        code: '+33',
        id: '74',
    },
    {
        name: 'French Polynesia',
        code: '+689',
        id: '75',
    },
    {
        name: 'Gabon',
        code: '+241',
        id: '76',
    },
    {
        name: 'Gambia',
        code: '+220',
        id: '77',
    },
    {
        name: 'Georgia',
        code: '+995',
        id: '78',
    },
    {
        name: 'Germany',
        code: '+49',
        id: '79',
    },
    {
        name: 'Ghana',
        code: '+233',
        id: '80',
    },
    {
        name: 'Gibraltar',
        code: '+350',
        id: '81',
    },
    {
        name: 'Greece',
        code: '+30',
        id: '82',
    },
    {
        name: 'Greenland',
        code: '+299',
        id: '83',
    },
    {
        name: 'Grenada',
        code: '+1473',
        id: '84',
    },
    {
        name: 'Guam',
        code: '+1671',
        id: '85',
    },
    {
        name: 'Guatemala',
        code: '+502',
        id: '86',
    },
    {
        name: 'Guernsey',
        code: '+441481',
        id: '87',
    },
    {
        name: 'Guinea',
        code: '+224',
        id: '88',
    },
    {
        name: 'Guinea-Bissau',
        code: '+245',
        id: '89',
    },
    {
        name: 'Guyana',
        code: '+592',
        id: '90',
    },
    {
        name: 'Haiti',
        code: '+509',
        id: '91',
    },
    {
        name: 'Honduras',
        code: '+504',
        id: '92',
    },
    {
        name: 'Hong Kong',
        code: '+852',
        id: '93',
    },
    {
        name: 'Hungary',
        code: '+36',
        id: '94',
    },
    {
        name: 'Iceland',
        code: '+354',
        id: '95',
    },
    {
        name: 'India',
        code: '+91',
        id: '96',
    },
    {
        name: 'Indonesia',
        code: '+62',
        id: '97',
    },
    {
        name: 'Iran',
        code: '+98',
        id: '98',
    },
    {
        name: 'Iraq',
        code: '+964',
        id: '99',
    },
    {
        name: 'Ireland',
        code: '+353',
        id: '100',
    },
    {
        name: 'Isle of Man',
        code: '+441624',
        id: '101',
    },
    {
        name: 'Israel',
        code: '+972',
        id: '102',
    },
    {
        name: 'Italy',
        code: '+39',
        id: '103',
    },
    {
        name: 'Ivory Coast',
        code: '+225',
        id: '104',
    },
    {
        name: 'Jamaica',
        code: '+1876',
        id: '105',
    },
    {
        name: 'Japan',
        code: '+81',
        id: '106',
    },
    {
        name: 'Jersey',
        code: '+441534',
        id: '107',
    },
    {
        name: 'Jordan',
        code: '+962',
        id: '108',
    },
    {
        name: 'Kazakhstan',
        code: '+7',
        id: '109',
    },
    {
        name: 'Kenya',
        code: '+254',
        id: '110',
    },
    {
        name: 'Kiribati',
        code: '+686',
        id: '111',
    },
    {
        name: 'Kosovo',
        code: '+383',
        id: '112',
    },
    {
        name: 'Kuwait',
        code: '+965',
        id: '113',
    },
    {
        name: 'Kyrgyzstan',
        code: '+996',
        id: '114',
    },
    {
        name: 'Laos',
        code: '+856',
        id: '115',
    },
    {
        name: 'Latvia',
        code: '+371',
        id: '116',
    },
    {
        name: 'Lebanon',
        code: '+961',
        id: '117',
    },
    {
        name: 'Lesotho',
        code: '+266',
        id: '118',
    },
    {
        name: 'Liberia',
        code: '+231',
        id: '119',
    },
    {
        name: 'Libya',
        code: '+218',
        id: '120',
    },
    {
        name: 'Liechtenstein',
        code: '+423',
        id: '121',
    },
    {
        name: 'Lithuania',
        code: '+370',
        id: '122',
    },
    {
        name: 'Luxembourg',
        code: '+352',
        id: '123',
    },
    {
        name: 'Macau',
        code: '+853',
        id: '124',
    },
    {
        name: 'Macedonia',
        code: '+389',
        id: '125',
    },
    {
        name: 'Madagascar',
        code: '+261',
        id: '126',
    },
    {
        name: 'Malawi',
        code: '+265',
        id: '127',
    },
    {
        name: 'Malaysia',
        code: '+60',
        id: '128',
    },
    {
        name: 'Maldives',
        code: '+960',
        id: '129',
    },
    {
        name: 'Mali',
        code: '+223',
        id: '130',
    },
    {
        name: 'Malta',
        code: '+356',
        id: '131',
    },
    {
        name: 'Marshall Islands',
        code: '+692',
        id: '132',
    },
    {
        name: 'Mauritania',
        code: '+222',
        id: '133',
    },
    {
        name: 'Mauritius',
        code: '+230',
        id: '134',
    },
    {
        name: 'Mayotte',
        code: '+262',
        id: '135',
    },
    {
        name: 'Mexico',
        code: '+52',
        id: '136',
    },
    {
        name: 'Micronesia',
        code: '+691',
        id: '137',
    },
    {
        name: 'Moldova',
        code: '+373',
        id: '138',
    },
    {
        name: 'Monaco',
        code: '+377',
        id: '139',
    },
    {
        name: 'Mongolia',
        code: '+976',
        id: '140',
    },
    {
        name: 'Montenegro',
        code: '+382',
        id: '141',
    },
    {
        name: 'Montserrat',
        code: '+1664',
        id: '142',
    },
    {
        name: 'Morocco',
        code: '+212',
        id: '143',
    },
    {
        name: 'Mozambique',
        code: '+258',
        id: '144',
    },
    {
        name: 'Myanmar',
        code: '+95',
        id: '145',
    },
    {
        name: 'Namibia',
        code: '+264',
        id: '146',
    },
    {
        name: 'Nauru',
        code: '+674',
        id: '147',
    },
    {
        name: 'Nepal',
        code: '+977',
        id: '148',
    },
    {
        name: 'Netherlands',
        code: '+31',
        id: '149',
    },
    {
        name: 'Netherlands Antilles',
        code: '+599',
        id: '150',
    },
    {
        name: 'New Caledonia',
        code: '+687',
        id: '151',
    },
    {
        name: 'New Zealand',
        code: '+64',
        id: '152',
    },
    {
        name: 'Nicaragua',
        code: '+505',
        id: '153',
    },
    {
        name: 'Niger',
        code: '+227',
        id: '154',
    },
    {
        name: 'Nigeria',
        code: '+234',
        id: '155',
    },
    {
        name: 'Niue',
        code: '+683',
        id: '156',
    },
    {
        name: 'North Korea',
        code: '+850',
        id: '157',
    },
    {
        name: 'Northern Mariana Islands',
        code: '+1670',
        id: '158',
    },
    {
        name: 'Norway',
        code: '+47',
        id: '159',
    },
    {
        name: 'Oman',
        code: '+968',
        id: '160',
    },
    {
        name: 'Pakistan',
        code: '+92',
        id: '161',
    },
    {
        name: 'Palau',
        code: '+680',
        id: '162',
    },
    {
        name: 'Palestine',
        code: '+970',
        id: '163',
    },
    {
        name: 'Panama',
        code: '+507',
        id: '164',
    },
    {
        name: 'Papua New Guinea',
        code: '+675',
        id: '165',
    },
    {
        name: 'Paraguay',
        code: '+595',
        id: '166',
    },
    {
        name: 'Peru',
        code: '+51',
        id: '167',
    },
    {
        name: 'Philippines',
        code: '+63',
        id: '168',
    },
    {
        name: 'Pitcairn',
        code: '+64',
        id: '169',
    },
    {
        name: 'Poland',
        code: '+48',
        id: '170',
    },
    {
        name: 'Portugal',
        code: '+351',
        id: '171',
    },
    {
        name: 'Puerto Rico',
        code: '+1787',
        id: '172',
    },
    {
        name: 'Puerto Rico',
        code: '1939',
        id: '173',
    },
    {
        name: 'Qatar',
        code: '+974',
        id: '174',
    },
    {
        name: 'Republic of the Congo',
        code: '+242',
        id: '175',
    },
    {
        name: 'Reunion',
        code: '+262',
        id: '176',
    },
    {
        name: 'Romania',
        code: '+40',
        id: '177',
    },
    {
        name: 'Russia',
        code: '+7',
        id: '178',
    },
    {
        name: 'Rwanda',
        code: '+250',
        id: '179',
    },
    {
        name: 'Saint Barthelemy',
        code: '+590',
        id: '180',
    },
    {
        name: 'Saint Helena',
        code: '+290',
        id: '181',
    },
    {
        name: 'Saint Kitts and Nevis',
        code: '+1869',
        id: '182',
    },
    {
        name: 'Saint Lucia',
        code: '+1758',
        id: '183',
    },
    {
        name: 'Saint Martin',
        code: '+590',
        id: '184',
    },
    {
        name: 'Saint Pierre and Miquelon',
        code: '+508',
        id: '185',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        code: '+1784',
        id: '186',
    },
    {
        name: 'Samoa',
        code: '+685',
        id: '187',
    },
    {
        name: 'San Marino',
        code: '+378',
        id: '188',
    },
    {
        name: 'Sao Tome and Principe',
        code: '+239',
        id: '189',
    },
    {
        name: 'Saudi Arabia',
        code: '+966',
        id: '190',
    },
    {
        name: 'Senegal',
        code: '+221',
        id: '191',
    },
    {
        name: 'Serbia',
        code: '+381',
        id: '192',
    },
    {
        name: 'Seychelles',
        code: '+248',
        id: '193',
    },
    {
        name: 'Sierra Leone',
        code: '+232',
        id: '194',
    },
    {
        name: 'Singapore',
        code: '+65',
        id: '195',
    },
    {
        name: 'Sint Maarten',
        code: '+1721',
        id: '196',
    },
    {
        name: 'Slovakia',
        code: '+421',
        id: '197',
    },
    {
        name: 'Slovenia',
        code: '+386',
        id: '198',
    },
    {
        name: 'Solomon Islands',
        code: '+677',
        id: '199',
    },
    {
        name: 'Somalia',
        code: '+252',
        id: '200',
    },
    {
        name: 'South Africa',
        code: '+27',
        id: '201',
    },
    {
        name: 'South Korea',
        code: '+82',
        id: '202',
    },
    {
        name: 'South Sudan',
        code: '+211',
        id: '203',
    },
    {
        name: 'Spain',
        code: '+34',
        id: '204',
    },
    {
        name: 'Sri Lanka',
        code: '+94',
        id: '205',
    },
    {
        name: 'Sudan',
        code: '+249',
        id: '206',
    },
    {
        name: 'Suriname',
        code: '+597',
        id: '207',
    },
    {
        name: 'Svalbard and Jan Mayen',
        code: '+47',
        id: '208',
    },
    {
        name: 'Swaziland',
        code: '+268',
        id: '209',
    },
    {
        name: 'Sweden',
        code: '+46',
        id: '210',
    },
    {
        name: 'Switzerland',
        code: '+41',
        id: '211',
    },
    {
        name: 'Syria',
        code: '+963',
        id: '212',
    },
    {
        name: 'Taiwan',
        code: '+886',
        id: '213',
    },
    {
        name: 'Tajikistan',
        code: '+992',
        id: '214',
    },
    {
        name: 'Tanzania',
        code: '+255',
        id: '215',
    },
    {
        name: 'Thailand',
        code: '+66',
        id: '216',
    },
    {
        name: 'Togo',
        code: '+228',
        id: '217',
    },
    {
        name: 'Tokelau',
        code: '+690',
        id: '218',
    },
    {
        name: 'Tonga',
        code: '+676',
        id: '219',
    },
    {
        name: 'Trinidad and Tobago',
        code: '+1868',
        id: '220',
    },
    {
        name: 'Tunisia',
        code: '+216',
        id: '221',
    },
    {
        name: 'Turkey',
        code: '+90',
        id: '222',
    },
    {
        name: 'Turkmenistan',
        code: '+993',
        id: '223',
    },
    {
        name: 'Turks and Caicos Islands',
        code: '+1649',
        id: '224',
    },
    {
        name: 'Tuvalu',
        code: '+688',
        id: '225',
    },
    {
        name: 'U.S. Virgin Islands',
        code: '+1340',
        id: '226',
    },
    {
        name: 'Uganda',
        code: '+256',
        id: '227',
    },
    {
        name: 'Ukraine',
        code: '+380',
        id: '228',
    },
    {
        name: 'United Arab Emirates',
        code: '+971',
        id: '229',
    },
    {
        name: 'United Kingdom',
        code: '+44',
        id: '230',
    },
    {
        name: 'United States',
        code: '+1',
        id: '231',
    },
    {
        name: 'Uruguay',
        code: '+598',
        id: '232',
    },
    {
        name: 'Uzbekistan',
        code: '+998',
        id: '233',
    },
    {
        name: 'Vanuatu',
        code: '+678',
        id: '234',
    },
    {
        name: 'Vatican',
        code: '+379',
        id: '235',
    },
    {
        name: 'Venezuela',
        code: '+58',
        id: '236',
    },
    {
        name: 'Vietnam',
        code: '+84',
        id: '237',
    },
    {
        name: 'Wallis and Futuna',
        code: '+681',
        id: '238',
    },
    {
        name: 'Western Sahara',
        code: '+212',
        id: '239',
    },
    {
        name: 'Yemen',
        code: '+967',
        id: '240',
    },
    {
        name: 'Zambia',
        code: '+260',
        id: '241',
    },
    {
        name: 'Zimbabwe',
        code: '+263',
        id: '242',
    },
];
