import { useCallback, useState } from 'react';

const useCommutator = (def = false): [boolean, () => void, () => void] => {
    const [isOpen, setOpen] = useState(def);

    const open = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const close = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return [isOpen, open, close];
};

export default useCommutator;
