export function msToTime(s1) {
    let s = s1;
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;

    let res = '';

    if (hrs) res += `${hrs}h`;
    if (mins) res += ` ${mins}m`;
    if (secs) res += ` ${secs}s`;

    return res;
}
