import { keysToCamel, keysToSnake } from 'modules/common/helpers/transformKeysStyle';
import { baseApi } from 'store/services/base';

import {
    CreatePaymentIntentRequest,
    DeletePaymentMethodsRequest,
    GetPaymentMethodsResponse,
    GetPaymentPriceRequest,
    GetPaymentPriceResponse,
    GetProofkeepWalletResponse,
    GetStoredAccountResponse,
    StripeCreateSetupIntentRequest,
    StripeCreateSetupIntentResponse,
    StripeCustomerCreateResponse,
} from './types';
import { insufficientFundsOpen } from '../../slices/ui';

export const paymentsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createStripeCustomer: builder.mutation<StripeCustomerCreateResponse, void>({
            query: () => {
                return {
                    url: 'payments/stripe-customer/create/',
                    method: 'POST',
                };
            },
        }),

        createStripeSetupIntent: builder.mutation<
            StripeCreateSetupIntentResponse,
            StripeCreateSetupIntentRequest
        >({
            query: ({ stripeCustomerId }) => {
                return {
                    url: 'payments/stripe-setup-intent/create/',
                    method: 'POST',
                    body: { stripe_customer_id: stripeCustomerId },
                };
            },
        }),

        updateStoredAccount: builder.mutation<any, any>({
            query: ({ autoToppingUpAmount, paymentMethodId }) => {
                return {
                    url: 'payments/stored-account/update/',
                    method: 'POST',
                    body: {
                        auto_topping_up_amount: autoToppingUpAmount,
                        payment_method_id: paymentMethodId,
                    },
                };
            },

            invalidatesTags: ['StoredAccount', 'UserInfo'],
        }),

        getStoredAccount: builder.query<GetStoredAccountResponse, void>({
            query() {
                return {
                    url: `/payments/stored-account/get/`,
                };
            },
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                const res = await queryFulfilled;

                if (res?.data?.topUpError?.length) {
                    const { topUpError, last4 } = res.data;

                    dispatch(insufficientFundsOpen({ error: topUpError, last4 }));
                }
            },
            keepUnusedDataFor: 0,
            transformResponse: (response) => keysToCamel(response),

            providesTags: ['StoredAccount'],
        }),

        getProofkeepWallet: builder.query<GetProofkeepWalletResponse, void>({
            query() {
                return {
                    url: `/payments/proofkeep-wallet/get/`,
                };
            },
            transformResponse: (response) => keysToCamel(response),

            providesTags: ['StoredAccount'],
        }),

        deleteStoredAccount: builder.mutation<any, void>({
            query() {
                return {
                    url: 'payments/stored-account/delete/',
                    method: 'DELETE',
                };
            },

            invalidatesTags: ['StoredAccount', 'UserInfo'],
        }),

        payWithStoredAccount: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: 'payments/stored-account/pay/',
                    method: 'POST',
                    body: keysToSnake(data),
                };
            },

            invalidatesTags: ['StoredAccount', 'UserInfo'],
        }),

        createPaymentIntent: builder.mutation<any, CreatePaymentIntentRequest>({
            query: ({ paymentMethodId, metadata }) => {
                return {
                    url: 'payments/payment-intent/create/',
                    method: 'POST',
                    body: {
                        payment_method_id: paymentMethodId,
                        metadata: keysToSnake(metadata),
                    },
                };
            },

            invalidatesTags: ['Folder', 'Proof'],
        }),

        getPaymentMethods: builder.query<any, void>({
            query() {
                return {
                    url: `/payments/payment-methods/get/`,
                };
            },

            transformResponse: (response: GetPaymentMethodsResponse) => {
                return response.payment_methods.map((item) => keysToCamel(item));
            },

            providesTags: ['PaymentMethods'],
        }),

        getPaymentPrice: builder.query<GetPaymentPriceResponse, GetPaymentPriceRequest>({
            query: (payload) => ({
                url: `/payments/storage-price-prolong/calculate/`,
                method: 'POST',
                body: keysToSnake(payload),
            }),

            transformResponse: (response) => keysToCamel(response),

            providesTags: ['Proof'],
        }),

        deletePaymentMethod: builder.mutation<any, DeletePaymentMethodsRequest>({
            query({ paymentMethodId }) {
                return {
                    url: 'payments/payment-method/remove/',
                    method: 'POST',
                    body: {
                        payment_method_id: paymentMethodId,
                    },
                };
            },

            invalidatesTags: ['PaymentMethods'],
        }),

        getPaymentMethodFingerprint: builder.mutation<{ fingerprint: string }, string>({
            query: (id) => ({ url: `payments/payment-method/${id}/retrieve/`, method: 'GET' }),
        }),

        makeDefaultPaymentMethod: builder.mutation<any, DeletePaymentMethodsRequest>({
            query({ paymentMethodId }) {
                return {
                    url: 'payments/payment-method/set-default/',
                    method: 'POST',
                    body: {
                        payment_method_id: paymentMethodId,
                    },
                };
            },

            invalidatesTags: ['PaymentMethods'],
        }),
    }),
    overrideExisting: true,
});

export const {
    useCreateStripeCustomerMutation,
    useCreateStripeSetupIntentMutation,
    useCreatePaymentIntentMutation,
    useGetPaymentMethodsQuery,
    useGetPaymentPriceQuery,
    useDeletePaymentMethodMutation,
    useMakeDefaultPaymentMethodMutation,
    useUpdateStoredAccountMutation,
    useGetStoredAccountQuery,
    useGetProofkeepWalletQuery,
    useDeleteStoredAccountMutation,
    usePayWithStoredAccountMutation,
    useGetPaymentMethodFingerprintMutation,
} = paymentsApi;
