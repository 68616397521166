import React, { useState, useEffect, useContext, createContext } from 'react';

// const DOWNLINK_TRESHOLD = 0.2; // 200kbps

const OnlineStatusContext = createContext(true);

export const OnlineStatusProvider: React.FC = ({ children }) => {
    const [onlineStatus, setOnlineStatus] = useState<boolean>(navigator.onLine);
    // let downlinkInterval;

    useEffect(() => {
        window.addEventListener('offline', () => {
            setOnlineStatus(false);
        });
        window.addEventListener('online', () => {
            if (!onlineStatus) {
                setOnlineStatus(true);
                window.location.reload();
            }
        });

        // downlinkInterval = window.setInterval(() => {
        // const downlink =
        //     navigator.connection && navigator.connection['downlink']
        //         ? navigator.connection['downlink']
        //         : null;

        // Both conditions checks for `navigator.onLine`
        // Reason: downlink check should be performed only if we have internet connection
        // if (
        //     downlink !== null &&
        //     DOWNLINK_TRESHOLD > downlink &&
        //     onlineStatus &&
        //     navigator.onLine
        // ) {
        //     setOnlineStatus(false);
        // }
        // if (
        //     downlink !== null &&
        //     downlink > DOWNLINK_TRESHOLD &&
        //     !onlineStatus &&
        //     navigator.onLine
        // ) {
        //     setOnlineStatus(true);
        // }
        // if (onlineStatus) {
        //     setOnlineStatus(false);
        // }
        // if (!onlineStatus) {
        //     setOnlineStatus(true);
        // }
        // }, 1000);

        return () => {
            window.removeEventListener('offline', () => {
                setOnlineStatus(false);
            });
            window.removeEventListener('online', () => {
                setOnlineStatus(true);
            });
            // window.clearInterval(downlinkInterval);
        };
    }, [onlineStatus]);

    return (
        <OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
    );
};

export const useOnlineStatus = () => {
    const store = useContext(OnlineStatusContext);
    return store;
};
