export const truncateText = (s, maxLength) => {
    if (typeof s !== 'string') return '';
    return s.length >= maxLength ? `${s.substring(0, maxLength)}...` : s;
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const humanFileSize = (size: number): string => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${Number((size / Math.pow(1024, i)).toFixed(2)) * 1} ${
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }`;
};

export const pluralize = (count: number, noun: string, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;
