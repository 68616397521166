import React from 'react';
import {
    Box,
    makeStyles,
    IconButton,
    InputBase,
    Container,
    InputAdornment,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles } from '@material-ui/core/styles';
import { returnOnlyEngLang } from '../../../../../utils/format';
import { useGlobalStyles } from '../../../../../theme/globalStyles';
import { BoxProps } from '@material-ui/core/Box/Box';

interface IProps extends Omit<BoxProps, 'onChange'> {
    searchValue: string;
    placeholder: string;
    autoFocus?: boolean;
    onChange: (value: string | Record<string, unknown>) => void;
    onClose?: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        headerWrapper: {
            padding: 0,
            paddingRight: '16px',
        },
    })
);

const SearchBar: React.FC<IProps> = ({
    searchValue,
    placeholder,
    onChange,
    onClose,
    onFocus,
    onBlur,
    autoFocus = true,
    ...boxProps
}) => {
    const { headerWrapper } = useStyles();
    const { searchInput } = useGlobalStyles();

    return (
        <Container maxWidth="sm" className={headerWrapper}>
            <Box display="flex" alignItems="center" py={0.5} {...boxProps}>
                {onClose && (
                    <IconButton onClick={onClose}>
                        <ArrowBackIosIcon />
                    </IconButton>
                )}

                <Box className={searchInput}>
                    <InputBase
                        onChange={(e) => returnOnlyEngLang(e, onChange, true)}
                        value={searchValue}
                        fullWidth
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        inputProps={{ maxLength: 200 }}
                        endAdornment={
                            <InputAdornment position="start" onClick={() => onChange('')}>
                                <CloseIcon fontSize="small" />
                            </InputAdornment>
                        }
                    />
                </Box>
            </Box>
        </Container>
    );
};

export default SearchBar;
