/* eslint-disable */

import React, { ReactNode } from 'react';
import {
    Box,
    Container,
    createStyles,
    makeStyles,
    Typography,
    Modal,
    Button,
} from '@material-ui/core';
import { theme } from 'theme';
import { Variant } from '@material-ui/core/styles/createTypography';

export interface IData {
    text: string;
    onClick: () => void;
}

interface IProps {
    isOpen: boolean;
    title?: ReactNode;
    subTitle?: string;
    submitText: string;
    cancelText?: string;
    children?: JSX.Element;
    onSubmit: () => void;
    onCancel?: () => void;
    isSubmitDisabled?: boolean;
    color?: string;
    subVariant?: Variant;
}

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            padding: '0 48px',
            zIndex: 100,
            width: '100%',
            border: 0,
            outline: 'none',
        },
        box: {
            borderRadius: '12px',
            background: theme.palette.neutral.white,
        },
        buttons: {
            borderTop: ` 0.5px solid ${theme.palette.neutral.grey}`,
            display: 'flex',
            width: '100%',
            minHeight: '40px',

            '& div:not(:last-child)': {
                borderRight: ` 0.5px solid ${theme.palette.neutral.grey}`,
            },
        },
        button: {
            flexGrow: 1,
            flexBasis: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '& button': {
                padding: 0,
                height: '100%',
            },
        },
        buttonCancel: {
            width: '100%',
            color: theme.palette.neutral.main,
        },
        buttonSubmit: {
            width: '100%',
            '&:disabled': {
                color: theme.palette.neutral.grey,
            },
        },
        sub: {
            color: theme.palette.neutral.darkGrey,
            fontWeight: 400,
            marginTop: '2px',
        },
    })
);

const DialogBox: React.FC<IProps> = ({
    isOpen,
    title,
    onSubmit,
    onCancel,
    subTitle,
    submitText,
    cancelText = 'Back',
    children,
    isSubmitDisabled = false,
    color = theme.palette.primary.dark,
    subVariant = 'body1',
}) => {
    const { box, wrapper, buttons, button, sub, buttonSubmit, buttonCancel } = useStyles();

    return (
        <Modal open={isOpen}>
            <Container maxWidth="sm" className={wrapper}>
                <Box className={box}>
                    <Box p={2} textAlign="center">
                        <Typography variant="h3" component="h3" align="center">
                            {title}
                        </Typography>

                        {!!subTitle && (
                            <Typography variant={subVariant} className={sub} align="center">
                                {subTitle}
                            </Typography>
                        )}

                        <Box>{children}</Box>
                    </Box>

                    <Box className={buttons}>
                        {onCancel && (
                            <Box className={button}>
                                <Button onClick={onCancel} className={buttonCancel}>
                                    {cancelText}
                                </Button>
                            </Box>
                        )}

                        <Box className={button}>
                            <Button
                                className={buttonSubmit}
                                onClick={onSubmit}
                                disabled={!!isSubmitDisabled}
                                style={{ color: (!isSubmitDisabled && color) || undefined }}
                            >
                                {submitText}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Modal>
    );
};

export default DialogBox;
