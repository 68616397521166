import React, { FC, ReactNode, useMemo } from 'react';
import { CopyTypes } from 'store/services/sharing/types';
import { Box, Typography } from '@material-ui/core';
import { capitalize } from '../../../../utils/strings';
import { ReactComponent as AuthQR } from '../../../../assets/icons/qr/authenticated-qr-code.svg';
import { ReactComponent as UnauthQR } from '../../../../assets/icons/qr/unauth-qr-code.svg';

export interface IWatermarkProps {
    copyType: CopyTypes;
    generatedFor?: string;
    sentTo: string;
    generatedOn: string;
    transactionNumber: string;
}

interface IPosition {
    bottom?: string | number;
    width?: string | number;
}

const QRCodes = {
    [CopyTypes.Authenticated]: <AuthQR />,
    [CopyTypes.Unauthenticated]: <UnauthQR />,
};

const TextColors = {
    [CopyTypes.Authenticated]: '#FFBE00',
    [CopyTypes.Unauthenticated]: '#FFFFFF',
};

const WatermarkBlock: FC<IWatermarkProps & IPosition> = ({
    copyType,
    generatedFor,
    sentTo,
    generatedOn,
    transactionNumber,
    width,
    bottom,
}) => {
    const Title: FC<Omit<ITitleProps, 'width'>> = ({ children, ...props }) => (
        <TitleComponent width={width} {...props}>
            {children}
        </TitleComponent>
    );

    const TextItem: FC<Omit<ITextItemProps, 'width'>> = ({ children, ...props }) => (
        <TextItemComponent width={width} {...props}>
            {children}
        </TextItemComponent>
    );

    return (
        <Box width="100%" position="absolute" bottom={bottom} p={1} maxWidth={width}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                width="100%"
                borderRadius={8}
                bgcolor="rgba(0, 0, 0, 0.4)"
                minHeight={100}
            >
                <Box
                    pl="20px"
                    pt="4px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    {copyType === CopyTypes.Authenticated && !!generatedFor?.length && (
                        <>
                            <Title>Generated for</Title>
                            <TextItem>{generatedFor}</TextItem>
                        </>
                    )}

                    {!!sentTo?.length && (
                        <>
                            <Title>Sent To</Title>
                            <TextItem>{sentTo}</TextItem>
                        </>
                    )}

                    <Title>Generated On</Title>
                    <TextItem>{generatedOn}</TextItem>

                    <Title>Transaction Number</Title>
                    <TextItem>{transactionNumber}</TextItem>
                </Box>

                <Box bgcolor="rgba(0, 0, 0, 0.4)" p="4px" borderRadius="0 8px 8px 0">
                    {QRCodes[copyType]}

                    <Box textAlign="center">
                        <Title color={TextColors[copyType]}>
                            {capitalize(copyType)}
                            <br /> Copy
                        </Title>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

interface ITitleProps {
    children: ReactNode;
    color?: string;
    width?: number | string;
}

const TitleComponent: FC<ITitleProps> = ({ children, color = '#FFFFFF', width }) => {
    const fontSize = useMemo(() => {
        if (width && typeof width === 'number') {
            const scalableSize = Math.floor(width < 375 ? (7 * 375) / width : 7);

            return scalableSize < 11 ? scalableSize : 11;
        }

        return 7;
    }, [width]);

    return (
        <Typography
            variant="subtitle1"
            style={{
                fontSize: `${fontSize}px`,
                lineHeight: '8px',
                textTransform: 'uppercase',
                color,
                letterSpacing: '0.8px',
            }}
        >
            {children}
        </Typography>
    );
};

interface ITextItemProps {
    children: ReactNode;
    width?: number | string;
}

const TextItemComponent: FC<ITextItemProps> = ({ children, width }) => {
    const fontSize = useMemo(() => {
        if (width && typeof width === 'number') {
            const scalableSize = Math.floor(width < 375 ? (9 * 375) / width : 9);

            return scalableSize < 15 ? scalableSize : 15;
        }

        return 9;
    }, [width]);

    return (
        <Box mb="4px">
            <Typography
                variant="subtitle1"
                style={{
                    fontSize: `${fontSize}px`,
                    lineHeight: '12px',
                    color: '#FFFFFF',
                }}
            >
                {children}
            </Typography>
        </Box>
    );
};

export default WatermarkBlock;
