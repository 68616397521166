import React, { forwardRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

interface IProps {
    height?: string;
    children: JSX.Element;
}

interface StylesProps {
    height: string;
}

const useStyles = makeStyles<Theme, StylesProps>(
    createStyles({
        bottomBox: {
            position: 'fixed',
            bottom: 0,
            zIndex: 2,
            height: (props) => props.height,
            width: '100%',
            background: 'transparent',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '4px',
        },
    })
);

// eslint-disable-next-line react/display-name
const BottomBox = forwardRef<HTMLDivElement, IProps>(({ children, height = '140px' }, ref) => {
    const { bottomBox } = useStyles({ height });

    return (
        <div ref={ref} className={bottomBox}>
            {children}
        </div>
    );
});

export default BottomBox;
