import React from 'react';
import { ReactComponent as Calendar } from '../../../assets/icons/default/calendar.svg';
import dayjs from 'dayjs';
import { DAYS_FORMAT } from 'modules/common/constants/dateOptions';
import { ReactComponent as Clock } from '../../../assets/icons/default/clock.svg';
import { ReactComponent as MapPin } from '../../../assets/icons/default/map-pin.svg';
import { Box } from '@material-ui/core';

export const firstThreeItemForDetails = (uploadedAt, address, latitude, longitude) => {
    return [
        { icon: <Calendar />, text: dayjs(uploadedAt).format(DAYS_FORMAT) },
        { icon: <Clock />, text: dayjs(uploadedAt).format('HH:mm') },
        {
            icon: <MapPin />,
            text: (
                <>
                    {address}{' '}
                    <Box component="p" mt={0} mb={0}>
                        ({latitude}, {longitude})
                    </Box>
                </>
            ),
        },
    ];
};
