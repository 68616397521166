import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { GetShowPageAgainListResponseTransformed } from '../services/evidence/types';
import { Variant } from '@material-ui/core/styles/createTypography';

type UiState = {
    snackbar: {
        message: string;
        type: 'error' | 'success' | 'info' | 'warning';
        isShown: boolean;
        actionText?: string;
        onAction?: () => void;
        messageVariant?: Variant;
    };
    showPageAgainList: GetShowPageAgainListResponseTransformed;
    isLocationInfoModalOpen: boolean;
    offlineEvidences: number;
    uploadInProgress: boolean;
    insufficientFundsDialog: {
        isOpen: boolean;
        last4: string;
        error: string;
    };
};

const initialState: UiState = {
    snackbar: {
        message: '',
        type: 'info',
        isShown: false,
    },
    showPageAgainList: {
        carExterior: true,
        carFuel: true,
        carInterior: true,
        carMileage: true,
        hotelRental: true,
    },
    isLocationInfoModalOpen: false,
    offlineEvidences: 0,
    uploadInProgress: false,
    insufficientFundsDialog: {
        isOpen: false,
        last4: '',
        error: '',
    },
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        snackbarShown: (
            state,
            {
                payload: { message, type, onAction, actionText, messageVariant },
            }: PayloadAction<Omit<UiState['snackbar'], 'isShown'>>
        ) => {
            state.snackbar.message = message;
            state.snackbar.type = type;
            state.snackbar.onAction = onAction;
            state.snackbar.actionText = actionText;
            state.snackbar.messageVariant = messageVariant;
            state.snackbar.isShown = true;
        },

        snackbarHidden: (state) => {
            state.snackbar.isShown = false;
        },

        insufficientFundsOpen: (
            state,
            { payload }: PayloadAction<Omit<UiState['insufficientFundsDialog'], 'isOpen'>>
        ) => {
            state.insufficientFundsDialog = {
                isOpen: true,
                ...payload,
            };
        },

        insufficientFundsHidden: (state) => {
            state.insufficientFundsDialog.isOpen = false;
        },

        setShowPageAgainList: (
            state,
            action: PayloadAction<GetShowPageAgainListResponseTransformed>
        ) => {
            state.showPageAgainList = { ...action.payload };
        },

        updateStoreShowPageAgainList: (state, action: PayloadAction<string>) => {
            state.showPageAgainList[action.payload] = false;
        },
        openLocationInfoModal: (state) => {
            state.isLocationInfoModalOpen = true;
        },
        closeLocationInfoModal: (state) => {
            state.isLocationInfoModalOpen = false;
        },
        updateOfflineEvidences: (state, action: PayloadAction<number>) => {
            state.offlineEvidences = action.payload;
        },
        setUploadInProgress: (state, action: PayloadAction<boolean>) => {
            state.uploadInProgress = action.payload;
        },
    },
});

export const {
    snackbarShown,
    snackbarHidden,
    setShowPageAgainList,
    updateStoreShowPageAgainList,
    updateOfflineEvidences,
    setUploadInProgress,
    openLocationInfoModal,
    closeLocationInfoModal,

    insufficientFundsOpen,
    insufficientFundsHidden,
} = uiSlice.actions;

export const selectSnackbar = (state: RootState) => state.ui.snackbar;
export const selectShowPageAgainList = (state: RootState) => state.ui.showPageAgainList;
export const selectIsLocationInfoModalOpen = (state: RootState) => state.ui.isLocationInfoModalOpen;
export const selectInsufficientFundsDialog = (state: RootState) => state.ui.insufficientFundsDialog;

export const selectOfflineEvidences = (state: RootState) => state.ui.offlineEvidences;
export const selectUploadInProgress = (state: RootState) => state.ui.uploadInProgress;

export default uiSlice.reducer;
