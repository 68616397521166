export enum ScreensEnum {
    TypeOfRental,
    SelectVehicle,
}

export enum TypesOfVehicles {
    Car = 'Car',
    SUV = 'SUV',
    Watercraft = 'Watercraft',
    Trailer = 'Trailer',
    Truck = 'Truck',
}

export enum TypesOfRental {
    PickUp = 'Pick-up',
    Return = 'Return',
}

export enum CaptureVehicleConditionModal {
    UnsavedChanges,
    InfoMessage,
}

export enum CaptureVehicleConditionGuide {
    BodyGuide,
    InteriorGuide,
    MileageGuide,
    FuelGuide,
}

export enum VehicleCondition {
    Body = 'body',
    Interior = 'interior',
    Mileage = 'mileage',
    Fuel = 'fuel',
}

export const InteriorGuideSteps = [
    'Record interior video starting in the front and gradually moving to the back',
    'Capture close images of any blemishes, scratches, stains or damage',
];
export const MileageGuideSteps = [
    'Take a photo of the current odometer reading (miles / kilometers)',
    'Ensure you get a clear image that is in focus',
];
export const FuelGuideSteps = [
    'Take a photo of the current fuel level',
    'Ensure you get a clear image that is in focus',
];
