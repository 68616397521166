import React, { FunctionComponent, SVGProps } from 'react';
import { Box, ButtonBase, createStyles, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import classNames from 'classnames';

import { ReactComponent as ChevronRight } from 'assets/icons/default/chevron-right.svg';

interface IStyleProps {
    classicMode?: boolean;
}

export interface IMenuButtonProps extends IStyleProps {
    title: string;
    titleParentheses?: string;
    subtitle?: string;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>> | string;
    onClick?: () => void;
    disabled?: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
    createStyles({
        root: {
            display: 'block',
            width: '100%',
            padding: ({ classicMode }) => (classicMode ? '0 8px 0 16px' : '0 8px 0 12px'),
            borderRadius: 8,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            position: 'relative',
            minHeight: ({ classicMode }) => (classicMode ? '48px' : '54px'),
            background: theme.palette.neutral.white,

            '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: '0',
                margin: ({ classicMode }) => (classicMode ? '0' : '0px 36px'),
                width: ({ classicMode }) => (classicMode ? '100%' : 'calc(100% - 28px)'),
                borderBottom: `1px solid ${theme.palette.neutral.light}`,
            },

            '&:first-child:not(:last-child)': {
                borderRadius: ({ classicMode }) => (classicMode ? '0px' : '8px 8px 0px 0px'),
            },
            '&:last-child:not(:first-child)': {
                borderRadius: ({ classicMode }) => (classicMode ? '0px' : '0px 0px 8px 8px'),
            },
            '&:last-child:after': {
                display: 'none',
            },
            '&:disabled': {
                color: theme.palette.neutral.main,
            },
        },

        textContent: {
            minWidth: 0,
            padding: '8px 0px',
        },

        buttonTitle: {
            fontFamily: 'Source Sans Pro',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
        },

        buttonSubtitle: {
            color: theme.palette.neutral.darkGrey,
            whiteSpace: 'pre-line',
            '&::first-letter': {
                textTransform: 'capitalize',
            },
        },

        buttonSubtitleDisabled: {
            color: theme.palette.neutral.main,
        },

        chevronButton: {
            position: 'absolute',
            right: '8px',
        },

        dissabledIcon: {
            fill: theme.palette.neutral.extraLight,
            '& path': {
                stroke: theme.palette.neutral.main,
                fill: theme.palette.neutral.white,
            },
        },
        ml4: {
            marginLeft: '4px',
        },
    })
);

const MenuButton: React.FC<IMenuButtonProps> = ({
    icon: Icon,
    title,
    titleParentheses,
    subtitle,
    onClick,
    disabled,
    classicMode,
}) => {
    const {
        root,
        textContent,
        buttonTitle,
        buttonSubtitle,
        buttonSubtitleDisabled,
        chevronButton,
        dissabledIcon,
        ml4,
    } = useStyles({ classicMode });

    const theme = useTheme();

    return (
        <ButtonBase className={root} disabled={disabled} onClick={onClick}>
            <Box display="flex" alignItems="center" maxWidth="85%">
                {Icon && (
                    <Box pr={1.5}>
                        {typeof Icon === 'string' ? (
                            <img src={Icon} alt="menu_icon" />
                        ) : (
                            <Icon className={classNames({ [dissabledIcon]: disabled })} />
                        )}
                    </Box>
                )}

                <Box textAlign="left" className={textContent}>
                    <Box display="flex">
                        <Typography variant="subtitle1" className={buttonTitle}>
                            {title}
                        </Typography>
                        {titleParentheses && (
                            <Typography variant="body1" className={ml4}>
                                {` (${titleParentheses})`}
                            </Typography>
                        )}
                    </Box>
                    {!!subtitle && (
                        <Typography
                            variant="caption"
                            component="p"
                            className={classNames(buttonSubtitle, {
                                [buttonSubtitleDisabled]: disabled,
                            })}
                        >
                            {subtitle}
                        </Typography>
                    )}
                </Box>
                <Box className={chevronButton}>
                    <ChevronRight color={theme.palette.neutral.grey as string} />
                </Box>
            </Box>
        </ButtonBase>
    );
};

export default MenuButton;
