import React from 'react';
import { useController } from 'react-hook-form';

import Input from 'modules/common/components/Input';
import { fileNameRules } from 'modules/common/constants/formRules';

interface IProps {
    control: any;
    label?: string;
}

const FileNameInput: React.FC<IProps> = ({ control, label = 'File Name' }) => {
    const {
        field: { ref, ...inputProps },
        formState: { errors },
    } = useController({
        name: 'fileName',
        control,
        rules: fileNameRules,
    });

    return (
        <Input
            label={label}
            {...inputProps}
            inputRef={ref}
            hasError={errors.fileName}
            helperText={errors.fileName?.message}
            inputProps={{ maxLength: 250 }}
            autoFocus
        />
    );
};

export default FileNameInput;
