import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { ReactComponent as Dot } from 'assets/icons/color/16/dot.svg';

interface IProps {
    text: string;
}

const GuideTextItem: React.FC<IProps> = ({ text }) => {
    return (
        <Box display="flex" mb={0.5}>
            <Box mr={0.5}>
                <Dot />
            </Box>
            <Typography variant="caption">{text}</Typography>
        </Box>
    );
};

export default GuideTextItem;
