import React, { useEffect, useState } from 'react';
import {
    Box,
    createStyles,
    InputAdornment,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';

import { useHistory, useLocation } from 'react-router';
import { Routes } from 'router/routesConfig';
import LayoutMain from 'layouts/LayoutMain';
import { useGlobalStyles } from 'theme/globalStyles';
import { ButtonBottomBlock } from 'modules/common/components';
import {
    useCreatePaymentIntentMutation,
    useGetPaymentMethodsQuery,
    useGetStoredAccountQuery,
    useUpdateStoredAccountMutation,
} from 'store/services/payments';
import RadioGroup, { RadioOption, useStyle as useStyleRadio } from '../RadioGroup';
import { capitalize } from 'utils/strings';
import ButtonText from '../Buttons/ButtonText';
import { theme } from 'theme';
import AddPaymentMethod from 'modules/settings/pages/AddPaymentMethod';
import DialogBox from '../DialogBox';
import { Controller, useForm } from 'react-hook-form';
import Input from '../Input';
import { ReactComponent as CheckCircle } from 'assets/icons/color/16/check-circle.svg';
import classNames from 'classnames';
import { FLOAT_REGEX } from 'utils/validation';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { snackbarShown } from 'store/slices/ui';
import BackdropLoader from '../BackdropLoader';
import { formatAmountOfMoney } from 'utils/format';
import { useGetUserDetailsQuery } from 'store/services/accounts';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LeaveModal from '../LeaveModal';
import HighlightedBox from '../HighlightedBox';
import { useQuery } from 'hooks/useQuery';
import CheckOption from '../CheckOption';
import { DialogType } from '../StoredAccountDialogs/StoredAccountDialogs';
import CardDeclinedDialog from '../CardDeclinedDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: 'scroll',
            backgroundColor: theme.palette.neutral.extraLightGrey,
        },
        iconWrapper: {
            maxHeight: '28px',
        },
        button: {
            color: theme.palette.primary.main,
            maxWidth: '597px',
            zIndex: 100,
            position: 'fixed',
            bottom: 0,
            padding: '8px 16px 8px',
        },
        addButton: {
            width: '100%',
        },
        pricesWrapper: {
            flexDirection: 'row',
            width: 'calc(100% / 4 * 3)',
        },
        priceOption: {
            marginRight: '6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
        },
        optionButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            width: '25%',
            border: '0.5px solid #8B8B88',
            borderRadius: 8,
            padding: '0 8px',
        },
        adornment: {
            marginTop: '12px!important',
            paddingLeft: '12px',
            '& > p': {
                color: `${theme.palette.neutral.black}!important`,
            },
        },
        balanceWrapper: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            padding: '16px',
            '&:not(:last-child)': {
                borderRight: '0.5px solid #CCCBC9',
            },
        },
        balanceAmount: {
            fontSize: '17px',
        },
    })
);

interface IProps {
    onClose?: () => void;
    type?: StoredAccountType;
    balance?: number;
    required?: number;
    closeOnCreated?: boolean;
}

const priceOptions = [
    {
        label: '$10',
        value: '10',
    },
    {
        label: '$25',
        value: '25',
    },
    {
        label: '$50',
        value: '50',
    },
];

export enum StoredAccountType {
    CREATE = 'create',
    EDIT = 'edit',
    TOPUP = 'topup',
}

const SelectAmountTitle = {
    [StoredAccountType.TOPUP]: 'Select or Enter Amount',
    [StoredAccountType.CREATE]: 'Set AutoLoad for CreditCard Wallet',
    [StoredAccountType.EDIT]: 'Edit AutoLoad for CreditCard Wallet',
};

const AddEditStoredAccount: React.FC<IProps> = ({
    onClose,
    type = StoredAccountType.CREATE,
    balance,
    closeOnCreated,
    required,
}) => {
    const { replace } = useHistory();
    const { search } = useLocation();
    const query = useQuery();

    const {
        root,
        addButton,
        pricesWrapper,
        priceOption,
        optionButton,
        adornment,
        balanceWrapper,
        balanceAmount,
    } = useStyles();
    const { radioOption, active } = useStyleRadio({ position: 'center' });
    const { whiteBg, inDarkGrey } = useGlobalStyles();
    const { data: paymentMethods, refetch } = useGetPaymentMethodsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const {
        data: storedAccountInfo,
        isLoading: isStoredAccountInfoLoading,
    } = useGetStoredAccountQuery();
    const { data: userDetails } = useGetUserDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [updateStoredAccount, { isLoading: isUpdateLoading }] = useUpdateStoredAccountMutation();
    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const [
        createPaymentIntent,
        { isLoading: isCreatePaymentIntentLoading },
    ] = useCreatePaymentIntentMutation();
    const stripe = useStripe();

    const [cardOptions, setCardOptions] = useState<RadioOption[]>([]);
    const [dialogName, setDialogName] = useState<DialogType | null>(null);
    const [selectedCard, setSelectedCard] = useState('');
    const [selectedPrice, setSelectedPrice] = useState('');
    const [autoLoad, setAutoLoad] = useState(false);
    const [firstWithdraw, setFirstWithdraw] = useState(false);
    const { refetch: refetchUserInfo } = useGetUserDetailsQuery();

    const {
        control,
        reset,
        getValues,
        formState: { errors, isValid },
    } = useForm<{ selectedPrice: string }>({
        resolver: yupResolver(
            yup.object({
                selectedPrice: yup
                    .number()
                    .transform((_, value) => {
                        if (value.includes('.')) {
                            return +value;
                        }
                        return +value.replace(/,/, '.');
                    })
                    .min(10, 'The amount should be more then 10$')
                    .max(100, 'The amount should be less then 100$')
                    .required(),
            })
        ),
        mode: 'onChange',
        defaultValues: {
            selectedPrice: '',
        },
    });

    const [isSelectPriceModalOpen, setSelectPriceModalOpen] = useState(false);
    const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

    const openLeaveModal = () => setIsLeaveModalOpen(true);

    const closeLeaveModal = () => setIsLeaveModalOpen(false);

    useEffect(() => {
        if (type === StoredAccountType.EDIT && storedAccountInfo) {
            setSelectedCard(paymentMethods?.find(({ wallet }) => wallet)?.id || '');

            setSelectedPrice(formatAmountOfMoney(storedAccountInfo?.autoToppingUpAmount));
        }
    }, [type, storedAccountInfo, paymentMethods]);

    const toggleSelectPriceModalOpen = () => {
        reset();
        setSelectPriceModalOpen((is) => !is);
    };

    const handleConfirmSelectPrice = () => {
        const { selectedPrice } = getValues();
        toggleSelectPriceModalOpen();
        reset();
        setSelectedPrice(selectedPrice);
    };

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    useEffect(() => refetch(), []);
    useEffect(() => {
        paymentMethods &&
            setCardOptions(
                paymentMethods.map((card) => {
                    return {
                        value: card.id,
                        label: `${capitalize(card.brand)} •••• ${card.last4}`,
                        defaultMethod: card.defaultMethod,
                        wallet: card.wallet,
                    };
                })
            );
        paymentMethods?.length &&
            type !== StoredAccountType.EDIT &&
            setSelectedCard(paymentMethods.find(({ wallet }) => wallet)?.id || '');
    }, [paymentMethods]);

    const history = useHistory();

    const renderTopUpAmounts = () => {
        return (
            <Box display="flex" my={2} className={whiteBg} overflow="hidden">
                <Box display="flex" flexDirection="column" className={balanceWrapper}>
                    <Typography variant="caption">Current Balance</Typography>
                    <Typography variant="h3">${formatAmountOfMoney(balance, true)}</Typography>
                </Box>
                <Box display="flex" flexDirection="column" className={balanceWrapper}>
                    <Typography variant="caption">Required</Typography>
                    <Typography variant="h3" className={balanceAmount}>
                        ${formatAmountOfMoney(required, true)}
                    </Typography>
                </Box>
            </Box>
        );
    };

    const handleAddPaymentMethod = () => setIsAddModalOpen(true);

    const renderCardSelector = () => {
        return (
            <>
                <Box mb={1} mt={2} py={1} px={2} className={whiteBg} overflow="hidden">
                    <Box mb={1}>
                        <Typography variant="h4" style={{ letterSpacing: '-0.25px' }}>
                            {type !== StoredAccountType.TOPUP
                                ? 'Select the Credit Card to use for AutoLoad'
                                : 'Select Credit Card'}
                        </Typography>
                    </Box>
                    <RadioGroup
                        wrapperClassname={whiteBg}
                        value={selectedCard}
                        radioOptions={cardOptions}
                        onChange={(value) => setSelectedCard(value)}
                        position="flex-start"
                    />
                    <ButtonText
                        className={addButton}
                        color={theme.palette.primary.dark}
                        onClick={handleAddPaymentMethod}
                    >
                        Add Payment Method
                    </ButtonText>
                </Box>
            </>
        );
    };

    const renderSelectAmount = () => {
        const customActive = !['', '0', '10', '25', '50'].includes(selectedPrice);

        return (
            <Box my={1} py={1} px={2} className={whiteBg} overflow="hidden">
                <Box mb={1}>
                    <Typography variant="h4" style={{ letterSpacing: '-0.25px' }}>
                        {SelectAmountTitle[type]}
                    </Typography>
                </Box>
                <Box display="flex">
                    <RadioGroup
                        wrapperClassname={pricesWrapper}
                        optionClassname={priceOption}
                        value={selectedPrice}
                        radioOptions={priceOptions}
                        onChange={(value) => setSelectedPrice(value)}
                        deselect
                        iconRight={2}
                    />
                    <Box
                        className={classNames(radioOption, optionButton, {
                            [active]: customActive,
                        })}
                        onClick={toggleSelectPriceModalOpen}
                    >
                        {customActive ? (
                            <>
                                <CheckCircle />

                                <Typography variant="button" style={{ marginLeft: 2 }}>
                                    $
                                    <Typography variant="h3" style={{ display: 'inline' }}>
                                        {selectedPrice}
                                    </Typography>
                                </Typography>
                            </>
                        ) : (
                            <Typography variant="button" className={inDarkGrey}>
                                Set Value
                            </Typography>
                        )}
                    </Box>
                </Box>

                <HighlightedBox>
                    <Typography variant="caption" style={{ fontSize: '13px' }}>
                        {type === StoredAccountType.TOPUP ? (
                            <>
                                Select or Enter the initial amount of money you wish to transfer
                                from your designated credit card to your CreditCard Wallet (minimum
                                of $10.00)
                            </>
                        ) : (
                            <>
                                The amount selected above will be automatically transferred to your
                                CreditCard Wallet whenever the balance on your CreditCard Wallet
                                falls below $5.00. Minimum AutoLoad amount is $10.00.
                            </>
                        )}
                    </Typography>
                </HighlightedBox>

                <DialogBox
                    isOpen={isSelectPriceModalOpen}
                    title="AutoLoad Amount"
                    subTitle="Enter the amount to be AutoLoaded into your CreditCard Wallet when your CreditCard Wallet balance falls below $5.00."
                    onSubmit={handleConfirmSelectPrice}
                    submitText="Confirm"
                    cancelText="Cancel"
                    isSubmitDisabled={!isValid}
                    onCancel={toggleSelectPriceModalOpen}
                    subVariant="caption"
                >
                    <Box mt={2}>
                        <Controller
                            control={control}
                            name="selectedPrice"
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field: { ref, onChange, ...rest } }) => (
                                <Input
                                    label="AMOUNT"
                                    inputRef={ref}
                                    placeholder="minimum of $10.00"
                                    {...rest}
                                    onChange={(e) => {
                                        (FLOAT_REGEX.test(e.target.value) || !e.target.value) &&
                                            onChange(e);
                                    }}
                                    inputProps={{ inputMode: 'decimal' }}
                                    hasError={!!errors?.selectedPrice}
                                    helperText={errors?.selectedPrice?.message}
                                    startAdornment={
                                        <InputAdornment position="start" className={adornment}>
                                            $
                                        </InputAdornment>
                                    }
                                />
                            )}
                        />
                    </Box>
                </DialogBox>
            </Box>
        );
    };

    const renderCheckOptions = () => {
        return (
            <Box pl={2} className={whiteBg} overflow="hidden">
                <CheckOption checked={autoLoad} onChange={() => setAutoLoad((is) => !is)}>
                    I authorize ProofKeep to automatically AutoLoad my CreditCard Wallet from my
                    credit card ending in &apos;
                    {paymentMethods?.find((card) => card.id === selectedCard)?.last4}&apos; by $
                    {selectedPrice} when my CreditCard Wallet balance drops below $5.00.
                </CheckOption>

                <CheckOption checked={firstWithdraw} onChange={() => setFirstWithdraw((is) => !is)}>
                    First withdraw funds from my CreditCard Wallet when paying ProofKeep for
                    service.
                    <br />
                    Charge $0.50 for card authorization for the first time.
                </CheckOption>
            </Box>
        );
    };

    const onSubmit = () => {
        if (!stripe) return;

        setLoading(true);
        switch (type) {
            case StoredAccountType.EDIT:
                updateStoredAccount({
                    autoToppingUpAmount: +selectedPrice.replace(/,/, '.') * 100,
                    paymentMethodId: selectedCard,
                })
                    .unwrap()
                    .then(() => {
                        dispatch(
                            snackbarShown({
                                type: 'info',
                                message: 'Your CreditCard Wallet has been updated.',
                            })
                        );
                        onClose ? onClose() : history.push(Routes.SETTINGS);
                    })
                    .finally(() => setLoading(false));
                break;
            case StoredAccountType.CREATE:
                createPaymentIntent({
                    paymentMethodId: selectedCard,
                    metadata: {
                        actionNumber: 2,
                        autoToppingUpAmount: +selectedPrice.replace(/,/, '.') * 100,
                    },
                })
                    .unwrap()
                    .then(({ client_secret }) => {
                        stripe.confirmCardPayment(client_secret).then(async (response) => {
                            if (response.error) {
                                if (response.error.code === 'card_declined') {
                                    setDialogName(DialogType.FAILED_PAYMENT);
                                } else {
                                    dispatch(
                                        snackbarShown({
                                            type: 'error',
                                            message:
                                                response.error.message ||
                                                'An error occured please try again later',
                                        })
                                    );
                                }
                            } else {
                                await new Promise((resolve) => setTimeout(resolve, 1000));
                                refetchUserInfo();

                                if (userDetails) {
                                    if (!userDetails.oneTimeBenefitApplied && !closeOnCreated) {
                                        return replace({
                                            pathname: Routes.WALLET_CONGRATULATIONS,
                                            search,
                                        });
                                    }

                                    dispatch(
                                        snackbarShown({
                                            type: 'info',
                                            message: 'Your CreditCard Wallet has been created.',
                                        })
                                    );

                                    if (query.get('refer') === 'true') {
                                        return replace(Routes.REFER_A_FRIEND);
                                    }

                                    handleConfirmLeaveModal();
                                }
                            }
                            setLoading(false);
                        });
                    });
                break;
            case StoredAccountType.TOPUP:
                createPaymentIntent({
                    paymentMethodId: selectedCard,
                    metadata: {
                        actionNumber: 4,
                        amount: +selectedPrice.replace(/,/, '.') * 100,
                    },
                })
                    .unwrap()
                    .then(({ client_secret }) => {
                        stripe.confirmCardPayment(client_secret).then(async (response) => {
                            if (response.error) {
                                if (response.error.code === 'card_declined') {
                                    setDialogName(DialogType.FAILED_PAYMENT);
                                } else {
                                    dispatch(
                                        snackbarShown({
                                            type: 'error',
                                            message:
                                                response.error.message ||
                                                'An error occured please try again later',
                                        })
                                    );
                                }
                            } else {
                                await new Promise((resolve) => setTimeout(resolve, 1000));
                                refetchUserInfo();
                                dispatch(
                                    snackbarShown({
                                        type: 'info',
                                        message: 'Your CreditCard Wallet has been topped-up.',
                                    })
                                );
                                handleConfirmLeaveModal();
                            }
                            setLoading(false);
                        });
                    });
                break;
            default:
                break;
        }
    };

    const submitIsDisabled =
        !selectedCard ||
        !selectedPrice ||
        (type !== StoredAccountType.TOPUP && (!autoLoad || !firstWithdraw));

    const handleConfirmLeaveModal = () => (onClose ? onClose() : history.goBack());

    return (
        <>
            <BackdropLoader
                isOpen={
                    isCreatePaymentIntentLoading ||
                    isStoredAccountInfoLoading ||
                    isUpdateLoading ||
                    isLoading
                }
            />

            <CardDeclinedDialog setDialogName={setDialogName} dialogName={dialogName} />

            <LayoutMain
                flowTitle={
                    type === StoredAccountType.EDIT
                        ? 'Edit CreditCard Wallet'
                        : type === StoredAccountType.CREATE
                        ? 'Create CreditCard Wallet'
                        : 'Top-up CreditCard Wallet'
                }
                onClose={openLeaveModal}
            >
                <Box className={root} overflow="scroll">
                    <Box mb={15}>
                        {balance && required && renderTopUpAmounts()}
                        {renderCardSelector()}
                        {renderSelectAmount()}
                        {type !== StoredAccountType.TOPUP && renderCheckOptions()}
                    </Box>

                    <ButtonBottomBlock
                        background={2}
                        onButtonClick={onSubmit}
                        buttonTitle={
                            type === StoredAccountType.EDIT
                                ? 'Save'
                                : type === StoredAccountType.CREATE
                                ? 'Transfer Money and Create CreditCard Wallet'
                                : 'Top-up'
                        }
                        disabled={submitIsDisabled}
                    />
                </Box>
            </LayoutMain>

            <AddPaymentMethod isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} />

            <LeaveModal
                isOpen={isLeaveModalOpen}
                onLeave={handleConfirmLeaveModal}
                onClose={closeLeaveModal}
            />
        </>
    );
};

export default AddEditStoredAccount;
