import React, { useRef } from 'react';
import { Box, createStyles, makeStyles, Modal } from '@material-ui/core';
import TopBox from 'modules/evidence/components/TopBox';
import BackDropModalWrapper from 'modules/common/components/BackDropModalWrapper';
import { FileModalTypes } from '../../../../types/fileModalTypes';
import { IWatermarkProps } from 'modules/file-sharing/components/WatermarkBlock';
import ImageWatermark from 'modules/file-sharing/components/ImageWatermark';

const useStyles = makeStyles(() =>
    createStyles({
        image: {
            width: '100%',
            maxHeight: '100vh',

            '@media (orientation: landscape)': {
                width: 'initial',
                maxHeight: '100vh',
            },
        },
    })
);

const ImageViewer: React.FC<FileModalTypes & { watermark?: IWatermarkProps }> = ({
    isOpen,
    onClose,
    title,
    src,
    watermark,
}) => {
    const { image } = useStyles();

    const imageRef = useRef<any>(null);

    return (
        <Modal open={isOpen} onClose={onClose} BackdropComponent={BackDropModalWrapper}>
            <Box
                display="flex"
                height="100%"
                justifyContent="center"
                alignItems="center"
                bgcolor="rgba(0, 0, 0, 0.8)"
            >
                <TopBox title={title} onClose={onClose} />

                <Box>
                    <img ref={imageRef} src={src} className={image} alt={title} />
                </Box>

                {watermark && <ImageWatermark ref={imageRef} {...watermark} />}
            </Box>
        </Modal>
    );
};

export default ImageViewer;
