import React, { useState } from 'react';
import { Box, Modal, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Input from 'modules/common/components/Input';
import AddressSelect from 'modules/common/components/AddressSelect';
import InputButton from 'modules/common/components/InputButton';
import { LETTERS_INPUT_REGEX } from 'utils/validation';
import Tooltip from '../Tooltip';
import { useGlobalStyles } from 'theme/globalStyles';
import EditIcon from '../../icons/EditIcon';

const useStyles = makeStyles((theme) =>
    createStyles({
        infoGrayBox: {
            backgroundColor: theme.palette.neutral.lightGrey,
            fontSize: '14px',
            padding: '8px 12px',
            lineHeight: '20px',
            borderRadius: '8px',
            marginTop: '16px',
        },

        dividerTextWrapper: {
            whiteSpace: 'nowrap',
            color: theme.palette.neutral.darkGrey,
        },
    })
);

interface IProps {
    titleText?: string;
    dividerText?: string;
    bannerText?: string;
    apartmentRequired?: boolean | string;
    withApartmentField?: boolean;
}

const AddressForm: React.FC<IProps> = ({
    titleText,
    dividerText = 'Or Type Manually',
    bannerText = 'All fields except ‘Unit/Suite’ are required.',
    apartmentRequired = false,
    withApartmentField = true,
}) => {
    const { dividerTextWrapper } = useStyles();
    const { line } = useGlobalStyles();

    const [isFindAddressOpen, setFindAddressOpen] = useState(false);
    const [focusedField, setFocusedField] = useState('');

    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    const handleOpenAddressSelect = () => {
        setFindAddressOpen(true);
    };

    const handleCloseAddressSelect = () => {
        setFindAddressOpen(false);
    };

    const getShrinkState = (fieldName: string) => {
        return !!watch(fieldName)?.length || focusedField === fieldName;
    };

    const handleFocus = (fieldName: string) => {
        setFocusedField(fieldName);
    };

    const handleBlur = () => setFocusedField('');

    return (
        <>
            <Modal open={isFindAddressOpen}>
                <Box>
                    <AddressSelect onCloseAddressSelect={handleCloseAddressSelect} />
                </Box>
            </Modal>

            <Box>
                {titleText && (
                    <Box marginBottom={1}>
                        <Typography variant="subtitle1">{titleText}</Typography>
                    </Box>
                )}
                <Controller
                    control={control}
                    name="foundAddress"
                    render={({ field: { ref, ...rest } }) => (
                        <InputButton
                            icon={EditIcon}
                            onClick={handleOpenAddressSelect}
                            label="Find Your Address"
                            InputLabelProps={{
                                shrink: !!watch('foundAddress')?.length,
                            }}
                            inputRef={ref}
                            {...rest}
                        />
                    )}
                />

                <Box
                    mt={2}
                    my={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box className={line} />
                    <Box mx={1} className={dividerTextWrapper}>
                        <Typography variant="body1" align="center">
                            {dividerText}
                        </Typography>
                    </Box>
                    <Box className={line} />
                </Box>

                {bannerText && (
                    <Tooltip>
                        <Typography variant="caption" component="p">
                            {bannerText}
                        </Typography>
                    </Tooltip>
                )}

                <Box mt={2}>
                    <Controller
                        control={control}
                        name="country"
                        rules={{
                            required: 'This field is required',
                            maxLength: {
                                value: 50,
                                message: 'Maximum 50 characters',
                            },
                            pattern: {
                                value: LETTERS_INPUT_REGEX,
                                message: 'Incorrect input. Only letters allowed',
                            },
                            validate: (value) => {
                                return value.length > 1 || `Minimum 2 characters`;
                            },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                            <Input
                                label="Country"
                                inputRef={ref}
                                {...rest}
                                hasError={errors?.country}
                                helperText={errors?.country?.message}
                                onFocus={() => handleFocus('country')}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                    shrink: getShrinkState('country'),
                                }}
                            />
                        )}
                    />
                </Box>

                <Box mt={2}>
                    <Controller
                        control={control}
                        name="street"
                        rules={{
                            required: 'This field is required',
                            maxLength: {
                                value: 50,
                                message: 'Maximum 50 characters',
                            },
                            validate: (value) => {
                                return value.length > 1 || `Minimum 2 characters`;
                            },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                            <Input
                                label="Street"
                                inputRef={ref}
                                {...rest}
                                hasError={errors?.street}
                                helperText={errors?.street?.message}
                                onFocus={() => handleFocus('street')}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                    shrink: getShrinkState('street'),
                                }}
                            />
                        )}
                    />

                    <Box mt={2} display="flex" alignItems="flex-start">
                        <Box width="100%" mr={withApartmentField ? 2 : 0}>
                            <Controller
                                control={control}
                                name="house"
                                rules={{
                                    required: 'This field is required',
                                    maxLength: {
                                        value: 9,
                                        message: 'Maximum 9 characters',
                                    },
                                }}
                                render={({ field: { ref, ...rest } }) => (
                                    <Input
                                        label="House No."
                                        inputRef={ref}
                                        {...rest}
                                        hasError={errors?.house}
                                        helperText={errors?.house?.message}
                                        onFocus={() => handleFocus('house')}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: getShrinkState('house'),
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        {withApartmentField && (
                            <Box width="100%">
                                <Controller
                                    control={control}
                                    name="apartmentNumber"
                                    rules={{
                                        required: apartmentRequired,
                                        maxLength: {
                                            value: 9,
                                            message: 'Maximum 9 characters',
                                        },
                                    }}
                                    render={({ field: { ref, ...rest } }) => (
                                        <Input
                                            label="Unit/Suite"
                                            inputRef={ref}
                                            {...rest}
                                            hasError={errors?.apartmentNumber}
                                            helperText={errors?.apartmentNumber?.message}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box mt={2}>
                        <Controller
                            control={control}
                            name="city"
                            rules={{
                                required: 'This field is required',
                                maxLength: {
                                    value: 50,
                                    message: 'Maximum 50 characters',
                                },
                                pattern: {
                                    value: LETTERS_INPUT_REGEX,
                                    message: 'Incorrect input. Only letters allowed',
                                },
                                validate: (value) => {
                                    return value.length > 1 || `Minimum 2 characters`;
                                },
                            }}
                            render={({ field: { ref, ...rest } }) => (
                                <Input
                                    label="City"
                                    inputRef={ref}
                                    {...rest}
                                    hasError={errors?.city}
                                    helperText={errors?.city?.message}
                                    onFocus={() => handleFocus('city')}
                                    onBlur={handleBlur}
                                    InputLabelProps={{
                                        shrink: getShrinkState('city'),
                                    }}
                                />
                            )}
                        />
                    </Box>

                    <Box mt={2} display="flex" alignItems="flex-start">
                        <Box flexBasis="206px" flexGrow={1} mr={2}>
                            <Controller
                                control={control}
                                name="province"
                                rules={{
                                    required: 'This field is required',
                                    maxLength: {
                                        value: 30,
                                        message: 'Maximum 30 characters',
                                    },
                                    pattern: {
                                        value: LETTERS_INPUT_REGEX,
                                        message: 'Incorrect input. Only letters allowed',
                                    },
                                    validate: (value) => {
                                        return value.length > 1 || `Minimum 2 characters`;
                                    },
                                }}
                                render={({ field: { ref, ...rest } }) => (
                                    <Input
                                        label="State/Province"
                                        inputRef={ref}
                                        {...rest}
                                        hasError={errors?.province}
                                        helperText={errors?.province?.message}
                                        onFocus={() => handleFocus('province')}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: getShrinkState('province'),
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Box flexBasis="120px" flexGrow={1}>
                            <Controller
                                control={control}
                                name="zipCode"
                                rules={{
                                    required: 'This field is required',
                                    maxLength: {
                                        value: 9,
                                        message: 'Maximum 9 characters',
                                    },
                                }}
                                render={({ field: { ref, ...rest } }) => (
                                    <Input
                                        label="Postal Code"
                                        inputRef={ref}
                                        {...rest}
                                        hasError={errors?.zipCode}
                                        helperText={errors?.zipCode?.message}
                                        onFocus={() => handleFocus('zipCode')}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: getShrinkState('zipCode'),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AddressForm;
