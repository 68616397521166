import React from 'react';
import { useController } from 'react-hook-form';
import InputWithMask from '../../InputWithMask';
import { confirmationCodeRules } from 'modules/common/constants/formRules';
import { IInputTypes } from '../../../../../types/inputTypes';

type IProps = Omit<IInputTypes, 'onBlur'> & {
    autoFocus?: boolean;
    label?: string;
    spacing?: string;
    mask?: string;
    rules?: Record<string, any>;
    allowText?: boolean;
};

const ConfirmationCodeInput: React.FC<IProps> = ({
    control,
    name = 'confirmationCode',
    defaultValue = '',
    autoFocus = false,
    label = 'Code',
    spacing = '1px',
    mask = '999 999',
    allowText,
    rules = confirmationCodeRules,
}) => {
    const {
        field: { ref, ...inputProps },
        formState: { errors },
    } = useController({
        name,
        control,
        rules,
        defaultValue,
    });

    return (
        <InputWithMask
            mask={mask}
            label={label}
            {...inputProps}
            inputRef={ref}
            hasError={
                errors[name]?.message.toLowerCase().includes('incorrect') ||
                errors[name]?.message === 'just_error'
            }
            helperText={errors[name]?.message === 'just_error' ? '' : errors?.[name]?.message}
            inputProps={{
                inputMode: allowText ? 'text' : 'numeric',
                style: { textTransform: 'uppercase' },
            }}
            autoFocus={autoFocus}
            style={{ letterSpacing: spacing }}
            maskChar="_"
            isReferral={name === 'referralCode'}
            isCode
        />
    );
};

export default ConfirmationCodeInput;
