import React, { forwardRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        '& .MuiCheckbox-root': {
            padding: '6.5px 4px',
            borderRadius: 8,
            border: `0.5px solid ${theme.palette.neutral.main}`,
            width: '24px',
            height: '24px',

            '&.MuiCheckbox-indeterminate': {
                background: theme.palette.primary.main,
            },
        },

        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            backgroundColor: theme.palette.primary.main,
            border: 'none',
        },

        '& .MuiCheckbox-colorSecondary.Mui-checked:hover': {
            backgroundColor: theme.palette.primary.main,
        },

        '& .MuiIconButton-label span': {
            background: 'transparent',
            border: 'none',
        },

        '& .Mui-disabled.MuiButtonBase-root': {
            backgroundColor: theme.palette.neutral.light,
            '& .MuiIconButton-label': {
                display: 'none',
            },
        },
    },

    icon: {
        width: '16px',
        height: '11px',
        background: theme.palette.primary.main,
        border: `0.5px solid ${theme.palette.primary.main}`,
    },
}));

// eslint-disable-next-line react/display-name
const StyledCheckbox: React.FC<CheckboxProps> = forwardRef(
    ({ indeterminate = false, disabled = false, ...props }, ref) => {
        const { wrapper, icon } = useStyles();

        return (
            <Box className={wrapper}>
                <Checkbox
                    ref={ref}
                    indeterminate={indeterminate}
                    checkedIcon={
                        <svg
                            width="16"
                            height="11"
                            viewBox="0 0 18 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17 1L6 12L1 7"
                                stroke="#0D0A00"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    }
                    indeterminateIcon={
                        <svg
                            width="10"
                            height="2"
                            viewBox="0 0 10 2"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 1H9"
                                stroke="#0D0A00"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    }
                    icon={<span className={icon} />}
                    disabled={disabled}
                    {...props}
                />
            </Box>
        );
    }
);

export default StyledCheckbox;
