import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectToken } from 'store/slices/loginInfo';

import { Routes } from './routesConfig';

interface IProps {
    component: React.ComponentType<RouteComponentProps<any>>;
    exact?: boolean;
    path: Routes;
}

export const PrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
    const token = useSelector(selectToken);

    return (
        <Route
            {...rest}
            render={(props) =>
                token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.START_SCREEN,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};
