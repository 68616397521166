import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Routes } from 'router/routesConfig';
import CreatePayment from 'modules/common/components/CreatePayment';
import CustomDialog from 'modules/common/components/CustomDialog';
import { useLocation } from 'react-router';

interface IProps {
    isOpen?: boolean;
    onClose?: any;
    onSuccess?: any;
}

const AddPaymentMethod: React.FC<IProps> = ({ isOpen = true, onClose, onSuccess }) => {
    const { replace, push } = useHistory();
    const { search, ...location } = useLocation();

    useEffect(() => {
        replace({
            ...location,
            search: isOpen ? `${search.length ? `${search}&` : '?'}add-payment=true` : search,
        });
    }, [isOpen]);

    const handleScreenClose = () => {
        onClose ? onClose() : push(Routes.PAYMENT_METHODS);
    };

    return (
        <CustomDialog open={isOpen}>
            <CreatePayment
                title="Add Credit Card"
                submitText="Add Card"
                onClose={handleScreenClose}
                onConfirmClick={onSuccess ? onSuccess : handleScreenClose}
                hasSetAsDefaultPaymentMethod
            />
        </CustomDialog>
    );
};

export default AddPaymentMethod;
