import React, { FC, useMemo } from 'react';
import DialogBoxColumn, { IColumnButton } from 'modules/common/components/DialogBoxColumn';
import ConfirmationCodeInput from 'modules/common/components/FormInputs/ConfirmationCodeInput';
import { useForm } from 'react-hook-form';
import { useCheckPromoCodeMutation } from 'store/services/referral';
import { promoCodeRules } from 'modules/common/constants/formRules';

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
    savePaymentInfo: () => void;
    onPromoCodeApplied: (amount: number) => void;
}

const EnterPromoCodeModal: FC<IProps> = ({
    isOpen,
    handleClose,
    savePaymentInfo,
    onPromoCodeApplied,
}) => {
    const [checkPromoCode] = useCheckPromoCodeMutation();

    const { control, getValues, watch, setError } = useForm({
        mode: 'onChange',
        defaultValues: { promoCode: '' },
    });

    const handleApplyPromoCode = async () => {
        await checkPromoCode(getValues('promoCode').toUpperCase())
            .unwrap()
            .then((response) => {
                onPromoCodeApplied(response.amount);
                handleClose();
            })
            .catch((response) => {
                const {
                    data: { errors },
                } = response;
                setError('promoCode', { message: errors[0].message });
            });
    };

    const buttons: IColumnButton[] = useMemo(
        () => [
            {
                text: 'Apply Promo Code',
                onClick: handleApplyPromoCode,
                isHighlighted: true,
                disabled: watch('promoCode').length < 7 || watch('promoCode').includes('_'),
            },
            { text: 'Proceed without Promo Code', onClick: savePaymentInfo },
            { text: 'Cancel', onClick: handleClose },
        ],
        [watch('promoCode')]
    );
    return (
        <DialogBoxColumn isOpen={isOpen} buttons={buttons} title={'Enter Promo Code'}>
            <ConfirmationCodeInput
                control={control}
                name={'promoCode'}
                label={'Promo Code'}
                allowText
                rules={promoCodeRules}
                mask={'*** ***'}
            />
        </DialogBoxColumn>
    );
};

export default EnterPromoCodeModal;
