import { Routes } from '../router/routesConfig';
import { useLocation } from 'react-router';
import { useQuery } from './useQuery';

const StandardPaths = [
    Routes.HOME,
    Routes.MY_FILES,
    Routes.SETTINGS,
    Routes.EVIDENCE,
    Routes.PROOF_METADATA_VIEW,
    Routes.PAYMENT_DETAILS_SETUP,
    Routes.RECOVERY_PASSWORD,
    Routes.REFER_BY_EMAIL,
    Routes.REFER_BY_SMS,
    Routes.TRANSACTION_INFO,
    Routes.GET_ACCESS_CONFIRM,
    Routes.DELETE_MY_ACCOUNT_CONFIRM,
    Routes.LOG_IN,
];

const StandardQueryParams = ['set-up-rooms', 'add-payment'];
const STANDARD_PADDING = 73;

const WalletPaymentPaths = [Routes.EXTEND_STORAGE_DATE, Routes.CHECKOUT];
const WALLET_PAYMENT_BLOCK = 113;

const useSnackbarBottom = () => {
    const { pathname } = useLocation();
    const query = useQuery();

    if (pathname.includes(Routes.REFERRAL_CODE)) {
        return 125;
    }

    if (WalletPaymentPaths.some((route) => pathname.includes(route))) {
        return WALLET_PAYMENT_BLOCK;
    }

    if (
        StandardPaths.some((route) => pathname.includes(route)) ||
        StandardQueryParams.some((param) => query.get(param) === 'true')
    ) {
        return STANDARD_PADDING;
    }

    return 25;
};

export default useSnackbarBottom;
