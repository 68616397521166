import { PHONE_CODES } from 'modules/sign-up/constants/phoneCodes';
import {
    CONFIRMATION_CODE_REGEX,
    COUNTRY_CODE_REGEX,
    EMAIL_REGEX,
    FILE_NAME_REGEX,
    PASSWORD_REGEX,
    PROMO_CODE_REGEX,
    USERNAME_REGEX,
} from 'utils/validation';

export const emailRules = {
    required: 'This field is required',
    pattern: {
        value: EMAIL_REGEX,
        message: 'Please enter a valid email address',
    },
    maxLength: {
        value: 150,
        message: 'Maximum 150 characters',
    },
};

export const userNameRules = {
    required: 'This field is required',
    pattern: {
        value: USERNAME_REGEX,
        message: 'Please enter a valid username',
    },
    maxLength: {
        value: 20,
        message: 'Maximum 20 characters',
    },
    validate: (value) => {
        return value.length > 1 || `Minimum 2 characters`;
    },
};

export const loginRules = {
    required: 'This field is required',
    maxLength: {
        value: 150,
        message: 'Maximum 150 characters',
    },
    validate: (value) => {
        return value.length > 1 || `Minimum 2 characters`;
    },
};

export const fileNameRules = {
    required: 'This field is required',
    pattern: {
        value: FILE_NAME_REGEX,
        message: 'Invalid input',
    },
    maxLength: {
        value: 30,
        message: 'Maximum 30 characters',
    },
    minLength: {
        value: 3,
        message: 'Minimum 3 characters',
    },
};

export const passwordRules = {
    required: 'This field is required',
    pattern: {
        value: PASSWORD_REGEX,
        message: 'Wrong Password. Please try again',
    },
    maxLength: {
        value: 32,
        message: 'Maximum 32 characters',
    },

    validate: (value) => {
        return value.length > 7 || `Minimum 8 characters`;
    },
};

export const phoneNumberRules = {
    required: 'This field is required',
    maxLength: {
        value: 11,
        message: 'Maximum 11 characters',
    },

    validate: (value) => {
        return String(value).length > 8 || `Minimum 9 characters`;
    },
};

export const countryCodeRules = {
    required: 'This field is required',
    pattern: {
        value: COUNTRY_CODE_REGEX,
        message: 'Invalid input',
    },
    validate: (value) => {
        return Boolean(PHONE_CODES.find((code) => code.code === value)) || `Invalid country code`;
    },
};

export const confirmationCodeRules = {
    required: true,
    pattern: CONFIRMATION_CODE_REGEX,
};

export const promoCodeRules = {
    required: true,
    pattern: PROMO_CODE_REGEX,
};

export const nameRules = {
    required: 'This field is required',

    minLength: {
        value: 2,
        message: 'Must be 2 characters or more',
    },

    maxLength: {
        value: 50,
        message: 'Must be 50 characters or less',
    },

    pattern: {
        value: /^[a-zA-Z\d,-_`|.'" ]+$/,
        message: 'Special characters can not be used',
    },
};
