import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

interface IProps {
    children: React.ReactNode;
    color?: string;
    disabled?: boolean;
    onClick?: () => void;
    style?: any;
    className?: any;
}

interface StyleProps {
    color: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
    root: {
        color: ({ color }) => color,
        width: 'fit-content',
        height: '32px',
    },
}));

const ButtonText: React.FC<IProps> = ({
    children,
    className,
    disabled = false,
    color = '#F29912',
    onClick,
    style,
}) => {
    const { root } = useStyles({ color });

    return (
        <Button
            onClick={onClick}
            className={classNames(root, className)}
            style={style}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

export default ButtonText;
