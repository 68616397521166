/* eslint-disable no-useless-escape */

export const PASSWORD_CHAR_LIMIT_REGEX = /^.{8,32}$/;
export const CONTAINS_NUMBER_REGEX = /^(?=.*?[0-9])/;
export const CONTAINS_LOWERCASE_REGEX = /^(?=.*[a-z])/;
export const CONTAINS_UPPERCASE_REGEX = /^(?=.*[A-Z])/;
export const CONTAINS_SPECIAL_CHAR_REGEX = /^(?=.*?[#?!@$%^&*-])/;
export const USERNAME_REGEX = /^[a-zA-Z0-9@+-._]*$/;
export const EMAIL_REGEX = /^\w+[\w-+\.]*\@\w+([-\.]\w+)*\.[a-zA-Z]{2,}$/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const CONFIRMATION_CODE_REGEX = /^[0-9]{3,3} [0-9]{3,3}$/;
export const PROMO_CODE_REGEX = /^[0-9a-zA-Z]{3,3} [a-zA-Z0-9]{3,3}$/;
export const SMALL_CONFIRMATION_CODE_REGEX = /^[0-9]{2,2} [0-9]{2,2}$/;
export const CONFIRMATION_CODE_REPLACE_REGEX = /\D/g;
export const PHONE_NUMBER_REGEX = /^(?=(?:\s?\d){7,17}$)\d+(?:\s?\d+){0,3}$/;
export const LETTERS_INPUT_REGEX = /^(?:[a-zA-Zàâçéèêëîïôûùüÿñæœ]+(?:[.'\-,])?\s?)+$/;
export const COUNTRY_CODE_REGEX = /^\+\d{1,5}$/;
export const NAME_REGEX = /^.[a-zA-Z0-9._-]{2,29}$/;
export const FILE_NAME_REGEX = /^.{2,29}$/;
// export const FLOAT_REGEX = /^\d+(\,\d{0,2})?$/;
export const FLOAT_REGEX = /^\d+((\.\d{0,2})|(\,\d{0,2}))?$/;
export const ONLY_ENG_AND_SYMBOLS_REGEX = /[^a-zàâçéèêëîïôûùüÿñæœ0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~“”„«»‘’]/gi;
