import React, { useState } from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { ReactComponent as AlertIconError } from 'assets/icons/color/alert-triangle-error.svg';
import { ReactComponent as AlertIconWarning } from 'assets/icons/color/alert-triangle-warning.svg';
import Alert from '@material-ui/lab/Alert';
import { SlideUpMenu } from '../index';
import { Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectOfflineEvidences, selectUploadInProgress } from '../../../../store/slices/ui';
import { Routes } from '../../../../router/routesConfig';
import { useHistory } from 'react-router-dom';
import localforage from 'localforage';
import { pluralize } from '../../../../utils/strings';
import { selectToken } from '../../../../store/slices/loginInfo';

const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 1000,
            maxWidth: 598,
        },
        wrapperError: {
            borderRadius: 0,
            boxShadow: '0 1px 0 #F2BFBF',
        },
        wrapperWarning: {
            borderRadius: 0,
            boxShadow: '0 1px 0 #FFE680',
            background: '#FFF7CC',
        },
        more: {
            color: theme.palette.neutral.main,
            marginLeft: 6,
            cursor: 'pointer',
        },
        continueUpload: {
            color: theme.palette.primary.dark,
            marginLeft: 6,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
        },
    })
);

const OfflineBox: React.FC = () => {
    const { more, wrapper, wrapperError, continueUpload, wrapperWarning } = useStyles();
    const isOnline = useOnlineStatus();
    const history = useHistory();
    const token = useSelector(selectToken);
    const [showTip, setShowTip] = useState(false);
    const offlineEvidences = useSelector(selectOfflineEvidences);
    const uploadInProgress = useSelector(selectUploadInProgress);

    const onShowTip = () => {
        setShowTip(true);
    };

    const onCloseTip = () => {
        setShowTip(false);
    };

    const continueEvidenceUpload = () => {
        if (token) {
            localforage.getItem('evidences').then(async (value: any) => {
                const evidences = value || {};
                if (Object.keys(evidences).length) {
                    const situationId = Object.keys(evidences)[0];
                    history.push(`${Routes.EVIDENCE}?situation=${situationId}&syncOffline=1`);
                }
            });
        } else {
            history.push(Routes.LOG_IN);
        }
    };

    if (uploadInProgress) {
        return null;
    }

    return (
        <>
            <Box className={wrapper}>
                {!isOnline ? (
                    <Alert severity="error" className={wrapperError} icon={<AlertIconError />}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography
                                variant="caption"
                                component="span"
                                align="left"
                                color="textPrimary"
                            >
                                The network connection is poor or was lost.{' '}
                                {offlineEvidences > 0
                                    ? `${pluralize(offlineEvidences, 'file')} are waiting for
                                uploading.`
                                    : null}
                            </Typography>

                            <Typography
                                variant="subtitle1"
                                component="span"
                                className={more}
                                onClick={onShowTip}
                            >
                                More
                            </Typography>
                        </Box>
                    </Alert>
                ) : offlineEvidences > 0 ? (
                    <Alert
                        severity="warning"
                        className={wrapperWarning}
                        icon={<AlertIconWarning />}
                    >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography
                                variant="caption"
                                component="span"
                                align="left"
                                color="textPrimary"
                            >
                                You have an unfinished process.{' '}
                                {offlineEvidences > 0
                                    ? `${pluralize(
                                          offlineEvidences,
                                          'file'
                                      )} are waiting for uploading.`
                                    : null}
                            </Typography>

                            <Typography
                                variant="subtitle1"
                                component="span"
                                className={continueUpload}
                                onClick={continueEvidenceUpload}
                            >
                                {token ? 'Continue' : 'Log in'}
                            </Typography>
                        </Box>
                    </Alert>
                ) : null}
            </Box>
            <SlideUpMenu
                isOpen={showTip}
                bottomTitle="The network connection is poor or was lost"
                backLabel="Back"
                onClose={onCloseTip}
                isInfoBlock
            >
                <Box px={2} pb={2}>
                    <Typography variant="caption">
                        The network connection is poor or was lost. Please continue capturing your
                        evidence. All captured media is being stored locally. Your files are being
                        saved locally and you can transfer them to online storage once your network
                        connection is restored.
                    </Typography>
                </Box>
            </SlideUpMenu>
        </>
    );
};

export default OfflineBox;
