import React, { FC, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import DialogBoxColumn, { IColumnButton } from '../DialogBoxColumn';
import { useAppDispatch, useTypedSelector } from 'hooks/store';
import { insufficientFundsHidden, selectInsufficientFundsDialog } from 'store/slices/ui';
import StoredAccountDialogs, { DialogType } from '../StoredAccountDialogs/StoredAccountDialogs';
import { useLocation } from 'react-router';
import { Routes } from 'router/routesConfig';

const InsufficientFundsDialog: FC = () => {
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const { isOpen, error } = useTypedSelector(selectInsufficientFundsDialog);

    const [dialogName, setDialogName] = useState<null | DialogType>(null);

    const handleClose = () => dispatch(insufficientFundsHidden());

    const handleOpenTopUp = () => {
        handleClose();
        setDialogName(DialogType.TOP_UP_STORED_ACCOUNT);
    };

    const Buttons: IColumnButton[] = [
        { text: 'Change Payment Method', onClick: handleOpenTopUp },
        { text: 'Back', onClick: handleClose },
    ];

    return (
        <>
            <DialogBoxColumn
                isOpen={
                    isOpen && [Routes.STORED_ACCOUNT, Routes.CHECKOUT].includes(pathname as Routes)
                }
                title="Insufficient Funds on your credit card"
                buttons={Buttons}
            >
                <Box mt={1}>
                    <Typography>
                        {error}
                        There are insufficient funds available on your credit card ending in ‘7612’
                        to AutoLoad the CreditCard Wallet. You can change the Payment Card.
                    </Typography>
                </Box>
            </DialogBoxColumn>

            <StoredAccountDialogs dialogName={dialogName} setDialogName={setDialogName} />
        </>
    );
};

export default InsufficientFundsDialog;
