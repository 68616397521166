import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Box,
    IconButton,
    Button,
} from '@material-ui/core';

import { useAppDispatch, useTypedSelector } from 'hooks/store';
import { selectSnackbar, snackbarHidden } from 'store/slices/ui';
import { ReactComponent as AlertTriangle } from 'assets/icons/default/alert-triangle.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/default/check-circle.svg';
import { ReactComponent as CrossSmall } from 'assets/icons/default/cross-small.svg';
import useSnackbarBottom from 'hooks/useSnackbarBottom';
import { ReactComponent as OrangeInfoIcon } from 'assets/icons/default/info-orange.svg';
import { theme } from 'theme';

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles<Theme, { bottom?: number | string }>((theme: Theme) =>
    createStyles({
        snackbar: {
            bottom: ({ bottom = 0 }) => bottom,
            left: 16,
            right: 16,
            transform: 'none !important',

            '& .MuiAlert-root': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                lineHeight: '16px',
                borderRadius: '8px',
                padding: '8px 12px',
                width: '100%',
                maxWidth: '570px',
            },

            '& .MuiAlert-message': {
                padding: 0,
            },

            '& .MuiAlert-filledError': {
                backgroundColor: theme.palette.error.transparent,
                color: theme.palette.error.main,
            },

            '& .MuiAlert-filledSuccess': {
                backgroundColor: theme.palette.success.transparent,
                color: theme.palette.success.dark,
            },

            '& .MuiAlert-filledInfo': {
                backgroundColor: theme.palette.neutral.black,
                color: theme.palette.neutral.white,
            },
            '& .MuiAlert-filledWarning': {
                boxShadow: 'inset 0px 4px 0px #F29912',
                backgroundColor: theme.palette.neutral.black,
                color: theme.palette.neutral.white,
            },
        },

        alert: {
            '& path': {
                stroke: theme.palette.error.main,
            },
        },
        success: {
            '& path': {
                stroke: theme.palette.success.dark,
            },
        },

        closeBtn: {
            padding: '0 8px 0 0',
        },
    })
);

const Snackbar = () => {
    const bottom = useSnackbarBottom();
    const { snackbar, alert, success, closeBtn } = useStyles({ bottom });

    const dispatch = useAppDispatch();
    const {
        message,
        type,
        actionText,
        onAction,
        messageVariant = 'caption',
        isShown,
    } = useTypedSelector(selectSnackbar);

    const handleClose = () => {
        dispatch(snackbarHidden());
    };

    const handleAction = () => {
        onAction && onAction();
        handleClose();
    };

    const renderIcon = (type: 'error' | 'success' | 'info' | 'warning') => {
        switch (type) {
            case 'error':
                return <AlertTriangle className={alert} />;
            case 'success':
                return <CheckCircle className={success} />;
            case 'info':
                return false;
            default:
                return false;
        }
    };

    return (
        <MuiSnackbar
            open={isShown}
            onClose={handleClose}
            className={snackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity={type}
                action={
                    type === 'info' && (
                        <IconButton onClick={handleClose} className={closeBtn}>
                            <CrossSmall />
                        </IconButton>
                    )
                }
                icon={renderIcon(type)}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant={messageVariant} component="span">
                        {message}
                    </Typography>
                </Box>

                {actionText && (
                    <Box pt={2} width="100%" display="flex" justifyContent="flex-end">
                        <Button onClick={handleAction} style={{ padding: 0 }}>
                            <Box display="flex" alignItems="center">
                                <OrangeInfoIcon />

                                <Typography
                                    variant="button"
                                    style={{ color: theme.palette.primary.dark, paddingLeft: 4 }}
                                >
                                    {actionText}
                                </Typography>
                            </Box>
                        </Button>
                    </Box>
                )}
            </Alert>
        </MuiSnackbar>
    );
};

export default Snackbar;
